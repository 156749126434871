import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../cdl/Toast/useToasts';
import { updateKeyPort } from '../common/api/clients/keyPorts.api';
import { queries } from '../common/api/queryKeys/queries';
import { translate } from '../common/helpers/translate.helper';

export const useKeyPortEdit = (onSuccess) => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateKeyPort,
        onSuccess: (keyPort) => {
            queryClient.removeQueries({ queryKey: queries.keyPorts.detail(keyPort.id).queryKey });
            queryClient.invalidateQueries({ queryKey: queries.keyPorts._def });
            onSuccess?.();

            addToast(translate('keyPorts.toast.updateKeyPort.success'));
        },
        onError: (error) => {
            if (error.response?.status !== 400) {
                addErrorToast(translate('keyPorts.toast.updateKeyPort.error'));
            }
        },
    });
};
