import { useState } from 'react';

import { OfferApprovalSettingsMessage } from '../../types/OfferApprovalSettingsMessage';
import { useRole } from '../common/hooks/useRole';
import { Box } from '../common/ui/Box';

import { ApprovalRequestsDropdown } from './ApprovalRequest/ApprovalRequestsDropdown';
import { OrderedRequestButton } from './ApprovalRequest/RequestButton/OrderedRequestButton';
import { OfferModel } from './model/OfferModel';
import { RequestApprovalMultipleApproversPopup } from './MultipleApprovers/RequestApprovalMultipleApproversPopup';

interface OfferApprovalActivityProps {
    offer: OfferModel;
    offerApprovalSettings: OfferApprovalSettingsMessage;
}

export const OfferApprovalActivity = (props: OfferApprovalActivityProps) => {
    const [showPopup, setShowPopup] = useState(false);
    const role = useRole();
    const onRequestApprovalButtonClicked = () => {
        setShowPopup(true);
    };

    const onPopupDismiss = () => {
        setShowPopup(false);
    };

    return (
        <Box
            display="flex"
            width={props.offer.approvalRequests.length ? '570px' : '200px'}
            justifyContent="flex-end"
            alignItems="flex-end"
            gap={4}
        >
            {props.offer.approvalRequests.length ? (
                <ApprovalRequestsDropdown approvalRequests={props.offer.approvalRequests} />
            ) : null}

            {role.isCustomer() && role.hasWriteRights(props.offer.customerId) ? (
                <OrderedRequestButton
                    offerApprovalSettings={props.offerApprovalSettings}
                    approvalRequests={props.offer.approvalRequests}
                    onRequestApprovalButtonClicked={onRequestApprovalButtonClicked}
                />
            ) : null}

            {role.isCustomer() ? (
                <RequestApprovalMultipleApproversPopup
                    offerApprovalSettings={props.offerApprovalSettings}
                    open={showPopup}
                    onDismiss={onPopupDismiss}
                    offerId={props.offer.id}
                    customerId={props.offer.customerId}
                />
            ) : null}
        </Box>
    );
};
