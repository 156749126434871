import { ProductContext } from '../../../../types/ProductContext';
import { Text } from '../../../cdl/Text/Text';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

import { LubesOrderOverviewSortSelect } from './LubesOrderOverviewSortSelect';
import { LubesOrderOverviewTable } from './LubesOrderOverviewTable';
import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { useNavigate, useSearch } from '@tanstack/react-router';

export const LubesOrderOverviewPage = () => {
    const role = useRole();
    const { context } = useProductContext();

    useDocumentTitle(t('order.overviewPageTitle'));

    const navigate = useNavigate({ from: '/orders' });
    const search = useSearch({ from: '/_app/_lubes/orders' });

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {t('order.overviewPageTitle')}
                </Text>

                <Box display="flex" justifyContent="space-between" columnGap={4}>
                    <Box display="flex" columnGap={4}>
                        <Box minWidth="250px">
                            <DebouncedSearchTextInput
                                placeholder={t('order.searchInputPlaceholder')}
                                value={search.searchQuery}
                                onChange={(searchQuery) => {
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            searchQuery,
                                            page: 0,
                                        }),
                                    });
                                }}
                            />
                        </Box>

                        {!role.isOneCompanyUser(context) || role.isAdmin() ? (
                            <OverviewCustomerCompanySelect
                                value={search.customerIds}
                                context={ProductContext.LUBES}
                                onChange={(customerIds) => {
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            customerIds,
                                            page: 0,
                                        }),
                                    });
                                }}
                            />
                        ) : null}

                        {role.isAdmin() ? (
                            <OverviewSupplierCompanySelect
                                context={ProductContext.LUBES}
                                value={search.supplierIds}
                                onChange={(supplierIds) => {
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            supplierIds,
                                            page: 0,
                                        }),
                                    });
                                }}
                            />
                        ) : null}
                    </Box>

                    <Box minWidth="250px">
                        <LubesOrderOverviewSortSelect
                            onChange={(sortValue) => {
                                navigate({
                                    search: (previousSearch) => ({
                                        ...previousSearch,
                                        sortValue,
                                    }),
                                });
                            }}
                            value={search.sortValue}
                        />
                    </Box>
                </Box>

                <LubesOrderOverviewTable />
            </PageGrid>
        </Page>
    );
};
