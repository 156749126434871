import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

import { get } from '../../../common/ui/get';
import { Tooltip } from '../../Tooltip/Tooltip';
import { MARKER_TARGET_WIDTH } from '../util/MarkerTargetWidth';

const TooltipTarget = styled.div`
    position: absolute;
    background-color: transparent;
    top: -4px;
    width: ${MARKER_TARGET_WIDTH}px;
    height: 12px;
`;

interface MarkerProps {
    variant: InternalProgressBarMarkerProps['variant'];
    dimmed?: boolean;
}

const regularVariant = variant({
    prop: 'variant',
    variants: {
        neutral: {
            backgroundColor: get('colors.foreground.default'),
        },
        accent: {
            backgroundColor: get('colors.accent.emphasis'),
        },
        attention: {
            backgroundColor: get('colors.attention.emphasis'),
        },
        negative: {
            backgroundColor: get('colors.negative.emphasis'),
        },
    },
});

const dimmedVariant = variant({
    prop: 'variant',
    variants: {
        neutral: {
            backgroundColor: get('colors.border.default'),
        },
        accent: {
            backgroundColor: get('colors.accent.border'),
        },
        attention: {
            backgroundColor: get('colors.attention.border'),
        },
        negative: {
            backgroundColor: get('colors.negative.border'),
        },
    },
});

const Marker = styled.div<MarkerProps>`
    height: 12px;
    width: 2px;
    border-radius: 2px;
    margin-left: 4px;
    outline: 2px solid ${get('colors.background.default')};

    ${(props) => (props.dimmed ? dimmedVariant(props) : regularVariant(props))}
`;

export interface InternalProgressBarMarkerProps {
    value: number;
    variant?: 'neutral' | 'accent' | 'attention' | 'negative';
    dimmed?: boolean;
    children?: ReactNode;
    /**
     * not working, used internally in the ProgressBar component
     */
    style?: CSSProperties;
}

export const InternalProgressBarMarker = ({
    // the variable `value` is used in the ProgressBar component to calculate the position of the marker
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value,
    variant = 'neutral',
    dimmed,
    children,
    style,
}: InternalProgressBarMarkerProps) => {
    return (
        <Tooltip label={children} showArrow side="bottom">
            <TooltipTarget style={style}>
                <Marker variant={variant} dimmed={dimmed} />
            </TooltipTarget>
        </Tooltip>
    );
};
