import { CustomDoubleLineOption } from '../../../cdl/Select/components/CustomDoubleLineOption';
import { AgentImportedLabel } from '../../../common/AgentImportedLabel/AgentImportedLabel';
import { translate } from '../../../common/helpers/translate.helper';

import { AgentCreateOption } from './AgentCreateOptionLabel';
import { CustomSimpleAgentOption } from './CustomSimpleAgentOption';

export const CustomAgentOption = ({ data, ...props }: any) => {
    if (data.value === 'createAgent') {
        return <AgentCreateOption {...props} />;
    }

    const agent = data.agent;
    const label = agent.name;

    if (agent.contact.emailAddress || agent.contact.phoneNumber) {
        const description = [
            agent.contact.emailAddress ? `${translate('global.contact.email')}: ${agent.contact.emailAddress}` : null,
            agent.contact.phoneNumber ? `${translate('global.contact.phone')}: ${agent.contact.phoneNumber}` : null,
        ]
            .filter((value) => value)
            .join(', ');

        return (
            <CustomDoubleLineOption
                label={label}
                description={description}
                trailingVisual={agent.source ? <AgentImportedLabel /> : undefined}
                {...props}
            />
        );
    }

    return (
        <CustomSimpleAgentOption
            label={label}
            trailingVisual={agent.source ? <AgentImportedLabel /> : undefined}
            {...props}
        />
    );
};
