import { useLocation, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { DropdownMenu, DropdownMenuOption } from '../../../cdl/DropdownMenu/DropdownMenu';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconDots } from '../../../common/icons/cdl/Dots';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { OrderCancelPopup } from '../../../common/OrderCancelPopup/OrderCancelPopup';
import { useOrderExport } from '../../../offer/detail/common/hooks/useOrderExport';
import { OrderModel } from '../../model/OrderModel';
import { useOrderRedispatch } from '../common/useOrderRedispatch';

import { ProductPopup } from './ProductPopup';
import { useOrderCancel } from './useOrderCancel';

interface OrderDetailActionBarProps {
    order: OrderModel;
}

export const OrderDetailActionBar = ({ order }: OrderDetailActionBarProps) => {
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [showProductPopup, setShowProductPopup] = useState(false);
    const { addErrorToast, addToast, addLoadingToast } = useToasts();

    const cancelOrderMutation = useOrderCancel(order);
    const redispatchOrderMutation = useOrderRedispatch();
    const { downloadPdf } = useOrderExport();

    const role = useRole();
    const navigate = useNavigate();
    const location = useLocation();

    const copy = (order) => {
        navigate({
            to: '/fuel/create',
            state: {
                order,
                originLocation: location,
            },
        });
    };

    const edit = (orderId) => {
        navigate({
            to: '/fuel/order/$id/edit',
            params: {
                id: orderId,
            },
        });
    };

    const onCancelPopupSubmit = async ({ cancelReason }) => {
        await cancelOrderMutation.cancelOrder(cancelReason);
        setShowCancelPopup(false);
    };

    const options: DropdownMenuOption[] = [];

    if (role.isCustomer() && role.hasWriteRights(order.customerId)) {
        options.push({
            label: translate('fuelOrder.actions.copy'),
            onSelect: () => copy(order),
        });
    }

    if (role.isAdmin()) {
        options.push({
            label: translate('fuelOrder.actions.redispatch'),
            onSelect: () => {
                redispatchOrderMutation.mutate(order.id, {
                    onSuccess: () => {
                        addToast(translate('order.detail.redispatchSuccessToast'));
                    },
                    onError: () => {
                        addErrorToast(translate('order.detail.redispatchErrorToast'));
                    },
                });
            },
        });
    }

    options.push({
        label: translate('fuelOrder.actions.orderedProducts'),
        onSelect: () => setShowProductPopup(true),
    });

    options.push({
        label: translate('order.detail.header.actions.export'),
        onSelect: () => {
            return addLoadingToast(
                () => downloadPdf(order.id),
                translate('offer.detail.header.actions.export.downloadingPdf'),
                translate('offer.detail.header.actions.export.success'),
                translate('global.genericToastError')
            );
        },
    });

    if (role.isCustomer() && !order.isFinished() && role.hasWriteRights(order.customerId)) {
        options.push({
            label: translate('fuelOrder.actions.cancelAll'),
            onSelect: () => setShowCancelPopup(true),
            destructive: true,
        });
    }

    return (
        <>
            <ButtonGroup>
                {role.isCustomer() && role.hasWriteRights(order.customerId) && !order.isFinished() ? (
                    <Button leadingVisual={<IconPencil width={16} height={16} />} onClick={() => edit(order.id)}>
                        {translate('fuelOrder.actions.edit')}
                    </Button>
                ) : null}

                {role.isAdmin() && order.isOpen() ? (
                    <Button onClick={() => navigate({ to: '/fuel/offers', search: { searchQuery: order.id } })}>
                        {translate('fuelOrder.actions.showOfferList')}
                    </Button>
                ) : null}

                <DropdownMenu
                    options={options}
                    align="end"
                    trigger={<IconButton Icon={IconDots} emphasis="medium" />}
                />
            </ButtonGroup>

            {showCancelPopup ? (
                <OrderCancelPopup
                    isOpen={showCancelPopup}
                    onSubmit={onCancelPopupSubmit}
                    onDismiss={() => setShowCancelPopup(false)}
                    isSubmitting={cancelOrderMutation.isPending}
                />
            ) : null}

            <ProductPopup isOpen={showProductPopup} onDismiss={() => setShowProductPopup(false)} order={order} />
        </>
    );
};
