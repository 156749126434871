import { createQueryKeys } from '@lukemorales/query-key-factory';

import { SessionService } from '../../../user/session/SessionService';

export const role = createQueryKeys('role', {
    information: {
        queryKey: null,
        queryFn: () => SessionService.getInstance().loadRoleInformation(),
    },
});
