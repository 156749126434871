import { useNavigate, useSearch } from '@tanstack/react-router';

import { ProductContext } from '../../../types/ProductContext';
import { CenteredPagination } from '../../cdl/Pagination/CenteredPagination';
import { Text } from '../../cdl/Text/Text';
import { Error } from '../../common/Error/Error';
import { translate } from '../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { NoSearchResults } from '../../common/Search/NoSearchResults/NoSearchResults';
import { useOfferPagination } from '../../offer/useOfferPagination';

import { SearchResultOfferTable } from './SearchResultOfferTable';

export const AdminSearchResultOfferTable = () => {
    const { query, offersPage, ordersPage } = useSearch({ from: '/_app/search' });
    const navigate = useNavigate({ from: '/search' });

    const paginatedOffersQuery = useOfferPagination({
        page: offersPage,
        searchQuery: query,
        types: [ProductContext.FUEL, ProductContext.LUBES],
    });

    if (paginatedOffersQuery.isPending) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.enquiries')}
                </Text>

                <LoadingIndicator />
            </div>
        );
    }

    if (paginatedOffersQuery.isError) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.enquiries')}
                </Text>

                <Error />
            </div>
        );
    }

    if (paginatedOffersQuery.data.content.length === 0) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.enquiries')}
                </Text>

                <NoSearchResults />
            </div>
        );
    }

    return (
        <div>
            <Text as="h2" variant="subtitle1">
                {translate('searchResult.enquiries')}
            </Text>

            <div>
                <SearchResultOfferTable offers={paginatedOffersQuery.data.content} />
                <CenteredPagination
                    pageTotal={paginatedOffersQuery.pageTotal}
                    currentPage={offersPage}
                    onPageChange={(page) => navigate({ search: { query, offersPage: page, ordersPage } })}
                />
            </div>
        </div>
    );
};
