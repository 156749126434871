import { useNavigate, useSearch } from '@tanstack/react-router';

import { ProductMessage } from '../../../../types/ProductMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { InfoPopover } from '../../../common/InfoPopover/InfoPopover';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { Flex } from '../../../common/ui/Flex';
import { useProductsPagination } from '../hooks/useProductsPagination';

import { ArchiveProductButton } from './ArchiveProductButton';
import { UpdateProductButton } from './UpdateProductButton';

export const ProductsTable = () => {
    const { getHumanReadableValue } = useEnums();

    const search = useSearch({ from: '/_app/_lubes/products' });
    const navigate = useNavigate({ from: '/products' });

    const productsPaginationQuery = useProductsPagination(search.searchQuery, search.page);

    if (productsPaginationQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (productsPaginationQuery.isError) {
        return <CenteredPageError />;
    }

    if (productsPaginationQuery.data.content.length === 0) {
        return <NoSearchResults variant="page" />;
    }

    return (
        <div>
            <TableBuilder data={productsPaginationQuery.data.content} grow={false}>
                <TableBuilderColumn<ProductMessage>
                    header={translate('productsOverview.labels.name')}
                    width="20%"
                    truncate
                >
                    {(row) => row.name}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage>
                    header={translate('productsOverview.labels.supplier')}
                    width="10%"
                    truncate
                >
                    {(row) => getHumanReadableValue('supplierProductGroup', row.supplierProductGroup)}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage>
                    header={translate('productsOverview.labels.group')}
                    width="10%"
                    truncate
                >
                    {(row) => getHumanReadableValue('productGroup', row.group)}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.packType')} width="10%">
                    {(row) => getHumanReadableValue('packType', row.packTypeDefault)}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage>
                    header={translate('productsOverview.labels.unitDefault')}
                    width="10%"
                >
                    {(row) => getHumanReadableValue('productUnit', row.unitDefault)}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.publicId')} width="10%">
                    {(row) => (
                        <Flex alignItems="center" gap={3}>
                            {row.publicId}
                            <InfoPopover>
                                {translate('productsOverview.labels.internalId', {
                                    internalId: row.id,
                                })}
                            </InfoPopover>
                        </Flex>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.state')} width="10%">
                    {(row) =>
                        row.archived
                            ? translate('productsOverview.labels.archived')
                            : translate('productsOverview.labels.active')
                    }
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header="" width="5%">
                    {(row) => (
                        <ButtonGroup>
                            <UpdateProductButton product={row} />
                            <ArchiveProductButton product={row} />
                        </ButtonGroup>
                    )}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                currentPage={search.page}
                pageTotal={productsPaginationQuery.pageTotal}
                onPageChange={(page) => {
                    navigate({ search: (previousSearch) => ({ ...previousSearch, page }) });
                }}
            />
        </div>
    );
};
