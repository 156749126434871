import { ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

import { Text } from '../../../cdl/Text/Text';
import { Box } from '../../ui/Box';
import { get } from '../../ui/get';

interface KeyShapeProps {
    isSquare: boolean;
}

const KeyShape = styled.div<KeyShapeProps>`
    display: flex;
    height: ${({ isSquare }) => (isSquare ? '24px' : 'auto')};
    width: ${({ isSquare }) => (isSquare ? '24px' : 'auto')};

    padding: ${({ isSquare }) => (isSquare ? 0 : '4px 6px')};

    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${get('space.4')}px;
    border-radius: ${get('radii.1')}px;
    border: 0.5px solid ${get('colors.border.default')};
    background: ${get('colors.background.default')};
    box-shadow:
        0px -2px 0px 0px rgba(95, 125, 139, 0.2) inset,
        0px 1px 2px 1px rgba(25, 54, 67, 0.1);
`;

type KeyProps = IconOnly | TextOnly;

type IconOnly = { Icon: ComponentType<{ width?: number; height?: number }>; children?: never };
type TextOnly = { Icon?: never; children: ReactNode };

export const Key = ({ Icon, children }: KeyProps) => {
    return (
        <KeyShape isSquare={!!Icon}>
            {Icon ? (
                <Box marginBottom="2px">
                    <Icon width={14} height={14} />
                </Box>
            ) : null}

            {children ? (
                <Text variant="extraSmall" marginBottom="2px">
                    {children}
                </Text>
            ) : null}
        </KeyShape>
    );
};
