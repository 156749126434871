import { fallback } from '@tanstack/router-zod-adapter';
import z from 'zod';

const FuelOrderSortDirectionArray = ['ASC', 'DESC'] as const;

export type FuelOrderSortDirection = (typeof FuelOrderSortDirectionArray)[number];

const FuelOrderSortFieldsArray = ['dateUpdated', 'dateDelivery', 'dateCreated', 'validUntil'] as const;

export type FuelOrderSortFields = (typeof FuelOrderSortFieldsArray)[number];

const FuelOrderTabsArray = ['drafted', 'enquired', 'ordered', 'delivered', 'canceled'] as const;

export type FuelOrderTabs = (typeof FuelOrderTabsArray)[number];

export const fuelOrderOverviewSearchSchema = z.object({
    page: fallback(z.number(), 0).default(0),
    searchQuery: fallback(z.string(), '').default(''),
    tab: fallback(z.enum(FuelOrderTabsArray), 'enquired').default('enquired'),
    sortField: fallback(z.enum(FuelOrderSortFieldsArray), 'validUntil').default('validUntil'),
    sortDirection: fallback(z.enum(FuelOrderSortDirectionArray), 'ASC').default('ASC'),
    customerIds: fallback(z.array(z.string()), []).default([]),
    supplierIds: fallback(z.array(z.string()), []).default([]),
});
