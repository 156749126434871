import { ReactNode, useReducer } from 'react';

import { DrawerContext } from './DrawerContext';
import { layerStoreReducer } from './layerStoreReducer';

type DrawerContextProviderProps = {
    children: ReactNode;
};

export const DrawerContextProvider = ({ children }: DrawerContextProviderProps) => {
    const [store, dispatch] = useReducer(layerStoreReducer, {
        layers: [],
    });

    return <DrawerContext.Provider value={{ store, dispatch }}>{children}</DrawerContext.Provider>;
};
