import closelinkImageUrl from '../images/closelink-blue.svg';

export const LoadingApp = () => {
    return (
        <div className="app-loading">
            <div className="spinner-content">
                <div className="img-container">
                    <img alt="Closelink" src={closelinkImageUrl} />
                </div>

                <div style={{ margin: '0 auto' }}>
                    <div className="line-wobble" />
                </div>
            </div>
        </div>
    );
};
