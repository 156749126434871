import { useSearch } from '@tanstack/react-router';

import { Error } from '../../common/Error/Error';
import { useProductContext } from '../../common/hooks/useProductContext';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { useCustomerStatistics } from '../hooks/useCustomerStatistics';

import { CustomerStatisticsContent } from './CustomerStatisticsContent';
import { CustomerStatisticsTabSwitch } from './CustomerStatisticsTabSwitch';
import { NoStatistics } from './NoStatistics';
import { StatisticsCenteredFrame } from './StatisticsCenteredFrame';

export const CustomerStatistics = () => {
    const { isFuel, context } = useProductContext();
    const search = useSearch({ from: isFuel ? '/_app/fuel/statistics' : '/_app/_lubes/statistics' });

    const customerStatisticsQuery = useCustomerStatistics({
        from: search.from,
        to: search.to,
        customerIds: search.customerIds,
        type: context,
    });

    if (customerStatisticsQuery.isPending) {
        return (
            <div>
                <CustomerStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <LoadingIndicator />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    if (customerStatisticsQuery.isError) {
        return (
            <StatisticsCenteredFrame>
                <Error />
            </StatisticsCenteredFrame>
        );
    }

    if (customerStatisticsQuery.data?.general.completedCount === 0) {
        return (
            <div>
                <CustomerStatisticsTabSwitch />
                <StatisticsCenteredFrame>
                    <NoStatistics />
                </StatisticsCenteredFrame>
            </div>
        );
    }

    return (
        <div>
            <CustomerStatisticsTabSwitch />
            <CustomerStatisticsContent statistics={customerStatisticsQuery.data} />
        </div>
    );
};
