import { useNavigate, useSearch } from '@tanstack/react-router';

import { ProductContext } from '../../../types/ProductContext';
import { Text } from '../../cdl/Text/Text';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { OverviewCustomerCompanySelect } from '../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';

import { DownloadPriceListButton } from './components/DownloadPriceListButton';
import { InviteSupplierButton } from './components/InviteSupplierButton';
import { SuppliersOverviewTable } from './components/SuppliersOverviewTable';

export const SuppliersOverviewPage = () => {
    useDocumentTitle(translate('page.suppliers'));
    const role = useRole();

    const navigate = useNavigate({ from: '/suppliers' });
    const search = useSearch({ from: '/_app/_lubes/suppliers' });

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
                    <Text variant="headline" as="h1">
                        {translate('assignments.suppliers.subheadline')}
                    </Text>

                    <ButtonGroup>
                        <DownloadPriceListButton />
                        <InviteSupplierButton />
                    </ButtonGroup>
                </Box>

                {!role.isOneCompanyUser(ProductContext.LUBES) ? (
                    <Box display="flex">
                        <OverviewCustomerCompanySelect
                            value={search.customerIds}
                            onChange={(customerIds) => {
                                navigate({
                                    search: (previousSearch) => ({
                                        ...previousSearch,
                                        customerIds,
                                        page: 0,
                                    }),
                                });
                            }}
                            context={ProductContext.LUBES}
                        />
                    </Box>
                ) : null}

                <SuppliersOverviewTable />
            </PageGrid>
        </Page>
    );
};
