import { LinkProps } from '@tanstack/react-router';
import { Children, ReactElement, ReactNode } from 'react';

import { Characters } from '../../common/helpers/unicode.helper';
import { Text } from '../Text/Text';

import { DefaultCell } from './components/DefaultCell';
import { DefaultCellText } from './components/DefaultCellText';
import { InlineCell } from './components/InlineCell';
import { StyledDefaultHeaderCell } from './components/StyledDefaultHeaderCell';
import { StyledDefaultTableBodyRow } from './components/StyledDefaultTableBodyRow';
import { StyledInlineHeaderCell } from './components/StyledInlineHeaderCell';
import { StyledInlineTableBodyRow } from './components/StyledInlineTableBodyRow';
import { StyledTable } from './components/StyledTable';
import { TableBuilderColumnProps } from './TableBuilderColumn';
import { TableRowLink } from './TableRowLink';

interface TableBuilderDefaultVariantProps<T> {
    variant?: 'default';
    data?: T[];
    activeIndex?: number;
    onRowSelect?: (row: T, index: number) => void;
    alternateRowColors?: boolean;
    children: ReactNode;
    isLoading?: boolean;
    rowLink?: (row: T) => LinkProps;
    grow?: boolean;
}

interface TableBuilderInlineVariantProps<T> {
    variant: 'inline';
    data?: T[];
    activeIndex?: never;
    onRowSelect?: never;
    alternateRowColors?: never;
    children: ReactNode;
    rowLink?: never;
    grow?: never;
}

export type TableBuilderProps<T> = TableBuilderDefaultVariantProps<T> | TableBuilderInlineVariantProps<T>;

export function TableBuilder<T>(props: TableBuilderProps<T>) {
    const {
        children,
        data,
        variant = 'default',
        alternateRowColors = true,
        grow = true,
        activeIndex,
        onRowSelect,
        rowLink,
    } = props;

    const columns = Children.toArray(children)
        .filter((it): it is ReactElement => it !== null && it !== undefined && typeof it === 'object')
        .map((it) => it.props as TableBuilderColumnProps<T>);

    const DynamicTableHeaderComponent = variant === 'default' ? StyledDefaultHeaderCell : StyledInlineHeaderCell;

    return (
        <StyledTable variant={variant}>
            <colgroup>
                {columns.map((column, index) => (
                    <col
                        key={index}
                        style={{
                            width: column.width ?? 'auto',
                        }}
                    />
                ))}
            </colgroup>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <DynamicTableHeaderComponent key={index} textAlign={column.numeric ? 'right' : 'left'}>
                            <Text variant="fieldLabel" color="foreground.muted">
                                {column.header}
                            </Text>
                        </DynamicTableHeaderComponent>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data?.map((row, rowIndex) => {
                    if (variant === 'default') {
                        const { isLoading } = props as TableBuilderDefaultVariantProps<T>;

                        return (
                            <StyledDefaultTableBodyRow
                                key={rowIndex}
                                active={activeIndex === rowIndex}
                                selectable={onRowSelect !== undefined}
                                onClick={() => onRowSelect?.(row, rowIndex)}
                                alternateRowColors={alternateRowColors}
                                grow={grow}
                            >
                                {columns.map((column, columnIndex) => {
                                    const children = column.children(row, rowIndex);
                                    const shouldShowTitle =
                                        typeof children === 'string' || typeof children === 'number';

                                    return (
                                        <DefaultCell
                                            key={columnIndex}
                                            numeric={column.numeric}
                                            interactive={column.interactive}
                                            isLoading={isLoading}
                                            hideOnLoading={column.hideOnLoading}
                                            truncate={column.truncate}
                                        >
                                            {rowLink && !column.interactive ? (
                                                <TableRowLink
                                                    to={rowLink(row).to}
                                                    params={rowLink(row).params}
                                                    search={rowLink(row).search}
                                                    state={rowLink(row).state}
                                                    grow={grow}
                                                    numeric={column.numeric}
                                                >
                                                    <DefaultCellText
                                                        title={shouldShowTitle ? children?.toString() : undefined}
                                                        noWrap={!grow}
                                                    >
                                                        {children ?? Characters.ZERO_WIDTH_SPACE}
                                                    </DefaultCellText>
                                                </TableRowLink>
                                            ) : (
                                                <DefaultCellText
                                                    title={shouldShowTitle ? children?.toString() : undefined}
                                                    noWrap={!grow}
                                                >
                                                    {children}
                                                </DefaultCellText>
                                            )}
                                        </DefaultCell>
                                    );
                                })}
                            </StyledDefaultTableBodyRow>
                        );
                    } else {
                        return (
                            <StyledInlineTableBodyRow key={rowIndex}>
                                {columns.map((column, columnIndex) => (
                                    <InlineCell
                                        key={columnIndex}
                                        numeric={column.numeric}
                                        interactive={column.interactive}
                                    >
                                        <Text variant="small">{column.children(row, rowIndex)}</Text>
                                    </InlineCell>
                                ))}
                            </StyledInlineTableBodyRow>
                        );
                    }
                })}
            </tbody>
        </StyledTable>
    );
}
