import { useNavigate } from '@tanstack/react-router';

import { translate } from '../../helpers/translate.helper';
import { IconLogout } from '../../icons/cdl/Logout';

import { CommandItem } from './CommandItem';

export const Logout = () => {
    const navigate = useNavigate();

    return (
        <CommandItem
            onSelect={() => {
                navigate({
                    to: '/logout',
                });
            }}
            Icon={IconLogout}
            destructive
        >
            {translate('shortcutMenu.logout')}
        </CommandItem>
    );
};
