import { translate } from '../../../helpers/translate.helper';
import { LabelText } from '../../../LabelText/LabelText';
import { Th } from '../../../Table/Th';
import { Tr } from '../../../Table/Tr';
import { useLubesProductTableConfig } from '../hooks/useLubesProductTableConfig';

export const TableHeaderRow = () => {
    const { showPrices, editable } = useLubesProductTableConfig();

    return (
        <Tr>
            <Th width="25%">
                <LabelText>{translate('order.product')}</LabelText>
            </Th>
            <Th width="10%">
                <LabelText>{translate('order.productgroup')}</LabelText>
            </Th>
            <Th width="15%">
                <LabelText>{translate('order.packtype')}</LabelText>
            </Th>
            <Th width="7%">
                <LabelText>{translate('order.unitsize')}</LabelText>
            </Th>
            <Th width="7%">
                <LabelText>{translate('order.units')}</LabelText>
            </Th>
            <Th width="5%">
                <LabelText as="div" textAlign="right">
                    {translate('order.volume')}
                </LabelText>
            </Th>
            <Th width="7%">
                <LabelText>{translate('order.unit')}</LabelText>
            </Th>

            {showPrices ? (
                <>
                    <Th width="10%">
                        <LabelText>{translate('offer.edit.lubes.pricePerLiter')}</LabelText>
                    </Th>
                    <Th width="10%">
                        <LabelText as="div" textAlign="right">
                            {translate('order.total')}
                        </LabelText>
                    </Th>
                </>
            ) : null}

            {/*@ts-ignore*/}
            {editable ? <Th width="3%" /> : null}
        </Tr>
    );
};
