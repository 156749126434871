import { Text } from '../../../cdl/Text/Text';
import { Divider } from '../../../common/Divider/Divider';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { HeaderWithLine } from '../../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Section } from '../../../common/Section/Section';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { useCustomerSettings } from '../../../company/customer/detail/useCustomerSettings';
import { useOfferApprovalSettings } from '../../MultipleApprovers/hooks/useOfferApprovalSettings';
import { OfferApprovalActivity } from '../../OfferApprovalActivity';
import { OfferChat } from '../../OfferChat/OfferChat';
import { OfferDatasheet } from '../../OfferCoreData/OfferDatasheet';
import { InvolvedParties } from '../common/components/InvolvedParties';
import { OfferDetailAttachments } from '../common/components/OfferDetailAttachments';
import { OfferDetailLayoutGrid } from '../common/components/OfferDetailLayoutGrid';
import { useOfferDetails } from '../common/hooks/useOfferDetails';
import { OfferPositions } from '../OfferPositions/OfferPositions';

import { LubesOfferDetailActionBar } from './LubesOfferDetailActionBar';
import { LubesOfferDetailStatusBar } from './LubesOfferDetailStatusBar';
import { useFee } from './useFee';
import { LubesReadOnlyProductTable } from '../../../order/detail/lubes/components/LubesReadOnlyTable/LubesReadOnlyProductTable';
import { useParams } from '@tanstack/react-router';

const selectTitle = (offer, titleDefinition) => {
    if (offer.isOrder()) {
        return titleDefinition.order;
    }

    return titleDefinition.enquiry;
};

const Title = ({ offer }) => {
    return selectTitle(offer, {
        order: translate('offer.detail.header.headlineOrder'),
        enquiry: translate('offer.detail.header.headlineEnquiry'),
    });
};

const getDocumentTitle = (offer) => {
    return selectTitle(offer, {
        order: translate('page.offerShowOrder', {
            portName: offer.port.name,
            vesselName: offer.vessel.name,
        }),
        enquiry: translate('page.offerShowEnquiry', {
            portName: offer.port.name,
            vesselName: offer.vessel.name,
        }),
    });
};

export const LubesOfferDetailPage = () => {
    const { id } = useParams({ from: '/_app/_lubes/offer/$id' });
    const role = useRole();
    const offerDetailsQuery = useOfferDetails(id);
    const offerApprovalSettingsQuery = useOfferApprovalSettings(id, role.isCustomer());
    const feeQuery = useFee(id);

    const offer = offerDetailsQuery.data;
    const customerSettingsQuery = useCustomerSettings(offer?.customerId, !!offer?.customerId && !!role.isCustomer());

    const documentTitle = offer ? getDocumentTitle(offer) : null;
    useDocumentTitle(documentTitle);

    const showFeeBox = Boolean(
        offer &&
            !offer.isCanceled() &&
            offer.state !== 'ENQUIRY' &&
            offer.isSpot() &&
            (role.isAdmin() || role.isSupplier())
    );

    if (offerDetailsQuery.isPending || offerApprovalSettingsQuery.isLoading || customerSettingsQuery.isLoading) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (offerDetailsQuery.isError) {
        return (
            <Page>
                <CenteredPageError />
            </Page>
        );
    }

    return (
        <Page>
            <PageGrid>
                <HeaderWithLine>
                    <Text as="h1" variant="headline">
                        <Title offer={offer} />
                    </Text>

                    <Flex flexDirection="row">
                        {role.isCustomer() || role.isAdmin() ? (
                            <Box marginRight={8}>
                                <OfferApprovalActivity
                                    offer={offer}
                                    offerApprovalSettings={offerApprovalSettingsQuery.data}
                                />
                            </Box>
                        ) : null}

                        <LubesOfferDetailActionBar offer={offer} />
                    </Flex>
                </HeaderWithLine>

                <Box display="flex" flexDirection="column" rowGap={9}>
                    <LubesOfferDetailStatusBar offer={offer} customerSettings={customerSettingsQuery.data} />

                    <InvolvedParties offer={offer} />

                    <OfferDetailLayoutGrid>
                        <Box display="flex" flexDirection="column" gap={9}>
                            <Section title={translate('offer.detail.details')} fullHeight={false}>
                                <OfferDatasheet offer={offer} fee={showFeeBox ? feeQuery.data?.fee : null} />
                            </Section>

                            <Section title={translate('offer.detail.products')} fullHeight={false} marginBottom={5}>
                                <LubesReadOnlyProductTable
                                    items={offer.items}
                                    surcharges={offer.surcharges}
                                    sampleKits={offer.samplekits}
                                    totals={{ volume: offer.volume, price: offer.ppl, total: offer.total }}
                                    showPrices
                                />
                            </Section>
                            <Box>
                                <Box display="flex" flexDirection="column" paddingBottom={5} gap={5}>
                                    <Text variant="title" as="h2">
                                        {translate('offer.detail.attachments')}
                                    </Text>
                                    {role.isAdmin() || offer.isCanceled() ? <Divider /> : null}
                                </Box>
                                <OfferDetailAttachments offer={offer} />
                            </Box>
                        </Box>

                        <Box display="flex" flexDirection="column" gap={9}>
                            <Section title={translate('offer.detail.position')} fullHeight={false}>
                                <OfferPositions offer={offer} />
                            </Section>
                            <Box>
                                <Box paddingBottom={5}>
                                    <Text variant="title" as="h2">
                                        {translate('offer.detail.chat')}
                                    </Text>
                                </Box>

                                <Box
                                    borderStyle="solid"
                                    borderWidth="1px"
                                    borderColor="border.muted"
                                    minHeight="350px"
                                    maxHeight="600px"
                                    overflow="hidden"
                                    height="calc(100% - 56px)"
                                >
                                    <OfferChat offer={offer} />
                                </Box>
                            </Box>
                        </Box>
                    </OfferDetailLayoutGrid>
                </Box>
            </PageGrid>
        </Page>
    );
};
