import styled from 'styled-components';

import { get } from '../../common/ui/get';
import { Text } from '../Text/Text';

interface FrameProps {
    hasImage: boolean;
}

const Frame = styled.div<FrameProps>`
    height: 40px;
    width: 40px;

    border-radius: ${(props) => (props.hasImage ? '0px' : '50%')};
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => (props.hasImage ? 'transparent' : get('colors.border.muted'))};

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

interface AvatarProps {
    imageUrl?: string;
    name: string;
}

export const Avatar = ({ imageUrl, name }: AvatarProps) => {
    return (
        <Frame hasImage={!!imageUrl}>
            {imageUrl ? (
                <img style={{ width: '40px' }} alt={name} src={imageUrl} />
            ) : (
                <Text variant="subtitle1">{name.charAt(0)}</Text>
            )}
        </Frame>
    );
};
