import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';

import { ToastsProvider } from '../cdl/Toast/ToastsProvider';
import { CenteredPageError } from '../common/Error/CenteredPageError';
import { ThemeProvider } from '../common/ThemeProvider';
import { Toolbox } from '../dev-tools/components/Toolbox';
import { UserlikeMessengerContext } from '../userlike/UserlikeMessengerContext';
import { useUserlikeMessenger } from '../userlike/useUserlikeMessenger';

const Root = () => {
    const messenger = useUserlikeMessenger();
    return (
        <ThemeProvider>
            <UserlikeMessengerContext.Provider value={messenger}>
                <Outlet />
            </UserlikeMessengerContext.Provider>
            <Toolbox />
            <ToastsProvider />
        </ThemeProvider>
    );
};

interface AppRouteContext {
    queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<AppRouteContext>()({
    component: Root,
    errorComponent: CenteredPageError,
});
