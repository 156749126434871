import { useState } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconExport } from '../../../../common/icons/cdl/Export';
import { OfferModel } from '../../../model/OfferModel';
import { ShareOrderPopup } from '../../common/components/ShareOrderPopup';
import { useVesselEdit } from '../../common/hooks/useVesselEdit';

interface ShareOrderButtonProps {
    offer: OfferModel;
}

export const ShareOrderButton = ({ offer }: ShareOrderButtonProps) => {
    const vesselEditMutation = useVesselEdit(offer.vesselId);

    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const onShareOrderSuccess = (vesselEmailAddress) => {
        setShowConfirmPopup(false);

        if (!vesselEmailAddress) {
            return;
        }

        vesselEditMutation.mutate({
            vesselId: offer.vesselId,
            vessel: {
                ...offer.vessel,
                email: vesselEmailAddress,
            },
        });
    };
    return (
        <>
            <Button leadingVisual={<IconExport height={16} width={16} />} onClick={() => setShowConfirmPopup(true)}>
                {translate('offer.detail.header.actions.share')}
            </Button>

            {showConfirmPopup ? (
                <ShareOrderPopup
                    isOpen={showConfirmPopup}
                    onSuccess={onShareOrderSuccess}
                    onDismiss={() => setShowConfirmPopup(false)}
                    offer={offer}
                />
            ) : null}
        </>
    );
};
