import { useParams } from '@tanstack/react-router';
import { Fragment } from 'react';

import { Text } from '../../../cdl/Text/Text';
import { DataSheet } from '../../../common/DataSheet/DataSheet';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useEnums } from '../../../common/hooks/useEnums';
import { Box } from '../../../common/ui/Box';
import { useManagementUser } from '../hooks/useManagementUser';

import { BasicUserDataSheetContent } from './BasicUserDataSheetContent';
import { UserStatusDisplay } from './UserStatusDisplay';

export const AdminUserDataSheet = () => {
    const { getHumanReadableValue } = useEnums();
    const { id } = useParams({ from: '/_app/user/detail/$id' });

    const userDataQuery = useManagementUser(id);

    useDocumentTitle(
        userDataQuery.data
            ? translate('page.usershow', {
                  name: `${userDataQuery.data.firstname} ${userDataQuery.data.lastname}`,
              })
            : ''
    );

    if (userDataQuery.isPending) {
        return (
            <Box
                display="grid"
                gap={8}
                gridTemplateColumns={{
                    xs: '1fr',
                    md: '1fr 1fr',
                }}
            >
                <DataSheet.Skeleton rows={6} />
                <DataSheet.Skeleton rows={6} />
            </Box>
        );
    }

    if (userDataQuery.isError) {
        return <Error />;
    }

    const adminOnlyRows = [
        {
            label: translate('user.userType'),
            value: getHumanReadableValue('userType', userDataQuery.data.userType),
        },
        {
            label: translate('user.group'),
            value: userDataQuery.data.group?.name ?? undefined,
            condition: !!userDataQuery.data.group,
        },
        {
            label: translate('user.companies'),
            value: userDataQuery.data.companyAccesses
                ? userDataQuery.data.companyAccesses
                      .map((companyAccesses) => {
                          return formatCompanyName({
                              company: companyAccesses.company,
                              includeType: true,
                          });
                      })
                      .join(', ')
                : undefined,
            condition: !!userDataQuery.data.companyAccesses,
        },
        {
            label: translate('user.loginTimes'),
            value: userDataQuery.data.loginTimes ?? undefined,
            condition: !!userDataQuery.data.loginTimes,
        },
        {
            label: translate('user.dateLastLogin'),
            value: userDataQuery.data.dateLastLogin
                ? formatDateTime({
                      date: userDataQuery.data.dateLastLogin,
                  })
                : undefined,
            condition: !!userDataQuery.data.dateLastLogin,
        },
        {
            label: translate('user.dateCreated'),
            value: formatDateTime({
                date: userDataQuery.data.dateCreated,
            }),
        },
        {
            label: translate('user.dateUpdated'),
            value: formatDateTime({
                date: userDataQuery.data.dateUpdated,
            }),
        },
    ].filter(({ condition = true }) => condition);

    return (
        <Box
            display="grid"
            gap={8}
            gridTemplateColumns={{
                xs: '1fr',
                md: '1fr 1fr',
            }}
        >
            <div>
                <DataSheet>
                    <BasicUserDataSheetContent user={userDataQuery.data} />
                </DataSheet>
            </div>
            <div>
                <DataSheet>
                    <DataSheet.Label>{translate('user.status')}</DataSheet.Label>
                    <UserStatusDisplay status={userDataQuery.data.status} />

                    {adminOnlyRows.map((row) => {
                        return (
                            <Fragment key={row.label}>
                                <DataSheet.Label>{row.label}</DataSheet.Label>
                                <Text variant="body">{row.value}</Text>
                            </Fragment>
                        );
                    })}
                </DataSheet>
            </div>
        </Box>
    );
};
