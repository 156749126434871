import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../cdl/Text/Text.tsx';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box.tsx';
import { CreateContractForm } from '../ContractForm/CreateContractForm';

import { ContractOverviewTable } from './ContractOverviewTable';
import { ContractTab } from './contractTabsHelper';
import { ContractOverviewTableControls } from './components/ContractOverviewTableControls';
import { useNavigate } from '@tanstack/react-router';

export const ContractOverviewPage = () => {
    const role = useRole();
    const { push, pop } = useDrawer();

    const navigate = useNavigate({ from: '/fuel/contracts' });

    useDocumentTitle(translate('contracts.overviewPageTitle'));

    const openCreateModal = () => {
        push({
            width: '60vw',
            content: (
                <CreateContractForm
                    onSuccess={(contract) => {
                        if (contract.tender.enabled) {
                            navigate({
                                search: (previousSearch) => ({ ...previousSearch, tab: ContractTab.TENDERED }),
                            });
                        } else {
                            navigate({
                                search: (previousSearch) => ({ ...previousSearch, tab: ContractTab.CONTRACTED }),
                            });
                        }
                        pop();
                    }}
                />
            ),
        });
    };

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text variant="headline" as="h1">
                        {translate('contracts.overviewPageTitle')}
                    </Text>

                    {!role.isAdmin() && role.hasWriteRights() ? (
                        <Button emphasis="high" onClick={openCreateModal}>
                            {translate('contracts.createTitle')}
                        </Button>
                    ) : null}
                </Box>

                <ContractOverviewTableControls />

                <ContractOverviewTable />
            </PageGrid>
        </Page>
    );
};
