import { useNavigate } from '@tanstack/react-router';

import { translate } from '../../helpers/translate.helper';
import { IconArrowNarrowRight } from '../../icons/cdl/ArrowNarrowRight';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandItem } from './CommandItem';

export const Dashboard = () => {
    const navigate = useNavigate();
    const { setOpen } = useShortcutMenu();

    return (
        <CommandItem
            onSelect={() => {
                navigate({
                    to: '/dashboard',
                });

                setOpen(false);
            }}
            Icon={IconArrowNarrowRight}
        >
            {translate('shortcutMenu.dashboard')}
        </CommandItem>
    );
};
