import { useNavigate } from '@tanstack/react-router';
import { Command, useCommandState } from 'cmdk';

import { AgentGatewayMessage } from '../../../../types/AgentMessage';
import { useAgentPagination } from '../../../agent/useAgentPagination';
import { formatCompanyName } from '../../helpers/formatCompanyName.helper';
import { Characters } from '../../helpers/unicode.helper';
import { useDebounce } from '../../hooks/useDebounce';
import { useRole } from '../../hooks/useRole';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandEmptyState } from './CommandEmptyState';
import { CommandErrorState } from './CommandErrorState';
import { CommandItem } from './CommandItem';
import { CommandSkeletonList } from './CommandSkeletonList';

export const AgentSearch = () => {
    const search = useCommandState((state) => state.search);
    const debouncedSearch = useDebounce(search, 300);

    const { setOpen } = useShortcutMenu();
    const role = useRole();

    const query = useAgentPagination({ searchQuery: debouncedSearch, page: 0, active: true });

    const navigate = useNavigate();

    const onAgentSelect = (agentId: string) => {
        navigate({ to: '/agents/$id', params: { id: agentId } });
        setOpen(false);
    };

    if (query.isPending) {
        return <CommandSkeletonList />;
    }

    if (query.isError) {
        return <CommandErrorState />;
    }

    if (!query.data || query.data.content.length === 0) {
        return <CommandEmptyState />;
    }

    const formatAgent = (agent: AgentGatewayMessage) => {
        const agentCustomer = agent.customer
            ? formatCompanyName({ company: agent.customer, includeType: role.isAdmin() })
            : '';

        const parts = [agent.name, agentCustomer];

        return parts.filter(Boolean).join(Characters.EN_SPACE + Characters.MIDDLE_DOT + Characters.EN_SPACE);
    };

    return (
        <Command.List>
            {query.data.content.map((agent: AgentGatewayMessage) => (
                <CommandItem key={agent.id} value={agent.id} onSelect={() => onAgentSelect(agent.id)}>
                    {formatAgent(agent)}
                </CommandItem>
            ))}
        </Command.List>
    );
};
