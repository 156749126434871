import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Box } from '../../../common/ui/Box';

import { FuelOrderOverviewSortSelect } from './FuelOrderOverviewSortSelect';
import { FuelOrderOverviewTable } from './FuelOrderOverviewTable';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { ProductContext } from '../../../../types/ProductContext';
import { useNavigate, useSearch } from '@tanstack/react-router';

export const OrderOverviewPage = () => {
    const role = useRole();
    const { context } = useProductContext();

    const search = useSearch({ from: '/_app/fuel/orders' });
    const navigate = useNavigate({ from: '/fuel/orders' });

    useDocumentTitle(translate('order.overviewPageTitle'));

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {translate('order.overviewPageTitle')}
                </Text>

                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" gap={4}>
                        <Box width="250px">
                            <DebouncedSearchTextInput
                                placeholder={translate('order.searchInputPlaceholder')}
                                value={search.searchQuery}
                                onChange={(searchQuery) =>
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            searchQuery,
                                            page: 0,
                                        }),
                                    })
                                }
                            />
                        </Box>

                        {!role.isOneCompanyUser(context) || role.isAdmin() ? (
                            <OverviewCustomerCompanySelect
                                value={search.customerIds}
                                onChange={(customerIds) =>
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            customerIds,
                                            page: 0,
                                        }),
                                    })
                                }
                                context={ProductContext.FUEL}
                            />
                        ) : null}

                        {role.isAdmin() ? (
                            <OverviewSupplierCompanySelect
                                value={search.supplierIds}
                                onChange={(supplierIds) =>
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            supplierIds,
                                            page: 0,
                                        }),
                                    })
                                }
                                context={ProductContext.FUEL}
                            />
                        ) : null}
                    </Box>

                    <FuelOrderOverviewSortSelect
                        onChange={(sortValue) => {
                            navigate({
                                search: (previousSearch) => ({
                                    ...previousSearch,
                                    sortField: sortValue.sortField,
                                    sortDirection: sortValue.sortDirection,
                                    page: 0,
                                }),
                            });
                        }}
                        value={{ sortField: search.sortField, sortDirection: search.sortDirection }}
                    />
                </Box>

                <FuelOrderOverviewTable />
            </PageGrid>
        </Page>
    );
};
