import { Chart, ChartEvent, ChartOptions } from 'chart.js';

import { AverageConsumption } from '../../../../../types/AverageConsumption';
import { LubesConsumptionPreset } from '../../../../../types/LubesConsumptionPresetsRequestMessage';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { theme } from '../../../../common/ui/theme';

interface CustomChartEvent extends ChartEvent {
    chart: Chart<'line', number[], Date>;
}

interface UseChartOptionsParams {
    isFirstValueMissing: boolean;
    isLastValueMissing: boolean;
    consumptions: AverageConsumption[];
    preset: LubesConsumptionPreset;
    onActiveElementChange: (consumptionIndex: number) => void;
}

export const useChartOptions = ({
    isFirstValueMissing,
    isLastValueMissing,
    consumptions,
    preset,
    onActiveElementChange,
}: UseChartOptionsParams): ChartOptions<'line'> => {
    const consumptionNumbers = consumptions.map((consumption) =>
        preset.excludePortCalls ? consumption.averageConsumptionExclDaysAtPort : consumption.averageConsumption
    );

    const highestConsumption = Math.max(...consumptionNumbers);
    const lowestConsumption = Math.min(...consumptionNumbers);

    const highestCombinedConsumption = preset.fixedAverageDailyConsumption
        ? Math.max(highestConsumption, preset.fixedAverageDailyConsumption)
        : highestConsumption;
    const lowestCombinedConsumption = preset.fixedAverageDailyConsumption
        ? Math.min(lowestConsumption, preset.fixedAverageDailyConsumption)
        : lowestConsumption;

    const chartPaddingY = 0.05;
    const highestConsumptionWithMargin =
        highestCombinedConsumption !== 0
            ? highestCombinedConsumption + highestCombinedConsumption * chartPaddingY
            : chartPaddingY;
    const lowestConsumptionWithMargin =
        highestCombinedConsumption !== 0
            ? lowestCombinedConsumption - highestCombinedConsumption * chartPaddingY
            : -chartPaddingY;

    return {
        backgroundColor: (context) => {
            const canvas = context.chart.canvas;
            const gradient = context.chart.ctx.createLinearGradient(
                canvas.width / 2,
                0,
                canvas.width / 2,
                context.chart.height
            );
            gradient.addColorStop(0, 'rgba(181, 221, 255, 0.50)');
            gradient.addColorStop(1, 'rgba(181, 221, 255, 0.00)');

            return gradient;
        },
        onHover: (_event: CustomChartEvent, activeElements) => {
            if (activeElements.length) {
                const activeElement = activeElements[0];
                const dataIndex = activeElement.index;

                if (dataIndex) {
                    onActiveElementChange(dataIndex);
                }
            }
        },
        maintainAspectRatio: false,
        interaction: {
            mode: 'nearest',
            intersect: false,
        },
        elements: {
            line: {
                cubicInterpolationMode: 'monotone', // Smooths the line
            },
        },
        scales: {
            x: {
                display: false,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                min: lowestConsumptionWithMargin,
                max: highestConsumptionWithMargin,
                display: false,
                ticks: {
                    display: false,
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                displayColors: false,
                cornerRadius: 4,
                backgroundColor: theme.colors.background.emphasis,
                padding: {
                    x: 8,
                    top: 4,
                    bottom: 0,
                },
                titleSpacing: 0,
                callbacks: {
                    title: function (tooltipItems) {
                        const tooltipItem = tooltipItems[0];
                        if (tooltipItem) {
                            const date = tooltipItem.label;
                            if (date) {
                                return formatDateTime({ date: tooltipItem.label, format: 'll' });
                            }
                        }
                        return '';
                    },
                    label: function () {
                        return '';
                    },
                },
                filter: (tooltipItem, _index, _array, data) => {
                    const totalItems = data.labels?.length || 0;
                    if (isFirstValueMissing && tooltipItem.dataIndex === 0) {
                        return false;
                    }
                    if (isLastValueMissing && tooltipItem.dataIndex === totalItems - 1) {
                        return false;
                    }
                    return true;
                },
            },
        },
        layout: {
            autoPadding: false,
        },
    };
};
