import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';

import { Error } from './common/Error/Error';

interface ErrorBoundaryProps {
    children: ReactNode;
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
    return <Sentry.ErrorBoundary fallback={<Error />}>{children}</Sentry.ErrorBoundary>;
};
