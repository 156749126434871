import { useState } from 'react';

import { ApprovalRequestState } from '../../../types/ApprovalRequestState';
import { ProductContext } from '../../../types/ProductContext';
import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { APPROVAL_STATE } from '../../common/approvalState.constant';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { translate } from '../../common/helpers/translate.helper';
import { OverlayHeader } from '../../common/Overlay/OverlayHeader';
import { Box } from '../../common/ui/Box';
import { LubesReadOnlyProductTable } from '../../order/detail/lubes/components/LubesReadOnlyTable/LubesReadOnlyProductTable';
import { OfferModel } from '../model/OfferModel';
import { CustomerApprovalDataSheet } from '../OfferCoreData/CustomerApprovalDataSheet';

import { ApprovalRequestContentContainer } from './ApprovalRequestContentContainer';
import { ApprovalRequestsDropdown } from './ApprovalRequestsDropdown';
import { ApprovePopup } from './ApprovePopup';
import { DeclinePopup } from './DeclinePopup';
import { FuelApprovalContent } from './FuelApprovalContent';
import { PendingStatusBar } from './PendingStatusBar';

const ApprovalRequestPopups = {
    APPROVE: 'APPROVE',
    DECLINE: 'DECLINE',
} as const;

interface PendingStateProps {
    offer: OfferModel;
    approvalRequests: any[];
    onApprovalRequestApproved: (message?: string) => void;
    onApprovalRequestDeclined: (message?: string) => void;
    updatingApprovalRequestState: ApprovalRequestState;
}

export const PendingState = ({
    offer,
    approvalRequests,
    onApprovalRequestDeclined,
    onApprovalRequestApproved,
    updatingApprovalRequestState,
}: PendingStateProps) => {
    const [openPopup, setOpenPopup] = useState<keyof typeof ApprovalRequestPopups | null>(null);

    const isLubes = offer.type === ProductContext.LUBES;
    const subTitle = isLubes ? offer.offerNumber : offer.vessel.name;

    return (
        <OverlayHeader
            title={
                <Box display="flex" gap={5}>
                    <Text variant="title">{translate('approvalRequest.header.title')}</Text>
                    {isLubes ? (
                        <Text variant="title" color="foreground.subtle">
                            {subTitle}
                        </Text>
                    ) : null}
                </Box>
            }
            trailingVisual={
                <Box display="flex" alignItems="center">
                    <ApprovalRequestsDropdown approvalRequests={approvalRequests} />
                    <ButtonGroup>
                        <Button onClick={() => setOpenPopup(ApprovalRequestPopups.DECLINE)}>
                            {translate('approvalRequest.buttons.decline')}
                        </Button>
                        <Button
                            emphasis="high"
                            onClick={() => setOpenPopup(ApprovalRequestPopups.APPROVE)}
                            isLoading={updatingApprovalRequestState === APPROVAL_STATE.APPROVED}
                        >
                            {translate('approvalRequest.buttons.approve')}
                        </Button>
                    </ButtonGroup>
                </Box>
            }
        >
            <ApprovalRequestContentContainer>
                <PendingStatusBar approvalRequest={approvalRequests[0]} />

                {isLubes ? (
                    <>
                        <CustomerApprovalDataSheet offer={offer} />
                        <LubesReadOnlyProductTable
                            items={offer.items}
                            sampleKits={offer.samplekits}
                            surcharges={offer.surcharges}
                            totals={{ volume: offer.volume, price: offer.ppl, total: offer.total }}
                            showPrices
                        />
                    </>
                ) : (
                    <FuelApprovalContent offer={offer} />
                )}
            </ApprovalRequestContentContainer>

            <DeclinePopup
                open={openPopup === ApprovalRequestPopups.DECLINE}
                onDismiss={() => setOpenPopup(null)}
                onSubmit={onApprovalRequestDeclined}
                requestInProgress={updatingApprovalRequestState === APPROVAL_STATE.DECLINED}
            />

            <ApprovePopup
                open={openPopup === ApprovalRequestPopups.APPROVE}
                onDismiss={() => setOpenPopup(null)}
                onSubmit={onApprovalRequestApproved}
                requestInProgress={updatingApprovalRequestState === APPROVAL_STATE.APPROVED}
            />
        </OverlayHeader>
    );
};
