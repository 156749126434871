import { createMessenger, v2 } from '@userlike/messenger';
import { useEffect, useState } from 'react';

import AppSettings from '../constants';

export const useUserlikeMessenger = () => {
    const [api, setApi] = useState<v2.Api | null>(null);
    const [messenger, setMessenger] = useState<v2.Messenger | null>(null);

    useEffect(() => {
        createMessenger({
            version: 2,
            widgetKey: AppSettings.userlikeWidgetKey,
        }).then((result) => {
            setApi(result.kind === 'error' ? null : result.value.api);
        });
    }, []);

    useEffect(() => {
        if (api === null) {
            return;
        }

        const subscription = api?.mount().subscribe((result) => {
            setMessenger(result.kind === 'error' ? null : result.value);
        });

        return () => {
            subscription?.unsubscribe();
        };
    }, [api]);

    // Hide userlike button
    useEffect(() => {
        if (messenger !== null) {
            messenger.setVisibility({
                main: true,
                button: false,
                notifications: true,
            });
        }
    }, [messenger]);

    return messenger;
};
