import { useNavigate, useSearch } from '@tanstack/react-router';

import { ProductContext } from '../../../../types/ProductContext';
import { Text } from '../../../cdl/Text/Text';
import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

import { FuelOfferOverviewSortSelect } from './FuelOfferOverviewSortSelect';
import { FuelOfferOverviewTable } from './FuelOfferOverviewTable';

export const FuelOfferOverviewPage = () => {
    const role = useRole();
    const { context } = useProductContext();

    const title = translate(role.isAdmin() ? 'offer.overviewPageTitle' : 'order.overviewPageTitle');
    useDocumentTitle(title);

    const search = useSearch({ from: '/_app/fuel/offers' });
    const navigate = useNavigate({ from: '/fuel/offers' });

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {title}
                </Text>

                <Box display="flex" justifyContent="space-between" gap={4}>
                    <Box display="flex" gap={4}>
                        <Box width="250px">
                            <DebouncedSearchTextInput
                                placeholder={
                                    role.isAdmin()
                                        ? translate('offer.searchInputPlaceholder')
                                        : translate('order.searchInputPlaceholder')
                                }
                                value={search.searchQuery}
                                onChange={(searchQuery) =>
                                    navigate({
                                        search: (previousSearch) => ({ ...previousSearch, searchQuery, page: 0 }),
                                    })
                                }
                            />
                        </Box>

                        {role.isAdmin() ? (
                            <OverviewCustomerCompanySelect
                                value={search.customerIds}
                                onChange={(customerIds) => {
                                    navigate({
                                        search: (previousSearch) => ({ ...previousSearch, customerIds, page: 0 }),
                                    });
                                }}
                                context={ProductContext.FUEL}
                            />
                        ) : null}

                        {!role.isOneCompanyUser(context) || role.isAdmin() ? (
                            <OverviewSupplierCompanySelect
                                value={search.supplierIds}
                                onChange={(supplierIds) => {
                                    navigate({
                                        search: (previousSearch) => ({ ...previousSearch, supplierIds, page: 0 }),
                                    });
                                }}
                                context={ProductContext.FUEL}
                            />
                        ) : null}
                    </Box>

                    <FuelOfferOverviewSortSelect
                        onChange={(sortOption) => {
                            navigate({
                                search: (previousSearch) => ({
                                    ...previousSearch,
                                    sortField: sortOption.sortField,
                                    sortDirection: sortOption.sortDirection,
                                    page: 0,
                                }),
                            });
                        }}
                        value={{ sortField: search.sortField, sortDirection: search.sortDirection }}
                    />
                </Box>

                <FuelOfferOverviewTable />
            </PageGrid>
        </Page>
    );
};
