import { useSearch } from '@tanstack/react-router';

import { SupplierStatisticsMessage } from '../../../types/StatisticsMessage';
import { useProductContext } from '../../common/hooks/useProductContext';

import { CustomersTabContent } from './CustomersTabContent';
import { GeneralTabContent } from './GeneralTabContent';
import { PortsTabContent } from './PortsTabContent';
import { ProductGroupsTabContent } from './ProductGroupsTabContent';
import { ProductsTabContent } from './ProductsTabContent';
import { StatisticTab } from './StatisticTab';

interface SupplierStatisticsContentProps {
    statistics: SupplierStatisticsMessage;
}

interface SupplierStatisticsSearch {
    tab: StatisticTab;
}

export const SupplierStatisticsContent = ({ statistics }: SupplierStatisticsContentProps) => {
    const { isFuel } = useProductContext();

    const search: SupplierStatisticsSearch = useSearch({
        from: isFuel ? '/_app/fuel/statistics' : '/_app/_lubes/statistics',
    });

    switch (search.tab) {
        case StatisticTab.GENERAL:
            return <GeneralTabContent statistics={statistics} />;
        case StatisticTab.CUSTOMERS:
            return <CustomersTabContent customerStatistics={statistics.customer} />;
        case StatisticTab.PORTS:
            return <PortsTabContent portStatistics={statistics.port} />;
        case StatisticTab.PRODUCTS:
            return <ProductsTabContent productStatistics={statistics.product} />;
        case StatisticTab.PRODUCT_GROUPS:
            return <ProductGroupsTabContent productGroupsStatistics={statistics.productGroup} />;
        case StatisticTab.VESSELS:
            return null;
        case StatisticTab.SUPPLIERS:
            return null;
    }
};
