// @ts-expect-error - useId is not in @types/react
import { useContext, useId } from 'react';

import { raise } from '../../common/helpers/raise';

import { LabelContext } from './LabelContext';
import { LabelProvider } from './LabelProvider';

/**
 * Wrap a form field with the LabelProvider and use the Label component to have
 * a dynamically generated id that correctly binds label and the form input
 * together as expected by browsers.
 *
 * See `RadioGroupItem` implementaion as a usage reference
 */

export const useLabels = () => {
    const id = useId();
    return {
        id,
        LabelProvider,
    };
};

export const useLabelledBy = () => useLabelContext().id;

const useLabelContext = () => useContext(LabelContext) || raise('no context for label found');
