import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateOrder } from '../../../../common/api/clients/order.api';
import { queries } from '../../../../common/api/queryKeys/queries';

export const useEnquiryUpdate = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateOrder,
        onSuccess: (order) => {
            return queryClient.invalidateQueries({ queryKey: queries.orders.detail(order.id).queryKey });
        },
    });
};
