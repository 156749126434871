import { Command } from 'cmdk';

import { CommandSkeleton } from './CommandSkeleton';

export const CommandSkeletonList = () => {
    return (
        <Command.List>
            {Array.from({ length: 20 }).map((_, index) => {
                const randomWidth = Math.random() * (400 - 200) + 200;

                return <CommandSkeleton key={index} width={`${randomWidth}px`} />;
            })}
        </Command.List>
    );
};
