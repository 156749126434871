import { useNavigate, useParams, useSearch } from '@tanstack/react-router';

import { Text } from '../../cdl/Text/Text';
import { Error } from '../../common/Error/Error';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { useVesselDetails } from '../../common/hooks/useVesselDetails';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { TabSwitch } from '../../common/TabSwitch/TabSwitch';
import { Box } from '../../common/ui/Box';
import { VesselDetailPageContent, VesselDetailPageTabs } from '../VesselOverview/components/VesselDetailPageContent';

import { VesselActions } from './components/VesselActions';

const VesselDetailPageTotals = {
    overview: null,
    schedule: null,
    orders: null,
};

export const VesselDetailPage = () => {
    const role = useRole();
    const { isLubes, isFuel } = useProductContext();

    const routePath = isFuel ? '/_app/fuel/vessel/$id' : '/_app/_lubes/vessel/$id';

    const { id } = useParams({ from: routePath });
    const search = useSearch({ from: routePath });
    const navigate = useNavigate({ from: isFuel ? '/fuel/vessel/$id' : '/vessel/$id' });

    const { isPending, isError, data: vessel } = useVesselDetails(id);

    useDocumentTitle(
        vessel
            ? translate('vessel.documentTitle', {
                  name: vessel.name,
              })
            : undefined,
        [vessel]
    );

    const updateTab = (tab: VesselDetailPageTabs) => {
        navigate({ search: { tab } });
    };

    if (isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (isError) {
        return (
            <Page>
                <Error />
            </Page>
        );
    }

    return (
        <Page>
            <PageGrid>
                <div>
                    <Box display="flex" justifyContent="space-between" gap={6}>
                        <Text as="h1" variant="headline">
                            {vessel.name}
                        </Text>

                        {isLubes && role.hasWriteRights(vessel.customerId) ? <VesselActions vessel={vessel} /> : null}
                    </Box>
                    <Text variant="body" color="foreground.subtle">
                        {vessel.imo}
                    </Text>
                </div>

                <TabSwitch selectedContext={search.tab} onSelect={updateTab} totals={VesselDetailPageTotals} />

                <VesselDetailPageContent />
            </PageGrid>
        </Page>
    );
};
