import { Form, Formik } from 'formik';
import { FC, useState } from 'react';

import { ProductCategoryLubes } from '../../../../types/ProductCategoryLubes';
import { ProductMessage } from '../../../../types/ProductMessage';
import { VesselTankMessage } from '../../../../types/VesselTankMessage';
import { Button } from '../../../cdl/Button/Button';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Box } from '../../../common/ui/Box';

import { FormikTanksTable } from './components/FormikTanksTable';
import { useVesselTanks } from './hooks/useVesselTanks';
import { useVesselTanksMutation } from './hooks/useVesselTanksMutation';
import { handleFormikValidate } from './util/handleFormikValidate';

export interface UIVesselTankMessage
    extends Omit<VesselTankMessage, 'id' | 'category' | 'latestStock' | 'safetyReserve' | 'threshold' | 'maxVolume'> {
    defaultProduct?: ProductMessage;
    category: ProductCategoryLubes | '';
    threshold: number | string;
    maxVolume: number | string;
    safetyReserve?: number | string;
}

export interface FormikVesselTanksState {
    vesselTanks: UIVesselTankMessage[];
}

interface EditTanksPopupProps {
    vesselId: string;
    customerId: string;
    isOpen: boolean;
    onDismiss: () => void;
}

export const EditTanksModal: FC<EditTanksPopupProps> = ({ isOpen, vesselId, customerId, onDismiss }) => {
    const [hasFormChanged, setHasFormChanged] = useState(false);
    const { vesselTankResponse, isPending } = useVesselTanks(vesselId);
    const { mutateAsync: mutateVesselTanks, isPending: isMutationLoading } = useVesselTanksMutation(vesselId);

    const handleSubmit = async (updatedVesselTanks: FormikVesselTanksState) => {
        const vesselTanks = updatedVesselTanks.vesselTanks.map((tank) => ({
            ...tank,
            customerId: customerId,
            defaultProductId: tank.defaultProduct ? tank.defaultProduct.id : undefined,
        }));

        await mutateVesselTanks({
            vesselId: vesselId,
            vesselTanks,
        });

        setHasFormChanged(false);
        onDismiss();
    };

    const handleDismissWithGuard = () => {
        if (!hasFormChanged) {
            onDismiss();
        } else {
            if (confirm(translate('vessel.editTanksPopup.confirmExit'))) {
                setHasFormChanged(false);
                onDismiss();
            }
        }
    };

    return (
        <Modal onDismiss={handleDismissWithGuard} width={1680} isOpen={isOpen}>
            <Box display="flex" flexDirection="column" rowGap={5} padding={6}>
                <Text variant="title" as="h3">
                    {translate('vessel.editTanksPopup.headline')}
                </Text>

                {isPending || !vesselTankResponse.vesselTanks ? (
                    <Box paddingX={10} marginY={8}>
                        <LoadingIndicator />
                    </Box>
                ) : (
                    <Formik<FormikVesselTanksState>
                        initialValues={{ vesselTanks: vesselTankResponse.vesselTanks }}
                        enableReinitialize
                        validate={handleFormikValidate}
                        onSubmit={handleSubmit}
                    >
                        <Form onChange={() => setHasFormChanged(true)}>
                            <Text variant="body" as="p" marginBottom={5}>
                                {translate('vessel.editTanksPopup.description')}
                            </Text>

                            <FormikTanksTable />

                            <Box display="flex" columnGap={4} marginTop={5} justifyContent="end">
                                <Button type="button" emphasis="low" onClick={handleDismissWithGuard}>
                                    {translate('btn.cancel')}
                                </Button>
                                <Button emphasis="high" type="submit" isLoading={isMutationLoading}>
                                    {translate('btn.save.default')}
                                </Button>
                            </Box>
                        </Form>
                    </Formik>
                )}
            </Box>
        </Modal>
    );
};
