import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { Slot } from '@radix-ui/react-slot';
import { ReactNode, RefAttributes } from 'react';
import styled, { keyframes } from 'styled-components';

import { Box, BoxProps } from '../../common/ui/Box';
import { get } from '../../common/ui/get';
import { useLabelledBy, useLabels } from '../Label/useLabels';
import { Text } from '../Text/Text';

export const Description = styled(Text).attrs({
    as: 'p',
    variant: 'extraSmall',
    color: 'foreground.subtle',
})`
    margin: 0;
`;

const StyledRadioGroup = styled(RadixRadioGroup.Root)<{
    gap?: `${number}px`;
}>`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.gap || '4px'};
`;

export const RadioGroup = ({ children, asChild, ...props }: RadixRadioGroup.RadioGroupProps) => {
    if (!asChild) {
        return (
            <StyledRadioGroup asChild {...props}>
                <fieldset>{children}</fieldset>
            </StyledRadioGroup>
        );
    }

    return <Slot {...props}>{children}</Slot>;
};

const AnimationJumpIn = keyframes`
    0% { transform: scale(0); }
    100% { transform: scale(1); }
`;

const StyledRadioGroupItem = styled(Box)`
    display: grid;
    grid-template-areas: 'radio label';
    grid-template-columns: min-content 1fr;
    gap: 8px;
    row-gap: 4px;

    &:has(${Description}) {
        grid-template-areas: 'radio label' '. description';
    }

    ${Description} {
        grid-area: description;
    }
`;

export const RadioGroupIndicator = styled(RadixRadioGroup.Indicator)`
    display: block;
    width: 8px;
    height: 8px;
    border-radius: var(--radio-border-radius);
    background: ${get('colors.foreground.onEmphasis')};
    animation: ${AnimationJumpIn} 250ms ease-in-out;
`;

export const RadioButton = styled(RadixRadioGroup.Item)`
    --radio-border-radius: 50%;
    background: ${get('colors.background.muted')};
    transition: background 250ms ease-in-out;
    &:has(${RadioGroupIndicator}) {
        background: ${get('colors.accent.emphasis')};
    }
    width: 20px;
    height: 20px;
    border-radius: var(--radio-border-radius);
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: radio;
    place-self: center;
`;

export const Radio = ({
    children,
    ...props
}: RadixRadioGroup.RadioGroupItemProps & RefAttributes<HTMLButtonElement>) => (
    <RadioButton id={useLabelledBy()} {...props}>
        <RadioGroupIndicator />
        {children}
    </RadioButton>
);

type RadioGroupItemProps = {
    children: ReactNode;
} & BoxProps;

export const RadioGroupItem = ({ children, ...props }: RadioGroupItemProps) => {
    const { LabelProvider } = useLabels();
    return (
        <LabelProvider>
            {/* @ts-expect-error */}
            <StyledRadioGroupItem {...props}>{children}</StyledRadioGroupItem>
        </LabelProvider>
    );
};
