import { useParams } from '@tanstack/react-router';

import { useVesselDetails } from '../../../common/hooks/useVesselDetails';
import { Box } from '../../../common/ui/Box';
import { FuelVesselDetailsSection } from '../../VesselDetailPage/components/FuelVesselDetailsSection';
import { FuelStocks } from '../../VesselDetailPage/FuelStocks/FuelStocks';

export const FuelVesselDetailPageOverviewContent = () => {
    const { id } = useParams({ from: '/_app/fuel/vessel/$id' });
    const { data: vessel } = useVesselDetails(id);

    return (
        <Box display="grid" gridTemplateColumns="auto 25%" gap={9}>
            <FuelStocks vesselId={vessel.id} customerId={vessel.customerId} />

            <FuelVesselDetailsSection vessel={vessel} />
        </Box>
    );
};
