import { useNavigate } from '@tanstack/react-router';
import { Command, useCommandState } from 'cmdk';

import { PortMessage } from '../../../../types/PortMessage';
import { usePortPagination } from '../../../admin/port/list/hooks/usePortPagination';
import { formatPort } from '../../helpers/formatPort.helper';
import { useDebounce } from '../../hooks/useDebounce';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandEmptyState } from './CommandEmptyState';
import { CommandErrorState } from './CommandErrorState';
import { CommandItem } from './CommandItem';
import { CommandSkeletonList } from './CommandSkeletonList';

export const PortSearch = () => {
    const search = useCommandState((state) => state.search);
    const debouncedSearch = useDebounce(search, 300);
    const { setOpen } = useShortcutMenu();

    const query = usePortPagination(0, debouncedSearch);

    const navigate = useNavigate();

    const onPortSelect = (portId: string) => {
        navigate({ to: '/port/detail/$id', params: { id: portId } });
        setOpen(false);
    };

    if (query.isPending) {
        return <CommandSkeletonList />;
    }

    if (query.isError) {
        return <CommandErrorState />;
    }

    if (!query.data || query.data.content.length === 0) {
        return <CommandEmptyState />;
    }

    return (
        <Command.List>
            {query.data.content.map((port: PortMessage) => (
                <CommandItem key={port.id} value={port.id} onSelect={() => onPortSelect(port.id)}>
                    {formatPort({ port })}
                </CommandItem>
            ))}
        </Command.List>
    );
};
