import { Router } from '@tanstack/react-router';
import { AxiosInstance, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import storage from 'local-storage-fallback';

const AUTH_TOKEN_KEY = 'cl-session-token';

function injectAuthHeader(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    const accessToken = storage.getItem(AUTH_TOKEN_KEY);

    if (!accessToken) {
        return config;
    }

    return {
        ...config,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            ...config.headers,
        } as AxiosRequestHeaders,
    };
}

const handleAuthorizationError = (router: Router<any, any, any>) => (error: any) => {
    const authErrorStatusCodes = [401, 403];

    if (!authErrorStatusCodes.includes(error.response?.status)) {
        return Promise.reject(error);
    }

    router.navigate({ to: '/logout' });
};

export function addAuthenticationInterceptors(axios: AxiosInstance, router: Router<any, any, any>) {
    axios.interceptors.request.use(injectAuthHeader);
    axios.interceptors.response.use(null, handleAuthorizationError(router));
}
