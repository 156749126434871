import { Text } from '../../../cdl/Text/Text';
import { Error } from '../../../common/Error/Error';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { useSupplierDetails } from '../../../common/hooks/useSupplierDetails';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../../common/Page/Page';
import { Section } from '../../../common/Section/Section';
import { AdminSettings } from '../../AdminSettings';
import { GeneralInformation } from '../../GeneralInformation';

import { SupplierSettings } from './SupplierSettings';
import { useSupplierDetailsEdit } from './useSupplierDetailsEdit';
import { Box } from '../../../common/ui/Box';
import { useParams } from '@tanstack/react-router';

export const SupplierDetailPage = () => {
    const role = useRole();
    const { id } = useParams({ from: '/_app/supplier/detail/$id' });

    const supplierDetailQuery = useSupplierDetails(id);
    const editSupplierDetailsMutation = useSupplierDetailsEdit();

    useDocumentTitle(supplierDetailQuery.data ? supplierDetailQuery.data.name : null);

    if (supplierDetailQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (supplierDetailQuery.isError) {
        return (
            <Page>
                <Section>
                    <Error />
                </Section>
            </Page>
        );
    }

    const onSupplierDetailEditSubmit = (data) => {
        editSupplierDetailsMutation.mutate({ id, supplierData: data });
    };

    return (
        <Page>
            <Box display="grid" gap={9} gridTemplateColumns="1fr 1fr">
                <Box gridColumn="1/3">
                    <Text as="h1" variant="headline">
                        {supplierDetailQuery.data.name}
                    </Text>
                </Box>

                <Box gridColumn="1" gridRow="2">
                    <GeneralInformation
                        onEditSubmit={onSupplierDetailEditSubmit}
                        companyDetails={supplierDetailQuery.data}
                    />

                    {role.isAdmin() ? (
                        <Box marginTop={9}>
                            <AdminSettings
                                onEditSubmit={onSupplierDetailEditSubmit}
                                companyDetails={supplierDetailQuery.data}
                                companyType="SUPPLIER"
                            />
                        </Box>
                    ) : null}
                </Box>

                <Box gridColumn="2" gridRow="2">
                    <SupplierSettings supplier={supplierDetailQuery.data} />
                </Box>
            </Box>
        </Page>
    );
};
