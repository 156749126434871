import { ReactNode } from 'react';

export interface TableBuilderColumnProps<T> {
    header: ReactNode;
    numeric?: boolean;
    children: (row: T, index: number) => ReactNode;
    interactive?: boolean;
    hideOnLoading?: boolean;
    width?: string;
    truncate?: boolean;
}

// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function TableBuilderColumn<T>(props: TableBuilderColumnProps<T>) {
    // Renderless component. See TableBuilder.
    return null;
}
