import { useField } from 'formik';
import { useEffect } from 'react';

import { ProductMessage } from '../../../../../types/ProductMessage';
import { Text } from '../../../../cdl/Text/Text';
import { FormikHumanReadableEnumValue } from '../../../form-elements/formik/FormikHumanReadableEnumValue';
import { formatMoney } from '../../../helpers/formatMoney.helper';
import { formatNumber } from '../../../helpers/formatNumber.helper';
import { Item } from '../../../models/item.model';
import { ItemModel } from '../../../models/ItemModel';
import { Td } from '../../../Table/Td';
import { Tr } from '../../../Table/Tr';
import { Box } from '../../../ui/Box';
import { Flex } from '../../../ui/Flex';
import { useLubesProductTableConfig } from '../hooks/useLubesProductTableConfig';

import { PackTypeDisplay } from './PackTypeDisplay';
import { PriceDisplay } from './PriceDisplay';
import { ProductNameDisplay } from './ProductNameDisplay';
import { ProductRowAction } from './ProductRowAction';
import { ProductTd } from './ProductTd';
import { UnitDisplay } from './UnitDisplay';
import { UnitsDisplay } from './UnitsDisplay';
import { UnitSizeDisplay } from './UnitSizeDisplay';

interface ProductRowProps {
    name: string;
    index: number;
    isDeleteable: boolean;
    isLastRemainingEntry: boolean;
    isLastSelectedEntry: boolean;
    onRowRemoveClicked: () => void;
}

export const ProductRow = (props: ProductRowProps) => {
    const { editable, showPrices, editPrices } = useLubesProductTableConfig();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [itemField, _, itemFieldHelpers] = useField(props.name);

    const item = itemField.value;

    useEffect(() => {
        if (item.compute) {
            item.compute();
        }
    }, [item]);

    const onProductSelect = (product: ProductMessage) => {
        const existingItem: ItemModel = itemField.value;

        const previousPrice = {
            value: existingItem.price?.value ?? null,
            currency: existingItem.price?.currency ?? null,
        };

        const newItem = new Item({
            ...existingItem,
            productId: product.id,
            product,
            price: editPrices && previousPrice.value ? previousPrice : undefined,
        });

        newItem.initWithProduct(product);

        itemFieldHelpers.setValue(newItem);
    };

    return (
        <Tr inactive={item.deleted} focused={item.added && !item.deleted}>
            <Td>
                <ProductNameDisplay
                    name={props.name}
                    vesselIdName="vesselId"
                    onSelect={onProductSelect}
                    editable={editable}
                />
            </Td>
            <ProductTd>
                <Box display="flex" alignItems="center" height="40px">
                    <Text variant="body">
                        <FormikHumanReadableEnumValue
                            name={`${props.name}.product.group`}
                            enumType="productGroup"
                            fallback="-"
                        />
                    </Text>
                </Box>
            </ProductTd>
            <ProductTd>
                <PackTypeDisplay name={props.name} editable={editable} />
            </ProductTd>
            <ProductTd>
                <UnitSizeDisplay name={props.name} editable={editable} />
            </ProductTd>
            <ProductTd>
                <UnitsDisplay name={props.name} editable={editable} itemDeleted={item.deleted} index={props.index} />
            </ProductTd>
            <ProductTd>
                <Text variant="body" textAlign="right">
                    {item.volume ? formatNumber({ number: item.volume, maximumFractionDigits: 3 }) : '-'}
                </Text>
            </ProductTd>
            <ProductTd>
                <UnitDisplay name={props.name} editable={editable} />
            </ProductTd>

            {showPrices ? (
                <>
                    <ProductTd>
                        <PriceDisplay name={props.name} editable={editable && editPrices} />
                    </ProductTd>

                    <ProductTd>
                        <Text variant="body" textAlign="right">
                            {item.total?.value
                                ? formatMoney({
                                      value: item.total.value,
                                      currency: item.total?.currency || 'USD',
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                  })
                                : '-'}
                        </Text>
                    </ProductTd>
                </>
            ) : null}

            {editable ? (
                <ProductTd>
                    <Flex width="32px" justifyContent="center">
                        <ProductRowAction
                            name={props.name}
                            item={item}
                            isDeleteable={props.isDeleteable}
                            isLastRemainingEntry={props.isLastRemainingEntry}
                            isLastSelectedEntry={props.isLastSelectedEntry}
                            onRowRemoveClicked={props.onRowRemoveClicked}
                        />
                    </Flex>
                </ProductTd>
            ) : null}
        </Tr>
    );
};
