import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

import { get } from '../../common/ui/get';

interface DropdownMenuItemProps {
    $destructive?: boolean;
}

export const DropdownMenuItem = styled(RadixDropdownMenu.Item)<DropdownMenuItemProps>`
    color: ${(props) => (props.$destructive ? get('colors.negative.foreground') : get('colors.accent.foreground'))};
    padding: ${get('space.2')}px ${get('space.5')}px;
    outline: none;
    cursor: pointer;
    border-radius: ${get('radii.1')}px;

    &:hover {
        background: ${(props) =>
            props.$destructive ? get('colors.negative.background') : get('colors.accent.background')};
    }
`;
