import { useNavigate, useSearch } from '@tanstack/react-router';

import { translate } from '../../../common/helpers/translate.helper';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

export const ProductsSearch = () => {
    const search = useSearch({ from: '/_app/_lubes/products' });
    const navigate = useNavigate({ from: '/products' });

    const handleChange = (newValue: string) => {
        navigate({ search: { searchQuery: newValue, page: 0 } });
    };

    return (
        <Box width="300px">
            <DebouncedSearchTextInput
                onChange={handleChange}
                value={search.searchQuery}
                placeholder={translate('productsOverview.searchPlaceholder')}
            />
        </Box>
    );
};
