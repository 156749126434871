import styled from 'styled-components';

import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../helpers/translate.helper';
import { IconArrowDown } from '../../icons/cdl/ArrowDown';
import { IconArrowUp } from '../../icons/cdl/ArrowUp';
import { IconCornerDownLeft } from '../../icons/cdl/CornerDownLeft';
import { Box } from '../../ui/Box';

import { Key } from './Key';

const KeyInstruction = styled(Box).attrs({
    paddingY: '12px',
    paddingX: 5,
    display: 'flex',
    alignItems: 'center',
    gap: 4,
})``;

export const CommandFooter = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
            alignSelf="stretch"
            justifyContent="space-between"
            backgroundColor="background.subtle"
        >
            <Box display="flex">
                <KeyInstruction>
                    <Box display="flex" gap={2}>
                        <Key Icon={IconArrowUp} />
                        <Key Icon={IconArrowDown} />
                    </Box>
                    <Text variant="extraSmall" color="foreground.subtle">
                        {translate('shortcutMenu.toNaviagte')}
                    </Text>
                </KeyInstruction>

                <KeyInstruction>
                    <Key Icon={IconCornerDownLeft} />
                    <Text variant="extraSmall" color="foreground.subtle">
                        {translate('shortcutMenu.toSelect')}
                    </Text>
                </KeyInstruction>
            </Box>

            <KeyInstruction>
                <Key>esc</Key>
                <Text variant="extraSmall" color="foreground.subtle">
                    {translate('shortcutMenu.toClose')}
                </Text>
            </KeyInstruction>
        </Box>
    );
};
