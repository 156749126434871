import { useEffect, useState } from 'react';
import { MultiValue, OnChangeValue, SingleValue } from 'react-select';

import { ComboBox, ComboBoxProps, DefaultOption } from '../../../cdl/ComboBox/ComboBox';
import { getCustomerGroups, GetCustomerGroupsParams } from '../../api/clients/company.api';

export interface CustomerGroupSelectProps<IsMulti extends boolean>
    extends Omit<
        ComboBoxProps<DefaultOption, IsMulti>,
        'onChange' | 'value' | 'loadOptions' | 'options' | 'isLoading'
    > {
    onChange: (option: OnChangeValue<string, IsMulti>) => void;
    value?: IsMulti extends true ? MultiValue<string> : SingleValue<string>;
}

const loadCustomerGroups = async (query: GetCustomerGroupsParams): Promise<DefaultOption[]> => {
    let response = await getCustomerGroups(query);
    return await response.map((customerGroup: any) => ({
        value: customerGroup.id,
        label: customerGroup.name,
    }));
};

const loadCustomerGroupsBySearchQuery = (searchQuery: string) => loadCustomerGroups({ searchQuery });

function loadCustomerGroupsByIds<IsMulti>(ids: IsMulti extends true ? MultiValue<string> : SingleValue<string>) {
    return loadCustomerGroups({ ids: ids as string[] | string | undefined });
}

export function CustomerGroupSelect<IsMulti extends boolean>(props: CustomerGroupSelectProps<IsMulti>) {
    const [selectedValue, setSelectedValue] = useState<MultiValue<DefaultOption> | SingleValue<DefaultOption>>(
        props.isMulti ? [] : null
    );

    useEffect(() => {
        if (props.value?.length) {
            loadCustomerGroupsByIds<IsMulti>(props.value).then((result) => {
                setSelectedValue(props.isMulti ? result : result[0]);
            });
        } else {
            setSelectedValue(props.isMulti ? [] : null);
        }
    }, [props.value, props.isMulti]);

    const onChange: ComboBoxProps<DefaultOption, IsMulti>['onChange'] = (newValue) => {
        setSelectedValue(newValue);
        props.onChange(
            (props.isMulti
                ? (newValue as MultiValue<DefaultOption>).map((it) => it.value)
                : (newValue as SingleValue<DefaultOption>)?.value) as OnChangeValue<string, IsMulti>
        );
    };

    return (
        <ComboBox {...props} value={selectedValue} onChange={onChange} loadOptions={loadCustomerGroupsBySearchQuery} />
    );
}
