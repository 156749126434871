import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { UserType } from '../../../../../types/UserType';
import { TableBuilder } from '../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../../common/Error/Error';
import { formatCompanyName } from '../../../../common/helpers/formatCompanyName.helper';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../common/hooks/useEnums';
import { useRole } from '../../../../common/hooks/useRole';
import { ListingWithMore } from '../../../../common/ListingWithMore/ListingWithMore';
import { LoadingIndicator } from '../../../../common/LoadingIndicator/LoadingIndicator';
import { TableEmptyState } from '../../../../common/TableEmptyState/TableEmptyState';
import { useApiKeyList } from '../hooks/useApiKeyList';

import { ApiKeySwitch } from './ApiKeySwitch';
import { EditApiKeyButton } from './EditApiKeyButton';

interface ApiKeyListProps {
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export const ApiKeysTable = (props: ApiKeyListProps) => {
    const apiKeyListQuery = useApiKeyList(props.groupId, props.userType);
    const role = useRole();
    const { getHumanReadableValue } = useEnums();

    if (apiKeyListQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (apiKeyListQuery.isError) {
        return <Error />;
    }

    if (!apiKeyListQuery.data.length) {
        return <TableEmptyState text={translate('apikey.emptylist')} />;
    }

    return (
        <TableBuilder data={apiKeyListQuery.data}>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.name')}>
                {(row) => row.name}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.key')} width="30%">
                {(row) => row.key}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.datecreated')}>
                {(row) => formatDateTime({ date: row.dateCreated })}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.companies')} width="15%">
                {(row) => {
                    if (!row.companyAccesses?.length) {
                        return '-';
                    }

                    const companies = row.companyAccesses
                        .map((companyAccess) => {
                            return formatCompanyName({ company: companyAccess.company, includeType: role.isAdmin() });
                        })
                        .filter(Boolean) as string[];

                    return <ListingWithMore>{companies}</ListingWithMore>;
                }}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.companyaccesslevel')}>
                {(row) => {
                    const accessLevel = row.companyAccesses?.[0]?.accessLevel;
                    if (!accessLevel) {
                        return '-';
                    }
                    return getHumanReadableValue('accessLevel', accessLevel);
                }}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header={translate('apikey.enableColumnHeader')}>
                {(row) => <ApiKeySwitch apiKey={row} groupId={props.groupId} userType={props.userType} />}
            </TableBuilderColumn>
            <TableBuilderColumn<ApiKeyMessage> header="">
                {(row) => <EditApiKeyButton groupId={props.groupId} apiKey={row} userType={props.userType} />}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
