import { useNavigate, useSearch } from '@tanstack/react-router';

import { ProductContext } from '../../../../types/ProductContext';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { useRole } from '../../../common/hooks/useRole';

export const CustomerFilterActions = () => {
    const role = useRole();
    const search = useSearch({ from: '/_app/_lubes/customers' });
    const navigate = useNavigate({ from: '/customers' });

    if (role.isOneCompanyUser(ProductContext.LUBES)) {
        return null;
    }

    return (
        <OverviewSupplierCompanySelect
            value={search.supplierIds}
            onChange={(supplierIds) => {
                navigate({ search: (previousSearch) => ({ ...previousSearch, supplierIds, page: 0 }) });
            }}
            context={ProductContext.LUBES}
        />
    );
};
