import { useField } from 'formik';

import { CustomerCompanySelect, CustomerCompanySelectProps } from '../CompanySelect/CustomerCompanySelect';

interface FormikCustomerCompanySelectProps<IsMulti extends boolean>
    extends Omit<CustomerCompanySelectProps<IsMulti>, 'onChange' | 'value'> {
    name: string;
}

export function FormikCustomerCompanySelect<IsMulti extends boolean>(props: FormikCustomerCompanySelectProps<IsMulti>) {
    const [field, meta, helpers] = useField(props.name);

    const onChange = (customerIds: IsMulti extends true ? string[] : string) => {
        helpers.setValue(customerIds);
    };

    return (
        <CustomerCompanySelect
            {...props}
            onChange={onChange}
            value={field.value}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : props.caption}
            onBlur={() => {
                helpers.setTouched(true);
            }}
        />
    );
}
