import GoogleMapReact from 'google-map-react';
import { ReactNode } from 'react';

import AppSettings from '../../constants';

import { mapStyles } from './mapStyles';

export interface Position {
    lat: number;
    lng: number;
}

export interface Polyline {
    strokeWeight: number;
    strokeColor: string;
    path: Position[];
}

interface CloselinkMapProps {
    children?: ReactNode[];
    zoomControl?: boolean;
    defaultCenter?: Position;
    defaultZoom?: number;
    places?: Position[];
    polylines?: Polyline[];
    onDragEnd?: () => void;
    onDrag?: () => void;
    fitToBounds?: boolean;
    minZoom?: number;
    onApiIsLoaded?: () => void;
    center?: { lat: number; lng: number };
    zoom?: number;
}

export const CloselinkMap = ({
    places,
    children,
    defaultCenter = { lat: 0, lng: 0 },
    defaultZoom = 0,
    zoomControl = false,
    onDrag,
    onDragEnd,
    polylines,
    fitToBounds = false,
    minZoom = 0,
    onApiIsLoaded,
    center,
    zoom,
}: CloselinkMapProps) => {
    const getMapBounds = (_map: any, maps: any, places?: Position[]) => {
        const bounds = new maps.LatLngBounds();

        if (places && places.length) {
            places.forEach((place) => {
                bounds.extend(new maps.LatLng(place.lat, place.lng));
            });
        } else {
            bounds.extend(new maps.LatLng(defaultCenter.lat, defaultCenter.lng));
        }

        return bounds;
    };

    const bindResizeListener = (map: any, _maps: any, bounds: any) => {
        map.addListener(
            'idle',
            () => {
                window.addEventListener('resize', () => {
                    map.fitBounds(bounds);

                    if (places && places.length === 1) {
                        map.setZoom(10);
                    }

                    if (!places || !places.length) {
                        map.setZoom(defaultZoom);
                    }
                });
            },
            { once: true }
        );
    };

    const apiIsLoaded = (map: any, maps: any, places?: Position[]) => {
        if (onApiIsLoaded) {
            onApiIsLoaded();
        }

        polylines?.forEach((polyline) => {
            const mapPolyline = new maps.Polyline({
                path: polyline.path,
                strokeColor: polyline.strokeColor,
                strokeWeight: polyline.strokeWeight,
            });

            mapPolyline.setMap(map);
        });

        if (!fitToBounds) {
            return;
        }

        const bounds = getMapBounds(map, maps, places);
        map.setCenter(bounds.getCenter());
        map.fitBounds(bounds);

        if (places && places.length === 1) {
            map.setZoom(10);
        }

        if (!places || !places.length) {
            map.setZoom(defaultZoom);
        }

        bindResizeListener(map, maps, bounds);
    };

    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: AppSettings.googleApiURLKey,
                libraries: ['places'],
                id: 'closelink',
            }}
            // Defaults are required as fallback
            defaultZoom={defaultZoom}
            defaultCenter={defaultCenter}
            hoverDistance={12}
            // To let the map resize responsive, we access the original api here
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
            options={() => {
                return {
                    scrollwheel: true,
                    disableDefaultUI: true,
                    zoomControl: zoomControl,
                    mapTypeId: 'roadmap',
                    styles: mapStyles,
                    minZoom: minZoom,
                };
            }}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            center={center}
            zoom={zoom}
        >
            {children}
        </GoogleMapReact>
    );
};
