import { Formik, FormikErrors } from 'formik';

import { UserInviteMessage } from '../../../../types/UserInviteMessage';
import { UserRole } from '../../../../types/UserRole';
import { UserType } from '../../../../types/UserType';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { UserServerError } from '../../detail/hooks/useUserInvite';
import { companyAccessLevel, groupAccessLevel } from '../utils/UserAccessLevels';

import { InviteUserBaseForm } from './InviteUserBaseForm';

export interface InviteUserFormProps {
    onSubmit: (userInviteMessage: UserInviteMessage) => void;
    error?: UserServerError;
}

export interface FormikInviteUserState {
    groupId: string;
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    userType?: UserType;
    role?: UserRole;
    companyIds: string[];
}

export const InviteUserForm = ({ onSubmit, error }: InviteUserFormProps) => {
    const role = useRole();

    const initialFormikState: FormikInviteUserState = {
        role: undefined,
        email: '',
        groupId: role.isAdmin() ? '' : role.user.groupId,
        userType: role.isAdmin() ? undefined : role.user.userType,
        firstname: '',
        lastname: '',
        phone: '',
        companyIds: [],
    };

    const handleSubmit = (values: FormikInviteUserState) => {
        const userMessage: UserInviteMessage = {
            accessLevel: groupAccessLevel[values.role!!],
            email: values.email,
            groupId: values.groupId,
            userType: values.userType!,
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.phone.length ? values.phone : undefined,
            companyAccesses: values.companyIds.length
                ? values.companyIds.map((companyId) => {
                      return {
                          companyId: companyId,
                          accessLevel: companyAccessLevel[values.role!!],
                      };
                  })
                : undefined,
        };
        onSubmit(userMessage);
    };

    const validate = (values: FormikInviteUserState): FormikErrors<FormikInviteUserState> => {
        const errors: FormikErrors<FormikInviteUserState> = {};

        if (error && error.email === values.email && error.errorCode === 'MAIL_NOT_UNIQUE') {
            errors.email = translate('user.error.emailNotUnique');
        }

        if (error && error.email === values.email && error.errorCode === 'INVALID_EMAIL') {
            errors.email = translate('user.error.emailInvalid');
        }

        if (!values.email || values.email.length === 0) {
            errors.email = translate('user.error.requiredEmail');
        }

        if (role.isAdmin()) {
            if (!values.firstname || values.firstname.length === 0) {
                errors.firstname = translate('user.error.requiredFirstName');
            }

            if (!values.lastname || values.lastname.length === 0) {
                errors.lastname = translate('user.error.requiredLastName');
            }

            if (!values.userType || values.userType.length === 0) {
                errors.userType = translate('user.error.requiredUserType');
            }

            if (!values.groupId || values.groupId.length === 0) {
                errors.groupId = translate('user.error.requiredGroupId');
            }
        }

        if (!values.role || values.role.length === 0) {
            errors.role = translate('user.error.requiredUserRole');
        }

        if (values.role !== UserRole.GROUP_ADMIN && !values.companyIds?.length) {
            errors.companyIds = translate('user.error.requiredCompanyIds');
        }
        return errors;
    };

    return (
        <Formik initialValues={initialFormikState} onSubmit={handleSubmit} validate={validate}>
            <InviteUserBaseForm error={error} />
        </Formik>
    );
};
