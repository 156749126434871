import { useNavigate, useParams } from '@tanstack/react-router';

import { IconButton } from '../../../cdl/IconButton/IconButton';
import { LogoHeader } from '../../../common/headers/LogoHeader';
import { translate } from '../../../common/helpers/translate.helper';
import { IconX } from '../../../common/icons/cdl/X';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../../common/Overlay/Overlay';
import { Box } from '../../../common/ui/Box';
import { OfferState } from '../../model/OfferState';
import { useCreateQuoteDocumentTitle } from '../hooks/useCreateQuoteDocumentTitle';

import { QuoteFormWrapper } from './components/QuoteFormWrapper';
import { useQuotationState } from './hooks/useQuotationState';

export const FuelCreateQuoteFlow = () => {
    return createOverlay(<FuelCreateQuote />);
};

const FuelCreateQuote = () => {
    const { id } = useParams({ from: '/_app/fuel/offer/$id_/quote' });
    const navigate = useNavigate({ from: '/fuel/offer/$id/quote' });
    const quotationState = useQuotationState(id);

    useCreateQuoteDocumentTitle(quotationState.data);

    const handleCloseClick = () => {
        navigate({ to: '/fuel/offer/$id', params: { id } });
    };

    if (quotationState.isPending) {
        return (
            <Box minHeight="100%" backgroundColor="background.subtle">
                <LogoHeader rightElement={<IconButton Icon={IconX} onClick={handleCloseClick} />} />
                <LoadingIndicator variant="page" />
            </Box>
        );
    }

    const title =
        quotationState.data?.state === OfferState.QUOTED
            ? translate('offer.quotationProcess.fuel.update')
            : translate('offer.quotationProcess.fuel.headerTitle');

    return (
        <Box paddingBottom={11} minHeight="100%" backgroundColor="background.subtle">
            <LogoHeader title={title} rightElement={<IconButton Icon={IconX} onClick={handleCloseClick} />} />
            <QuoteFormWrapper data={quotationState.data} />
        </Box>
    );
};
