import { useContext } from 'react';

import { UserlikeMessengerContext } from '../../userlike/UserlikeMessengerContext';

import { useRole } from './useRole';

export function useStartUserlikeChat() {
    const role = useRole();

    const messenger = useContext(UserlikeMessengerContext);

    return () => {
        try {
            if (!messenger) {
                window.open('mailto:hello@closelink.com');
                return;
            }

            if (role) {
                messenger.setContactInfo({
                    name: role.user.firstname + ' ' + role.user.lastname,
                    email: role.user.email,
                    company: role.group.name,
                });
            }

            messenger.maximize();
        } catch {
            window.open('mailto:hello@closelink.com');
        }
    };
}
