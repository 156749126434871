import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import FileDownload from 'js-file-download';
import { useState } from 'react';

import { VesselMessage } from '../../../../types/VesselMessage';
import { Button } from '../../../cdl/Button/Button';
import { DropdownMenu } from '../../../cdl/DropdownMenu/DropdownMenu';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { updateVessel } from '../../../common/api/clients/vessel.api';
import {
    downloadVesselRequisition,
    sendVesselRequisition as apiSendVesselRequisition,
} from '../../../common/api/clients/vesselRequisition.api';
import { queries } from '../../../common/api/queryKeys/queries';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { extractFileName } from '../../../common/helpers/extractFileName.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconChevronDown } from '../../../common/icons/cdl/ChevronDown';
import { IconPlus } from '../../../common/icons/cdl/Plus';

import { SendToVesselPopup } from './SendToVesselPopup';

interface VesselActionsProps {
    vessel: VesselMessage;
}

export const VesselActions = ({ vessel }: VesselActionsProps) => {
    const queryClient = useQueryClient();
    const role = useRole();
    const navigate = useNavigate();
    const { addToast, addLoadingToast } = useToasts();

    const [showPopup, setShowPopup] = useState(false);

    const { mutateAsync: executeVesselRequisitionDownload } = useMutation({
        mutationFn: downloadVesselRequisition,
    });
    const { mutateAsync: executeSendVesselRequisition, isPending: sendVesselRequisitionPending } = useMutation({
        mutationFn: apiSendVesselRequisition,
    });
    const { mutateAsync: executeUpdateVessel } = useMutation({
        mutationFn: updateVessel,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queries.vessels.detail(vessel.id).queryKey });
        },
    });

    const onPopupDismiss = () => {
        setShowPopup(false);
    };

    const onPopupSubmit = async ({ emailAddress }: { emailAddress: string }) => {
        setShowPopup(false);

        await executeUpdateVessel({
            vesselId: vessel.id,
            vessel: {
                ...vessel,
                email: emailAddress,
            },
        });

        await sendVesselRequisition();
    };

    const sendVesselRequisition = async () => {
        await executeSendVesselRequisition(vessel.id);

        addToast(translate('vessel.vesselRequisitionSentToast'));
    };

    const onClickSendButton = async () => {
        if (sendVesselRequisitionPending) {
            return;
        }

        if (!vessel.email) {
            setShowPopup(true);
        } else {
            await sendVesselRequisition();
        }
    };

    const onClickDownloadButton = async () => {
        return addLoadingToast(
            () =>
                executeVesselRequisitionDownload(vessel.id, {
                    onSuccess: (response) =>
                        FileDownload(
                            response.data,
                            extractFileName(response.headers['content-disposition'] || '', 'VesselRequisition.xlsx')
                        ),
                }),
            translate('vessel.requisitionForm.downloading'),
            translate('vessel.requisitionForm.downloadSuccess'),
            translate('vessel.requisitionForm.downloadError')
        );
    };

    const onClickNewEnquiryButton = () => {
        navigate({
            to: '/orders/new',
            state: {
                vesselId: vessel.id,
                customerId: vessel.customerId,
            },
        });
    };

    const requisitionOptions = [
        {
            label: translate('vessel.requisitionForm.downloadButton'),
            onSelect: () => onClickDownloadButton(),
        },
        {
            label: translate('vessel.requisitionForm.sendToVesselButton'),
            onSelect: () => onClickSendButton(),
        },
    ];

    return (
        <ButtonGroup>
            {!role.usesCloselinkLite() ? (
                <DropdownMenu
                    options={requisitionOptions}
                    align="end"
                    trigger={
                        <Button trailingVisual={<IconChevronDown height={16} width={16} />}>
                            {translate('vessel.requisitionForm.label')}
                        </Button>
                    }
                />
            ) : null}

            {role.isCustomer() && vessel.active ? (
                <Button
                    emphasis="high"
                    leadingVisual={<IconPlus height={16} width={16} />}
                    onClick={onClickNewEnquiryButton}
                >
                    {translate('vessel.newEnquiry')}
                </Button>
            ) : null}

            {showPopup && <SendToVesselPopup open={showPopup} onDismiss={onPopupDismiss} onSubmit={onPopupSubmit} />}
        </ButtonGroup>
    );
};
