import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

import { Box } from '../../ui/Box';

import { CommandItem } from './CommandItem';

const CommandLoadingItem = styled(CommandItem)`
    cursor: default;

    &[data-selected='true'] {
        background: none;
    }
`;

interface CommandSkeletonProps {
    width?: string;
}

export const CommandSkeleton = ({ width = '300px' }: CommandSkeletonProps) => {
    return (
        <CommandLoadingItem>
            <Box height="24px" display="flex" alignItems="center">
                <Box height="16px">
                    <Skeleton height="16px" width={width} style={{ display: 'block' }} />
                </Box>
            </Box>
        </CommandLoadingItem>
    );
};
