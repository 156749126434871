import { FieldArray, useField } from 'formik';

import { Button } from '../../../../cdl/Button/Button';
import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { TableBuilder } from '../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../cdl/TableBuilder/TableBuilderColumn';
import { FormikFormattedNumberInput } from '../../../../common/FormattedNumberInput/FormikFormattedNumberInput';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconPlus } from '../../../../common/icons/cdl/Plus';
import { IconTrash } from '../../../../common/icons/cdl/Trash';
import { Box } from '../../../../common/ui/Box';
import { UIVesselTankMessage } from '../EditTanksModal';

import { FormikProductCategorySelect } from './FormikProductCategorySelect';
import { InternalFormikProductSelect } from './InternalFormikProductSelect';

export const FormikTanksTable = () => {
    const [field] = useField<UIVesselTankMessage[]>('vesselTanks');

    return (
        <FieldArray
            name="vesselTanks"
            render={(arrayHelpers) => (
                <div>
                    <TableBuilder data={field.value} alternateRowColors={false}>
                        <TableBuilderColumn header={translate('vessel.editTanksPopup.table.headers.name')} width="18%">
                            {(_, index: number) => <FormikInput name={`vesselTanks.${index}.name`} />}
                        </TableBuilderColumn>
                        <TableBuilderColumn
                            header={translate('vessel.editTanksPopup.table.headers.category')}
                            width="18%"
                        >
                            {(_, index: number) => (
                                <FormikProductCategorySelect name={`vesselTanks.${index}.category`} />
                            )}
                        </TableBuilderColumn>
                        <TableBuilderColumn
                            header={translate('vessel.editTanksPopup.table.headers.defaultProduct')}
                            width="22%"
                        >
                            {(_, index: number) => (
                                <InternalFormikProductSelect name={`vesselTanks.${index}.defaultProduct`} />
                            )}
                        </TableBuilderColumn>
                        <TableBuilderColumn header={translate('vessel.editTanksPopup.table.headers.maxVolume')}>
                            {(_, index: number) => (
                                <FormikFormattedNumberInput
                                    name={`vesselTanks.${index}.maxVolume`}
                                    trailingVisual="L"
                                />
                            )}
                        </TableBuilderColumn>
                        <TableBuilderColumn header={translate('vessel.editTanksPopup.table.headers.warningLimit')}>
                            {(_, index: number) => (
                                <FormikFormattedNumberInput
                                    name={`vesselTanks.${index}.threshold`}
                                    trailingVisual="L"
                                />
                            )}
                        </TableBuilderColumn>
                        <TableBuilderColumn header={translate('vessel.editTanksPopup.table.headers.safetyReserve')}>
                            {(_, index: number) => (
                                <FormikFormattedNumberInput
                                    name={`vesselTanks.${index}.safetyReserve`}
                                    trailingVisual="L"
                                />
                            )}
                        </TableBuilderColumn>
                        <TableBuilderColumn header="">
                            {(_, index: number) => (
                                <IconButton
                                    tooltipLabel={translate('vessel.editTanksPopup.table.removeTank')}
                                    type="button"
                                    Icon={IconTrash}
                                    onClick={() => arrayHelpers.remove(index)}
                                />
                            )}
                        </TableBuilderColumn>
                    </TableBuilder>
                    <Box marginTop={5}>
                        <Button
                            type="button"
                            onClick={() => arrayHelpers.push({})}
                            leadingVisual={<IconPlus width={16} height={16} />}
                        >
                            {translate('vessel.editTanksPopup.table.addTank')}
                        </Button>
                    </Box>
                </div>
            )}
        />
    );
};
