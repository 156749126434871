import { useNavigate, useSearch } from '@tanstack/react-router';

import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

export const VesselOverviewTableControls = () => {
    const role = useRole();
    const { context, isFuel } = useProductContext();

    const search = useSearch({ from: isFuel ? '/_app/fuel/vessels' : '/_app/vessels' });
    const navigate = useNavigate({ from: isFuel ? '/fuel/vessels' : '/vessels' });

    return (
        <Box display="flex" gap={4}>
            <Box minWidth="300px" maxWidth="80%">
                <DebouncedSearchTextInput
                    onChange={(searchQuery) => {
                        navigate({ search: (previousSearch) => ({ ...previousSearch, searchQuery, page: 0 }) });
                    }}
                    value={search.searchQuery}
                    placeholder={translate('vessel.searchInputPlaceholder')}
                />
            </Box>

            {!role.isOneCompanyUser(context) ? (
                <OverviewCustomerCompanySelect
                    onChange={(customerIds) => {
                        navigate({ search: (previousSearch) => ({ ...previousSearch, customerIds, page: 0 }) });
                    }}
                    value={search.customerIds}
                    context={!role.isAdmin() ? context : undefined}
                />
            ) : null}
        </Box>
    );
};
