import { useParams, useSearch } from '@tanstack/react-router';

import { useProductContext } from '../../../common/hooks/useProductContext';
import { useVesselDetails } from '../../../common/hooks/useVesselDetails';
import { VesselOrderTable } from '../../VesselDetailPage/components/VesselOrderTable';
import { FuelScheduleSection } from '../../VesselDetailPage/ScheduleSection/FuelScheduleSection';
import { LubesScheduleSection } from '../../VesselDetailPage/ScheduleSection/LubesScheduleSection';

import { FuelVesselDetailPageOverviewContent } from './FuelVesselDetailPageOverviewContent';
import { LubesVesselDetailPageOverviewContent } from './LubesVesselDetailPageOverviewContent';

const VesselDetailPageTabs = {
    OVERVIEW: 'overview',
    SCHEDULE: 'schedule',
    ORDERS: 'orders',
} as const;

// eslint-disable-next-line no-redeclare
export type VesselDetailPageTabs = (typeof VesselDetailPageTabs)[keyof typeof VesselDetailPageTabs];
interface VesselDetailPageSearch {
    tab: VesselDetailPageTabs;
}

export const VesselDetailPageContent = () => {
    const { isFuel } = useProductContext();

    const routePath = isFuel ? '/_app/fuel/vessel/$id' : '/_app/_lubes/vessel/$id';

    const { id } = useParams({ from: routePath });
    const search: VesselDetailPageSearch = useSearch({ from: routePath });

    const { data: vessel } = useVesselDetails(id);

    switch (search.tab) {
        case VesselDetailPageTabs.OVERVIEW:
            if (isFuel) {
                return <FuelVesselDetailPageOverviewContent />;
            }
            return <LubesVesselDetailPageOverviewContent />;
        case VesselDetailPageTabs.SCHEDULE:
            if (isFuel) {
                return <FuelScheduleSection vessel={vessel} />;
            }
            return <LubesScheduleSection vessel={vessel} />;
        case VesselDetailPageTabs.ORDERS:
            return <VesselOrderTable vessel={vessel} />;
    }
};
