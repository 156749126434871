import { Form, Formik, FormikErrors } from 'formik';

import { AssignmentMessage } from '../../../../types/AssignmentMessage';
import { Button } from '../../../cdl/Button/Button';
import { Callout } from '../../../cdl/Callout/Callout';
import { Checkbox } from '../../../cdl/Checkbox/Checkbox';
import { InputLabelText } from '../../../cdl/Input/components/InputLabelText';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { FuelUpdateOrderRequest, LubesUpdateOrderRequest } from '../../../common/api/clients/order.api';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Box } from '../../../common/ui/Box';
import { mapToFuelOrderRequestObject } from '../../fuel/mapToFuelOrderRequestObject';
import { useSpotAvailableAtPort } from '../../lubes/create-enquiry/hooks/useSpotAvailableAtPort';
import { toLubesUpdateOrderRequest } from '../../model/order.mapper';
import { OrderModel } from '../../model/OrderModel';
import { useOrderUpdate } from '../lubes/hooks/useOrderUpdate';

interface RequestSuppliersModalProps {
    onClose: (hasError?: boolean) => void;
    order: OrderModel;
    assignments: AssignmentMessage[];
}

interface ReceiverOption {
    label: string;
    value: string;
    selected: boolean;
}

interface RequestSuppliersFormState {
    receiverOptions: ReceiverOption[];
}

const SPOT_OPTION_VALUE = 'spot';

export const RequestSuppliersModal = (props: RequestSuppliersModalProps) => {
    const role = useRole();
    const { isLubes } = useProductContext();

    const spotAvailableAtPortQuery = useSpotAvailableAtPort(props.order.port?.country?.code, props.order.testOrder);
    const isSpotAllowed = role.isSpotAllowed(props.order.customerId);
    const updateOrderMutation = useOrderUpdate();

    const optionAlreadySelected = (optionValue: string) => {
        if (optionValue === SPOT_OPTION_VALUE) {
            return props.order.spot;
        }

        return props.order.receiverSupplierIds?.includes(optionValue);
    };

    const validate = (values: RequestSuppliersFormState) => {
        const errors: FormikErrors<RequestSuppliersFormState> = {};

        const newEntries = values.receiverOptions
            .filter((option) => !optionAlreadySelected(option.value))
            .filter((option) => option.selected);

        if (!newEntries.length) {
            errors.receiverOptions = translate('order.detail.requestSuppliers.validationError');
        }

        return errors;
    };

    const onSubmit = (values: RequestSuppliersFormState) => {
        const supplierSelectionValues = values.receiverOptions
            ?.filter((option) => option.selected)
            .map((option) => option.value);

        const newSupplierIds = supplierSelectionValues?.filter((value) => value !== SPOT_OPTION_VALUE);

        const newOrder: LubesUpdateOrderRequest | FuelUpdateOrderRequest = {
            ...(isLubes
                ? toLubesUpdateOrderRequest(props.order)
                : mapToFuelOrderRequestObject(props.order, props.order.state)),
            receiverSupplierIds: newSupplierIds,
            spot: props.order.spot ? props.order.spot : supplierSelectionValues.includes(SPOT_OPTION_VALUE),
            cancelReason: null,
        };

        updateOrderMutation
            .mutateAsync({
                orderId: props.order.id,
                order: newOrder,
            })
            .then(() => {
                props.onClose();
            })
            .catch(() => {
                props.onClose(true);
            });
    };

    const createReceiverOptions = (): ReceiverOption[] => {
        const options: any[] = [];
        if (spotAvailableAtPortQuery.data?.available === true && isSpotAllowed) {
            options.push({
                label: translate('order.detail.requestSuppliers.spot'),
                value: SPOT_OPTION_VALUE,
                selected: props.order.spot ?? false,
            });
        }

        props.assignments.forEach((supplierData: AssignmentMessage) => {
            if (supplierData.supplier) {
                options.push({
                    label: supplierData.supplier.name,
                    value: supplierData.supplier.id,
                    selected: optionAlreadySelected(supplierData.supplier.id),
                });
            }
        });

        return options;
    };

    if (spotAvailableAtPortQuery.isLoading) {
        return null;
    }

    const receiverOptions = createReceiverOptions();

    return (
        <Modal isOpen={true} onDismiss={props.onClose} dismissible={false}>
            <Formik
                initialValues={{
                    receiverOptions,
                }}
                onSubmit={onSubmit}
                validate={validate}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {(formik) => (
                    <Form>
                        <Box padding={6} display="grid" rowGap={5}>
                            <Text variant="title">{translate('order.detail.requestSuppliers.title')}</Text>

                            <Text variant="small">{translate('order.detail.requestSuppliers.description')}</Text>

                            {formik.errors.receiverOptions ? (
                                <Callout variant="accent" heading={formik.errors.receiverOptions} />
                            ) : null}

                            <Box display="grid" rowGap={3}>
                                <InputLabelText>
                                    {translate('order.detail.requestSuppliers.checkboxLabel')}
                                </InputLabelText>
                                {formik.values.receiverOptions.map((receiverOption) => (
                                    <Checkbox
                                        key={receiverOption.value}
                                        label={receiverOption.label}
                                        checked={receiverOption.selected}
                                        onChange={() => {
                                            formik.setFieldValue('receiverOptions', [
                                                ...formik.values.receiverOptions.map((option) =>
                                                    option.value === receiverOption.value
                                                        ? { ...option, selected: !option.selected }
                                                        : option
                                                ),
                                            ]);
                                        }}
                                        disabled={optionAlreadySelected(receiverOption.value)}
                                    />
                                ))}
                            </Box>

                            <Box display="flex" justifyContent="end" gap={4}>
                                <Button type="button" onClick={() => props.onClose()}>
                                    {translate('order.detail.requestSuppliers.cancel')}
                                </Button>
                                <Button type="submit" emphasis="high" isLoading={updateOrderMutation.isPending}>
                                    {translate('order.detail.requestSuppliers.submit')}
                                </Button>
                            </Box>
                        </Box>

                        <FormikDebug />
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};
