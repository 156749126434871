import development from './configuration/constants-development';
import production from './configuration/constants-production';
import staging from './configuration/constants-staging';
import storybook from './configuration/constants-storybook';
import test from './configuration/constants-test';

function getAppSettings(): IAppSettings {
    if (import.meta.env.STORYBOOK) {
        return storybook;
    }

    // NOTE: APP_SETTINGS will be replaced by webpack with one of the string values
    // and then remove all the other AppSettings during optimization.

    if (import.meta.env.MODE === 'development') {
        return development;
    }

    if (import.meta.env.MODE === 'production') {
        return production;
    }

    if (import.meta.env.MODE === 'staging') {
        return staging;
    }

    if (import.meta.env.MODE === 'test') {
        return test;
    }

    return development;
}

const AppSettings = getAppSettings();

export default AppSettings;

interface IAppSettings {
    VERSION: string;
    ENV: string;
    SENTRY_DSN: string;
    googleAnalyticsId: string;
    googleApiURLKey: string;
    magicBellApiKey: string;
    routes: {
        api: string;
        gateway: string;
    };
    launchDarklyClientSideId: string;
    closelinkChartsUrl: string;
    userlikeWidgetKey: string;
}
