import { useFormikContext } from 'formik';

import { ProductContext } from '../../../../../types/ProductContext';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { ReadOnlyField } from '../../../../cdl/ReadOnlyField/ReadOnlyField';
import { FormikEnumOptionSelect } from '../../../../common/form-elements/formik/FormikEnumOptionSelect';
import { FormikTimestampDatePicker } from '../../../../common/form-elements/formik/FormikTimestampDatePicker';
import { FormikFormattedNumberInput } from '../../../../common/FormattedNumberInput/FormikFormattedNumberInput';
import { formatCompanyName } from '../../../../common/helpers/formatCompanyName.helper';
import { formatPort } from '../../../../common/helpers/formatPort.helper';
import { formatVessel } from '../../../../common/helpers/formatVessel.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../model/OfferModel';

export const FormikGeneralInformation = () => {
    const { values: offer, setFieldValue } = useFormikContext<OfferModel>();
    const role = useRole();

    return (
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={5}>
            <ReadOnlyField
                label={translate('offer.quotationProcess.labels.customer')}
                value={formatCompanyName({ company: offer.customer, includeType: role.isAdmin() }) || ''}
            />

            <ReadOnlyField
                label={translate('offer.quotationProcess.labels.vessel')}
                value={formatVessel({ vessel: offer.vessel }) || ''}
            />

            <ReadOnlyField
                label={translate('offer.quotationProcess.labels.port')}
                value={formatPort({ port: offer.port }) || ''}
            />

            <FormikTimestampDatePicker name="dateDelivery" label={translate('offer.edit.lubes.deliveryDate')} />

            {offer.isSpot() ? (
                <Box display="grid" gridTemplateColumns="3fr 1fr" gap={4}>
                    <FormikEnumOptionSelect
                        label={translate('offer.edit.lubes.paymentTerms')}
                        name="paymentTermReference"
                        enumType="paymentTermReference"
                        onOptionChange={(option) => {
                            if (option === 'PREPAYMENT') {
                                setFieldValue('paymentTermReferenceDays', undefined);
                            }
                        }}
                    />
                    <FormikFormattedNumberInput
                        name="paymentTermReferenceDays"
                        label={translate('offer.edit.lubes.paymentTermsDays')}
                        allowNegativeValue={false}
                        allowDecimals={false}
                        disabled={offer.paymentTermReference === 'PREPAYMENT'}
                    />
                </Box>
            ) : null}

            <FormikFormattedNumberInput
                name="noticeDays"
                label={translate('offer.edit.lubes.noticeDays')}
                allowNegativeValue={false}
                allowDecimals={false}
            />
            <FormikEnumOptionSelect
                name="supplyMode"
                enumType="supplyMode"
                label={translate('offer.edit.lubes.supplyMode')}
                context={ProductContext.LUBES}
            />
            <FormikInput name="vendorReference" label={translate('offer.edit.lubes.vendorReference')} />
        </Box>
    );
};
