import { useQuery } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { Command, useCommandState } from 'cmdk';

import { ProductContext } from '../../../../types/ProductContext';
import { VesselMessage } from '../../../../types/VesselMessage';
import { getPaginatedVessels } from '../../api/clients/gateway.api';
import { formatVessel } from '../../helpers/formatVessel.helper';
import { useDebounce } from '../../hooks/useDebounce';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandEmptyState } from './CommandEmptyState';
import { CommandErrorState } from './CommandErrorState';
import { CommandItem } from './CommandItem';
import { CommandSkeletonList } from './CommandSkeletonList';

export const VesselSearch = () => {
    const search = useCommandState((state) => state.search);
    const { setOpen } = useShortcutMenu();
    const debouncedSearch = useDebounce(search, 300);

    const query = useQuery({
        queryKey: ['vessels', debouncedSearch],
        queryFn: () =>
            getPaginatedVessels({
                searchQuery: debouncedSearch,
                page: 0,
                active: true,
            }),
    });

    const navigate = useNavigate();

    const onVesselSelect = (vessel: VesselMessage) => {
        navigate({
            to: vessel.customer.type === ProductContext.FUEL ? '/fuel/vessel/$id' : '/vessel/$id',
            params: { id: vessel.id },
        });
        setOpen(false);
    };

    if (query.isPending) {
        return <CommandSkeletonList />;
    }

    if (query.isError) {
        return <CommandErrorState />;
    }

    if (!query.data || query.data.content.length === 0) {
        return <CommandEmptyState />;
    }

    return (
        <Command.List>
            {query.data.content.map((vessel: VesselMessage) => (
                <CommandItem key={vessel.id} value={vessel.id} onSelect={() => onVesselSelect(vessel)}>
                    {formatVessel({ vessel })}
                </CommandItem>
            ))}
        </Command.List>
    );
};
