import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from '@tanstack/react-router';
import { Form, Formik, setNestedObjectValues } from 'formik';
import { useState } from 'react';

import { SamplekitMessage } from '../../../../types/SamplekitMessage';
import { SurchargeMessage } from '../../../../types/SurchargeMessage';
import { Button } from '../../../cdl/Button/Button';
import { Text } from '../../../cdl/Text/Text';
import { FormikTextArea } from '../../../cdl/TextArea/FormikTextArea';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { queryKeys } from '../../../common/api/queryKeys';
import { Divider } from '../../../common/Divider/Divider';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikLubesProductTable } from '../../../common/FormikProductTable/lubes/FormikLubesProductTable';
import { translate } from '../../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { ItemModel } from '../../../common/models/ItemModel';
import { createOverlay } from '../../../common/Overlay/Overlay';
import { OverlayHeader } from '../../../common/Overlay/OverlayHeader';
import { Box } from '../../../common/ui/Box';
import { useOfferDetails } from '../../detail/common/hooks/useOfferDetails';
import { useOfferUpdate } from '../../detail/common/hooks/useOfferUpdate';
import { useValidateLubesOfferForm } from '../../hooks/useValidateLubesOfferForm';
import { OfferModel } from '../../model/OfferModel';
import { useMessageCreate } from '../../OfferChat/useMessageCreate';
import { useCreateQuoteDocumentTitle } from '../hooks/useCreateQuoteDocumentTitle';

import { FormikGeneralInformation } from './components/FormikGeneralInformation';
import { FormikSpotConfirmTermsModal } from './components/FormikSpotConfirmTermsModal';
import { LubesQuoteAttachments } from './components/LubesQuoteAttachments';

export const LubesCreateQuotePage = () => {
    return createOverlay(<LubesCreateQuote />);
};

const LubesCreateQuote = () => {
    const { id } = useParams({ from: '/_app/_lubes/offer/$id_/quote' });
    const navigate = useNavigate({ from: '/offer/$id/quote' });

    const offerQuery = useOfferDetails(id);
    const offerUpdateMutation = useOfferUpdate();
    const queryClient = useQueryClient();
    const [confirmSpotTermsModalOpen, setConfirmSpotTermsModalOpen] = useState(false);
    const { addToast, addErrorToast } = useToasts();
    const messageMutation = useMessageCreate();
    const formikValidate = useValidateLubesOfferForm();

    useCreateQuoteDocumentTitle(offerQuery.data);

    const handleCancel = () => {
        navigate({ to: '/offer/$id', params: { id } });
    };

    if (offerQuery.isPending) {
        return (
            <OverlayHeader onClose={handleCancel}>
                <LoadingIndicator variant="page" />
            </OverlayHeader>
        );
    }

    if (offerQuery.isError || !offerQuery.data) {
        return (
            <OverlayHeader title={translate('offer.quotationProcess.header.createQuote')} onClose={handleCancel}>
                <CenteredPageError />
            </OverlayHeader>
        );
    }

    const offer = offerQuery.data;

    const handleSubmit = async (values: OfferModel & { comment?: string }) => {
        const offerRequest = {
            ...values,
            state: 'QUOTED',
            dateDelivery: values.dateDelivery || undefined,
            items: values.items.map((item: ItemModel) => ({
                ...item,
                productId: item.productId || undefined,
                price:
                    item.price?.value !== undefined && item.price?.value?.toString() !== ''
                        ? {
                              value: item.price.value,
                              currency: item.price.currency || 'USD',
                          }
                        : undefined,
            })),
            surcharges: values.surcharges.map((surcharge: SurchargeMessage) => ({
                ...surcharge,
                value:
                    surcharge.value?.value !== undefined && surcharge.value?.value?.toString() !== ''
                        ? { value: Number(surcharge.value.value), currency: 'USD' }
                        : undefined,
            })),
            samplekits: values.samplekits?.map((samplekit: SamplekitMessage) => ({
                ...samplekit,
                value:
                    samplekit.value?.value !== undefined && samplekit.value?.value?.toString() !== ''
                        ? {
                              value: samplekit.value.value,
                              currency: samplekit.value.currency || 'USD',
                          }
                        : undefined,
            })),
        };

        try {
            await offerUpdateMutation.mutateAsync({ offer: offerRequest, offerId: values.id });

            if (values.comment) {
                await messageMutation.mutateAsync({ offerId: offer.id, message: values.comment });
            }

            addToast(translate('offer.quotationProcess.sentSuccessfully'));

            navigate({ to: '/offer/$id', params: { id: offer.id } });
        } catch {
            addErrorToast(translate('offer.quotationProcess.failedToSend'));
        }
    };

    const handleInvalidateCache = async () => {
        await queryClient.refetchQueries({ queryKey: queryKeys.offer(offer.id) });
    };

    return (
        <Box paddingBottom={8}>
            <Formik initialValues={offer} validateOnMount onSubmit={handleSubmit} validate={formikValidate}>
                {(formikProps) => (
                    <Form>
                        <OverlayHeader
                            title={
                                offer.isSpot()
                                    ? translate('offer.quotationProcess.header.createOffer')
                                    : translate('offer.quotationProcess.header.createQuote')
                            }
                            onClose={handleCancel}
                            trailingVisual={
                                <Button
                                    type="button"
                                    onClick={async () => {
                                        if (formikProps.values.isSpot()) {
                                            await formikProps.validateForm();
                                            formikProps.setTouched(setNestedObjectValues(formikProps.errors, true));
                                            formikProps.isValid && setConfirmSpotTermsModalOpen(true);
                                        } else {
                                            await formikProps.submitForm();
                                            formikProps.setTouched(setNestedObjectValues(formikProps.errors, true));
                                        }
                                    }}
                                    emphasis="high"
                                    isLoading={formikProps.values.isSpot() ? false : formikProps.isSubmitting}
                                >
                                    {formikProps.values.isSpot()
                                        ? translate('offer.quotationProcess.navigationButtons.spot.send')
                                        : translate('offer.quotationProcess.navigationButtons.contracted.send')}
                                </Button>
                            }
                        >
                            <Box display="grid" margin={7} gap={9}>
                                <Box>
                                    <Text variant="title">
                                        {translate('offer.quotationProcess.coreData.banner.headline')}
                                    </Text>
                                    <Text variant="body" color="foreground.muted" as="p">
                                        {translate('offer.quotationProcess.coreData.banner.subHeadline')}
                                    </Text>

                                    <Divider marginTop={5} marginBottom={7} />

                                    <FormikGeneralInformation />
                                </Box>

                                <Box>
                                    <Text variant="title">
                                        {translate('offer.quotationProcess.products.banner.headline')}
                                    </Text>
                                    <Text variant="body" color="foreground.muted" as="p">
                                        {translate('offer.quotationProcess.products.banner.subHeadline')}
                                    </Text>

                                    <Divider marginTop={5} marginBottom={7} />

                                    <FormikLubesProductTable
                                        config={{
                                            initialItems: offer.items,
                                            showPrices: true,
                                            editable: true,
                                            editSurcharges: true,
                                            editPrices: true,
                                        }}
                                    />
                                </Box>
                                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={5} alignContent="top">
                                    <FormikTextArea
                                        name="comment"
                                        rows={5}
                                        label={translate('offer.quotationProcess.messageToCustomer')}
                                    />
                                    <Box marginTop={6}>
                                        <LubesQuoteAttachments offer={offer} invalidateCache={handleInvalidateCache} />
                                    </Box>
                                </Box>
                            </Box>

                            <FormikSpotConfirmTermsModal
                                open={confirmSpotTermsModalOpen}
                                onDismiss={() => setConfirmSpotTermsModalOpen(false)}
                            />
                            <FormikDebug />
                        </OverlayHeader>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
