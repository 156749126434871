import { MouseEvent } from 'react';
import styled from 'styled-components';

import { Button, ButtonProps } from './Button';

const BaseDiscoverableButton = (props: ButtonProps) => {
    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        props.onClick?.(event);
    };

    return (
        <Button onClick={onClick} className={props.className} {...props}>
            {props.children}
        </Button>
    );
};

export const DiscoverableButton = styled(BaseDiscoverableButton)<ButtonProps>`
    opacity: 0;
    transition: opacity 75ms;
`;
