import { Fragment, useState, MouseEvent } from 'react';

import { IconButton } from '../../cdl/IconButton/IconButton';
import { DeleteConfirmPopup } from '../DeleteConfirmPopup/DeleteConfirmPopup';
import { IconTrash } from '../icons/cdl/Trash';

interface DeleteIconButtonProps {
    onDeleteConfirm: () => void;
    popupHeadline: string;
    popupText: string;
    tooltip: string;
    itemToDelete?: string;
}

export const DeleteIconButton = ({
    onDeleteConfirm,
    popupHeadline,
    popupText,
    tooltip,
    itemToDelete,
}: DeleteIconButtonProps) => {
    const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);

    const handleOnDeleteConfirm = () => {
        setShowDeleteConfirmPopup(false);
        onDeleteConfirm();
    };

    const handleOnClick = (event: MouseEvent<HTMLButtonElement>) => {
        setShowDeleteConfirmPopup(true);
        event.stopPropagation();
    };

    return (
        <Fragment>
            <IconButton Icon={IconTrash} onClick={handleOnClick} tooltipLabel={tooltip} aria-label="Delete" />
            <DeleteConfirmPopup
                isOpen={showDeleteConfirmPopup}
                onSubmit={handleOnDeleteConfirm}
                onDismiss={() => setShowDeleteConfirmPopup(false)}
                headline={popupHeadline}
                text={popupText}
                itemToDelete={itemToDelete}
            />
        </Fragment>
    );
};
