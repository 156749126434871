import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useToasts } from '../../cdl/Toast/useToasts';
import { createContract } from '../../common/api/clients/fuelContracts.api';
import { queryKeys } from '../../common/api/queryKeys';
import { translate } from '../../common/helpers/translate.helper';

export const useContractCreate = (onSuccess) => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: createContract,
        onSuccess: (contract) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.contracts() });
            queryClient.invalidateQueries({ queryKey: queryKeys.contractsTotals() });
            onSuccess?.(contract);

            if (contract.tender.enabled) {
                addToast(translate('contracts.toast.createContract.successTender'));
            } else {
                addToast(translate('contracts.toast.createContract.success'));
            }
        },
        onError: (error) => {
            if (error.response?.status !== 400) {
                addErrorToast(translate('contracts.toast.createContract.error'));
            }
        },
    });
};
