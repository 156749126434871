import { createLink, LinkComponent } from '@tanstack/react-router';
import { forwardRef } from 'react';

const BasicLinkComponent = forwardRef<HTMLAnchorElement, TableRowLinkProps>((props: TableRowLinkProps, ref) => {
    const { grow, numeric, ...rest } = props;

    return (
        <a
            ref={ref}
            {...rest}
            style={{
                height: !grow ? '40px' : 'auto',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: numeric ? 'flex-end' : 'flex-start',
            }}
        />
    );
});

BasicLinkComponent.displayName = 'BasicLinkComponent';

const CreatedLinkComponent = createLink(BasicLinkComponent);

interface TableRowLinkProps {
    grow: boolean;
    numeric?: boolean;
}

export const TableRowLink: LinkComponent<typeof BasicLinkComponent> = (props) => {
    return <CreatedLinkComponent preload="intent" {...props} />;
};
