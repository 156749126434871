import { ComponentPropsWithoutRef, ComponentType, FocusEventHandler, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Characters } from '../../../common/helpers/unicode.helper';
import { Flex } from '../../../common/ui/Flex';
import { Caption, CaptionProps } from '../../Caption/Caption';

import { BaseInput } from './BaseInput';
import { InputContainer } from './InputContainer';
import { InputLabelText } from './InputLabelText';
import { LeadingVisualWrapper } from './LeadingVisualWrapper';
import { TrailingVisualWrapper } from './TrailingVisualWrapper';

export interface CoreInputProps extends Pick<ComponentPropsWithoutRef<'input'>, 'disabled' | 'onFocus' | 'onBlur'> {
    /**
     * Define a label shown above the input
     */
    label?: string;
    /**
     * Define a helper text or caption for additional information below the input
     */
    caption?: string;
    /**
     * Define a helper icon for additional information below the input
     */
    CaptionIcon?: CaptionProps['Icon'];
    /**
     * Define additional information or actions on the top right of the input
     */
    meta?: ReactNode;
    /**
     * Add styles indicating a positive user input
     */
    positive?: boolean;
    /**
     * Add styles indicating a negative or destructive user input
     */
    negative?: boolean;
    /**
     * Add an element at the beginning of the input
     */
    leadingVisual?: ReactNode;
    /**
     * Add an element at the end of the input
     */
    trailingVisual?: ReactNode;
    /**
     * Override the base input component
     */
    as?: string | ComponentType<any>;
    /**
     * Add dot behind label to indicate a required user input
     */
    markAsRequired?: boolean;
}

export const InputLabel = styled.label`
    display: inline-block;
    width: 100%;

    // required to override global styles
    margin-bottom: 0;
    font-weight: 500;
`;

export const CoreInput = ({
    label,
    caption,
    CaptionIcon,
    meta,
    positive,
    negative,
    disabled,
    leadingVisual,
    trailingVisual,
    markAsRequired,
    ...rest
}: CoreInputProps) => {
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
        setIsInputFocused(true);
        rest.onFocus && rest.onFocus(event);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
        setIsInputFocused(false);
        rest.onBlur && rest.onBlur(event);
    };

    return (
        <InputLabel>
            <Flex justifyContent="space-between">
                {label ? (
                    <InputLabelText disabled={disabled} $isFocused={isInputFocused}>
                        {label}
                        &nbsp;
                        {markAsRequired ? Characters.BULLET : null}
                    </InputLabelText>
                ) : null}
                {meta ? <InputLabelText disabled={disabled}>{meta}</InputLabelText> : null}
            </Flex>
            <InputContainer $positive={positive} $negative={negative} $isFocused={isInputFocused} disabled={disabled}>
                {leadingVisual ? (
                    <LeadingVisualWrapper disabled={disabled}>{leadingVisual}</LeadingVisualWrapper>
                ) : null}

                <BaseInput
                    {...rest}
                    disabled={disabled}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    $hasLeadingVisual={!!leadingVisual}
                    $hasTrailingVisual={!!trailingVisual}
                />

                {trailingVisual ? (
                    <TrailingVisualWrapper disabled={disabled}>{trailingVisual}</TrailingVisualWrapper>
                ) : null}
            </InputContainer>

            {caption ? (
                <Caption $negative={negative} Icon={CaptionIcon}>
                    {caption}
                </Caption>
            ) : null}
        </InputLabel>
    );
};
