import { NoSearchResults } from '../../Search/NoSearchResults/NoSearchResults';
import { Box } from '../../ui/Box';

export const CommandEmptyState = () => {
    return (
        <Box paddingY={7}>
            <NoSearchResults />
        </Box>
    );
};
