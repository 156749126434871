import { useNavigate } from '@tanstack/react-router';

import { CustomerCompanyMessage } from '../../../../types/CustomerCompanyMessage';
import { IconCornerDownRight } from '../../icons/cdl/CornerDownRight';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandItem } from './CommandItem';

interface CompanyDetailProps {
    customer: CustomerCompanyMessage;
    value: string;
    isChildren: boolean;
}

export const CommandCustomerCompanyDetail = (props: CompanyDetailProps) => {
    const { setOpen } = useShortcutMenu();
    const navigate = useNavigate();

    const onCompanySelect = (id: string) => {
        navigate({ to: '/customer/detail/$id', params: { id } });
        setOpen(false);
    };

    return (
        <CommandItem
            value={props.value}
            onSelect={() => onCompanySelect(props.customer.id)}
            Icon={props.isChildren ? IconCornerDownRight : undefined}
        >
            {props.customer.name}
        </CommandItem>
    );
};
