import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconBackspace = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8.292 5.293A1 1 0 0 1 9 5h11a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H9a1 1 0 0 1-.707-.293l-5-5a2.5 2.5 0 0 1 0-3.414l5-5ZM9.413 7l-4.682 4.682a.5.5 0 0 0 0 .636L9.413 17H20V7H9.413Zm1.88 2.293a1 1 0 0 1 1.413 0L14 10.586l1.293-1.293a1 1 0 1 1 1.414 1.414L15.413 12l1.293 1.293a1 1 0 0 1-1.414 1.414L14 13.414l-1.293 1.293a1 1 0 0 1-1.414-1.414L12.585 12l-1.293-1.293a1 1 0 0 1 0-1.414Z"
            clipRule="evenodd"
        />
    </svg>
);
