import { useNavigate } from '@tanstack/react-router';
import { Command, useCommandState } from 'cmdk';

import { UserGatewayMessage } from '../../../../types/UserGatewayMessage';
import { useManagementUsersPagination } from '../../../user/list/hooks/useManagementUsers';
import { useDebounce } from '../../hooks/useDebounce';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandEmptyState } from './CommandEmptyState';
import { CommandErrorState } from './CommandErrorState';
import { CommandItem } from './CommandItem';
import { CommandSkeletonList } from './CommandSkeletonList';

export const UserSearch = () => {
    const search = useCommandState((state) => state.search);
    const debouncedSearch = useDebounce(search, 300);
    const { setOpen } = useShortcutMenu();

    const query = useManagementUsersPagination({ searchQuery: debouncedSearch, page: 0 });

    const navigate = useNavigate();

    const onUserSelect = (userId: string) => {
        navigate({ to: '/user/detail/$id', params: { id: userId } });
        setOpen(false);
    };

    if (query.isPending) {
        return <CommandSkeletonList />;
    }

    if (query.isError) {
        return <CommandErrorState />;
    }

    if (!query.data || query.data.content.length === 0) {
        return <CommandEmptyState />;
    }

    const formatUser = (user: UserGatewayMessage) => {
        if (!user.firstname || !user.lastname) {
            return user.email;
        }

        return `${user.firstname} ${user.lastname} (${user.email})`;
    };

    return (
        <Command.List>
            {query.data.content.map((user: UserGatewayMessage) => (
                <CommandItem key={user.id} value={user.id} onSelect={() => onUserSelect(user.id)}>
                    {formatUser(user)}
                </CommandItem>
            ))}
        </Command.List>
    );
};
