import { useEffect, useRef, useState } from 'react';

import { Port } from '../../port/Port';
import { getPorts } from '../api/clients/port.api';
import { DoubleLineOption } from '../DoubleLineOption/DoubleLineOption';
import { AsyncSearchSelect } from '../form-elements/AsyncSearchSelect/AsyncSearchSelect';
import { useFormData } from '../form-elements/Form/useFormData';
import { Label } from '../form-elements/Label/Label';

const PortOption = ({ data: { port }, ...props }) => {
    return (
        <DoubleLineOption
            headline={<Port port={port} />}
            subline={`${port.country.name} (${port.locCode})`}
            {...props}
        />
    );
};

const loadPortsById = (ids) => {
    return new Promise((resolve) => {
        getPorts({ ids })
            .then((ports) => resolve(ports))
            .catch(() => {}); // silently fail
    });
};

export const FormMultiPortSelect = ({ dataPath, label, annotation, required, ...input }) => {
    /**
     * The backend gives us an array of IDs, but the AsyncSearchSelect needs
     * IDs+Labels for display purposes. So we initially loadPorts with the
     * formValue and bring the response into desired shape.
     */
    const [displayValue, setDisplayValue] = useState(null);
    const { value: formValue, onChange } = useFormData(dataPath, {
        default: [],
    });

    const valueRef = useRef(formValue);

    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(!required);
    const validate = (options) => required && setIsValid(options?.length > 0);

    const parsePortsResponse = (port) => ({
        label: <Port port={port} highlightColor="white" />,
        value: port.id,
        port,
    });

    useEffect(() => {
        if (formValue.length <= 0) {
            return;
        }

        loadPortsById(formValue).then((response) => {
            const displayedPorts = [];
            formValue.forEach((id) => {
                const port = response.content.find((port) => port.id === id);
                if (port) {
                    displayedPorts.push(parsePortsResponse(port));
                }
            });
            setDisplayValue(displayedPorts);
        });
    }, [valueRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (valueRef.current.toString() !== formValue.toString()) {
            valueRef.current = formValue;
        }
    }, [formValue]);

    const loadPortsByInput = (input) =>
        new Promise((resolve) => {
            getPorts({ searchQuery: input })
                .then((response) => response.content.map(parsePortsResponse))
                .then((ports) => resolve(ports))
                .catch(() => {}); // silently fail
        });

    return (
        <Label label={label} annotation={annotation} required={required}>
            <AsyncSearchSelect
                loadOptions={loadPortsByInput}
                className={[isDirty ? 'dirty' : '', isValid ? 'valid' : 'invalid'].join(' ')}
                onBlur={() => setIsDirty(true)}
                onChange={(options) => {
                    setDisplayValue(options);
                    validate(options);
                    onChange({
                        target: {
                            value: options.map((option) => option.value),
                        },
                    });
                }}
                values={displayValue ? displayValue : []}
                isMulti={true}
                components={{
                    Option: PortOption,
                }}
                {...input}
            />
        </Label>
    );
};
