import { useNavigate } from '@tanstack/react-router';
import { Command, useCommandState } from 'cmdk';

import { ProductContext } from '../../../../types/ProductContext';
import { OfferModel } from '../../../offer/model/OfferModel';
import { useOfferPagination } from '../../../offer/useOfferPagination';
import { formatPort } from '../../helpers/formatPort.helper';
import { Characters } from '../../helpers/unicode.helper';
import { useDebounce } from '../../hooks/useDebounce';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandEmptyState } from './CommandEmptyState';
import { CommandErrorState } from './CommandErrorState';
import { CommandItem } from './CommandItem';
import { CommandSkeletonList } from './CommandSkeletonList';

export const OfferSearch = () => {
    const search = useCommandState((state) => state.search);
    const { setOpen } = useShortcutMenu();

    const debouncedSearch = useDebounce(search, 300);

    const navigate = useNavigate();

    const query = useOfferPagination({
        searchQuery: debouncedSearch,
        page: 0,
        types: [ProductContext.LUBES, ProductContext.FUEL],
    });

    const onSelect = (offer: OfferModel) => {
        const pathName = offer.type === ProductContext.LUBES ? '/offer/$id' : '/fuel/offer/$id';

        navigate({ to: pathName, params: { id: offer.id } });
        setOpen(false);
    };

    if (query.isPending) {
        return <CommandSkeletonList />;
    }

    if (query.isError) {
        return <CommandErrorState />;
    }

    if (!query.data || query.data.content.length === 0) {
        return <CommandEmptyState />;
    }

    const formatOffer = (offer: OfferModel) => {
        const parts = [offer.buyerReference, offer.vessel.name, formatPort({ port: offer.port })];

        return parts.filter(Boolean).join(Characters.EN_SPACE + Characters.MIDDLE_DOT + Characters.EN_SPACE);
    };

    return (
        <Command.List>
            {query.data?.content.map((offer: OfferModel) => (
                <CommandItem key={offer.id} value={offer.id} onSelect={() => onSelect(offer)}>
                    {formatOffer(offer)}
                </CommandItem>
            ))}
        </Command.List>
    );
};
