import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { DropdownMenu, DropdownMenuOption } from '../../../../cdl/DropdownMenu/DropdownMenu';
import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { IconDots } from '../../../../common/icons/cdl/Dots';
import { IconPencil } from '../../../../common/icons/cdl/Pencil';
import { useOrderExport } from '../../../../offer/detail/common/hooks/useOrderExport';
import { toLubesUpdateOrderRequest } from '../../../model/order.mapper';
import { OrderModel, OrderState } from '../../../model/OrderModel';
import { useOrderRedispatch } from '../../common/useOrderRedispatch';
import { useOrderUpdate } from '../hooks/useOrderUpdate';

import { OrderCancelModal } from './OrderCancelModal';

interface LubesOfferCompareActionBarProps {
    order: OrderModel;
}

export const LubesOfferCompareActionBar = ({ order }: LubesOfferCompareActionBarProps) => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const updateOrderMutation = useOrderUpdate();
    const { downloadPdf } = useOrderExport();
    const { addLoadingToast } = useToasts();

    const role = useRole();
    const navigate = useNavigate();
    const redispatchOrderMutation = useOrderRedispatch();
    const { addToast, addErrorToast } = useToasts();

    const prefillOrder = (order: OrderModel) => {
        navigate({
            to: '/orders/new',
            state: {
                order,
            },
        });
    };

    const onCancelPopupSubmit = async (cancelReason: string) => {
        await updateOrderMutation.mutateAsync({
            order: {
                ...toLubesUpdateOrderRequest(order),
                state: OrderState.CANCELED,
                cancelReason,
            },
            orderId: order.id,
        });

        setShowCancelModal(false);
    };

    const options: DropdownMenuOption[] = [];

    if (role.isCustomer() && role.hasWriteRights(order.customerId)) {
        options.push({
            label: translate('order.copy'),
            onSelect: () => prefillOrder(order),
        });
    }

    options.push({
        label: translate('order.detail.header.actions.export'),
        onSelect: () =>
            addLoadingToast(
                () => downloadPdf(order.id),
                translate('offer.detail.header.actions.export.downloadingPdf'),
                translate('offer.detail.header.actions.export.success'),
                translate('global.genericToastError')
            ),
    });

    if (role.isCustomer() && !order.isFinished() && !order.isDraft() && role.hasWriteRights(order.customerId)) {
        options.push({
            label: translate('order.detail.btncancel'),
            onSelect: () => setShowCancelModal(true),
            destructive: true,
        });
    }

    if (role.isAdmin() && order.isOpen()) {
        options.push({
            label: translate('order.detail.header.actions.redispatch'),
            onSelect: () => {
                redispatchOrderMutation.mutate(order.id, {
                    onSuccess: () => {
                        addToast(translate('order.detail.redispatchSuccessToast'));
                    },
                    onError: () => {
                        addErrorToast(translate('order.detail.redispatchErrorToast'));
                    },
                });
            },
        });
    }

    return (
        <>
            <ButtonGroup>
                {!role.isAdmin() && !order.isFinished() && !order.isDraft() && role.hasWriteRights(order.customerId) ? (
                    <Button
                        onClick={() =>
                            navigate({
                                to: '/order/edit/$id',
                                params: {
                                    id: order.id,
                                },
                            })
                        }
                        leadingVisual={<IconPencil height={16} width={16} />}
                    >
                        {translate('order.detail.edit')}
                    </Button>
                ) : null}

                {role.isAdmin() && order.state !== OrderState.DRAFT ? (
                    <Button onClick={() => navigate({ to: '/offers', search: { searchQuery: order.id } })}>
                        {translate('order.detail.header.actions.showOfferList')}
                    </Button>
                ) : null}

                <DropdownMenu
                    options={options}
                    align="end"
                    trigger={<IconButton Icon={IconDots} emphasis="medium" />}
                />
            </ButtonGroup>

            {showCancelModal ? (
                <OrderCancelModal
                    isOpen={showCancelModal}
                    onSubmit={onCancelPopupSubmit}
                    onDismiss={() => setShowCancelModal(false)}
                    isSubmitting={updateOrderMutation.isPending}
                />
            ) : null}
        </>
    );
};
