const AppSettings = {
    VERSION: '6.0.0',
    ENV: 'staging',
    SENTRY_DSN: 'https://f2addee7c0a849c28f0049d6f1fc04c7@sentry.io/278221',
    googleAnalyticsId: 'G-44MC80FPN2',
    googleApiURLKey: 'AIzaSyCItcgw6J6eZxVzHcke7SUJ_gUewOdw_z4',
    magicBellApiKey: '188e54046530f3f51777f99e4642609f5fc88f0f',
    routes: {
        api: '/api-staging',
        gateway: '/api-staging/gateway',
    },
    launchDarklyClientSideId: '65d46b4443c600100518f0f2',
    closelinkChartsUrl: 'https://charts.closelink.com',
    userlikeWidgetKey: 'aba4e0cc79fdec8ca9932a0b393316d1f0b9e17a41814d63eec15a10eafa368e',
};

export default AppSettings;
