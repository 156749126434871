import { EmptyPagePlaceholder } from '../../../cdl/EmptyPagePlaceholder/EmptyPagePlaceholder';
import { translate } from '../../helpers/translate.helper';
import { IconSearch } from '../../icons/cdl/Search';
import { Box } from '../../ui/Box';

interface NoSearchResultsProps {
    variant?: 'default' | 'page';
}

export const NoSearchResults = ({ variant = 'default' }: NoSearchResultsProps) => {
    return (
        <Box display="flex" justifyContent="center" paddingTop={variant === 'page' ? '124px' : undefined}>
            <EmptyPagePlaceholder
                title={translate('global.emptySearchResult.noSearchResults')}
                description={translate('global.emptySearchResult.tryDifferentQueryr')}
                Icon={IconSearch}
            />
        </Box>
    );
};
