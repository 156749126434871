import { EnergyContentMessage } from '../../../../types/EnergyContentMessage';
import { Iso8601DateTimeString } from '../../../../types/Iso8601DateTimeString';
import { ProductContext } from '../../../../types/ProductContext';
import { SamplekitMessage } from '../../../../types/SamplekitMessage';
import { OrderMessage, OrderState } from '../../../order/model/OrderModel';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

interface CreateOrderItemRequest {
    productId: string | null;
    packType: string;
    unitSize: number | null;
    units: number | null;
    unit: string;
}

export interface CreateOrderRequest {
    vesselId: string;
    portId: string | null;
    buyerReference: string | null;
    agentId: string | null;
    comment: string | null;
    receiverSupplierIds: string[] | null;
    samplekits: SamplekitMessage[] | null;
    spot: boolean | null;
    state: OrderState | null;
    chosenOfferId: string | null;
    type: ProductContext;
    fileIds: string[] | null;
}

type LubesCreateOrderItemRequest = CreateOrderItemRequest & {
    volume: number | null;
    deleted: boolean;
};

type FuelCreateOrderItemRequest = CreateOrderItemRequest & {
    productName?: string;
    isoSpec?: string;
    energyContent?: EnergyContentMessage;
    sulphurContent?: number;
    group?: string;
};

type LubesCreateOrderRequest = CreateOrderRequest & {
    dateDelivery: Iso8601DateTimeString | null;
    items: LubesCreateOrderItemRequest[];
};

export type FuelCreateOrderRequest = CreateOrderRequest & {
    items?: FuelCreateOrderItemRequest[];
    contractId: string | null;
    eta: Iso8601DateTimeString | null;
    etd: Iso8601DateTimeString | null;
    validityTime: number | null;
};

export interface CreateOrderResponse extends LubesCreateOrderRequest {
    id: string;
}

export type LubesUpdateOrderRequest = LubesCreateOrderRequest & {
    cancelReason: string | null;
};

export type FuelUpdateOrderRequest = FuelCreateOrderRequest;

export const createOrder = async (
    order: LubesCreateOrderRequest | FuelCreateOrderRequest
): Promise<CreateOrderResponse> => {
    const response = await authorizedAxiosInstance.post('/v2/orders', order);

    return response.data;
};

interface UpdateOrderParams {
    orderId: string;
    order: LubesUpdateOrderRequest | FuelUpdateOrderRequest;
}

export const updateOrder = async ({ orderId, order }: UpdateOrderParams): Promise<OrderMessage> => {
    const response = await authorizedAxiosInstance.put(`/v2/orders/${orderId}`, order);

    return response.data;
};

interface OrderValidationResponse {
    code: string;
    errors: {
        code: string;
        message: string;
        path: string;
    }[];
}

interface OrderValidationRequest {
    vesselId: string;
    buyerReference: string;
    type: ProductContext;
}

export const validateOrderCreation = async (orderDetails: OrderValidationRequest): Promise<OrderValidationResponse> => {
    const response = await authorizedAxiosInstance.post('/v2/orders/validation/create', orderDetails);
    return response.data;
};

export const validateOrderUpdate = async (
    orderDetails: OrderValidationRequest,
    orderId: string
): Promise<OrderValidationResponse> => {
    const response = await authorizedAxiosInstance.post(`/v2/orders/validation/update/${orderId}`, orderDetails);
    return response.data;
};

interface CreateMessageParams {
    offerId: string;
    message: string;
}

export const createMessage = async ({ offerId, message }: CreateMessageParams): Promise<any> => {
    const response = await authorizedAxiosInstance.post(`/v1/offer/${offerId}/message`, { message });

    return response.data;
};

export interface MarkOrderReadParams {
    orderId: string;
    read: boolean;
}

export const markOrderRead = async ({ orderId, read }: MarkOrderReadParams): Promise<void> => {
    await authorizedAxiosInstance.put(`/v2/orders/${orderId}/read/${read}`);
};
export const redispatchOrder = async (orderId: string): Promise<OrderMessage> => {
    const response = await authorizedAxiosInstance.put(`/v2/orders/${orderId}/redispatch`);

    return response.data;
};
export const deleteOrder = async (orderId: string): Promise<void> => {
    await authorizedAxiosInstance.delete(`/v2/orders/${orderId}`);
};

interface MarkOfferReadParams {
    offerId: string;
    read: boolean;
}

export const markOfferRead = async ({ offerId, read }: MarkOfferReadParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/offer/${offerId}/read/${read}`);

    return response.data;
};

interface UpdateOfferParams {
    offerId: string;
    offer: unknown;
}

export const updateOffer = async ({ offerId, offer }: UpdateOfferParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/offer/${offerId}`, offer);

    return response.data;
};
