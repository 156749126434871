import { Command } from 'cmdk';
import styled from 'styled-components';

import { BaseInput } from '../../../cdl/Input/components/BaseInput';
import { InputContainer } from '../../../cdl/Input/components/InputContainer';
import { Tag } from '../../../cdl/Tag/Tag';
import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../helpers/translate.helper';
import { Characters } from '../../helpers/unicode.helper';
import { IconBackspace } from '../../icons/cdl/Backspace';
import { IconCommand } from '../../icons/cdl/Command';
import { Box } from '../../ui/Box';
import { get } from '../../ui/get';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandListNestedRouterPath, CommandListNestedRouterPathEnum } from './commandRoutes';
import { Key } from './Key';

const StyledInputContainer = styled(InputContainer)`
    background-color: transparent;
`;

const StyledBaseInput = styled(BaseInput)`
    font-size: 18px;
    font-weight: ${get('fontWeights.normal')};
    padding: 0;
`;

const getPageTranslationKey = (page: CommandListNestedRouterPath) => {
    switch (page) {
        case CommandListNestedRouterPathEnum.ORDER:
            return 'shortcutMenu.order';
        case CommandListNestedRouterPathEnum.OFFER:
            return 'shortcutMenu.offer';
        case CommandListNestedRouterPathEnum.VESSEL:
            return 'shortcutMenu.vessel';
        case CommandListNestedRouterPathEnum.CUSTOMER:
            return 'shortcutMenu.customer';
        case CommandListNestedRouterPathEnum.SUPPLIER:
            return 'shortcutMenu.supplier';
        case CommandListNestedRouterPathEnum.AGENT:
            return 'shortcutMenu.agent';
        case CommandListNestedRouterPathEnum.PORT:
            return 'shortcutMenu.port';
        case CommandListNestedRouterPathEnum.USER:
            return 'shortcutMenu.user';
    }
};

interface CommandInputProps {
    search: string;
    onChange: (search: string) => void;
}

export const CommandInput = ({ search, onChange }: CommandInputProps) => {
    const { pages } = useShortcutMenu();

    const placeholder = translate('shortcutMenu.searchPlaceholder') + Characters.HORIZONTAL_ELLIPSIS;

    const page = pages[0];
    return (
        <Command.Input asChild>
            <StyledInputContainer $isFocused={false}>
                {page ? (
                    <Box paddingRight={4}>
                        <Tag variant="accent" emphasis>
                            {translate(getPageTranslationKey(page))}
                        </Tag>
                    </Box>
                ) : null}

                <StyledBaseInput
                    value={search}
                    onChange={(event) => onChange(event.target.value)}
                    placeholder={placeholder}
                    $hasLeadingVisual
                    $hasTrailingVisual
                />

                {search ? (
                    <Box display="flex" gap={4} flexDirection="row" alignItems="center" paddingRight="10px">
                        <Box display="flex" gap={2}>
                            <Key Icon={IconCommand} />
                            <Key Icon={IconBackspace} />
                        </Box>
                        <Text variant="extraSmall" color="foreground.subtle" style={{ whiteSpace: 'nowrap' }}>
                            {translate('shortcutMenu.toClear')}
                        </Text>
                    </Box>
                ) : null}

                {page && !search ? (
                    <Box display="flex" gap={4} flexDirection="row" alignItems="center" paddingRight="10px">
                        <Key Icon={IconBackspace} />

                        <Text variant="extraSmall" color="foreground.subtle" style={{ whiteSpace: 'nowrap' }}>
                            {translate('shortcutMenu.toReturn')}
                        </Text>
                    </Box>
                ) : null}
            </StyledInputContainer>
        </Command.Input>
    );
};
