import { Command } from 'cmdk';
import styled from 'styled-components';

import { get } from '../../ui/get';

export const CommandDialog = styled(Command.Dialog)`
    max-width: 640px;
    width: 100%;
    border-radius: ${get('radii.2')}px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20%;
    left: calc(50% - 320px);
    background: ${get('colors.background.default')};

    z-index: 9999;
    box-shadow:
        0px 0px 0px 1px #f1f7fa,
        0px 120px 120px 0px rgba(25, 54, 67, 0.08),
        0px 64px 64px 0px rgba(25, 54, 67, 0.12),
        0px 32px 32px 0px rgba(25, 54, 67, 0.04),
        0px 24px 24px 0px rgba(25, 54, 67, 0.04),
        0px 4px 24px 0px rgba(25, 54, 67, 0.04),
        0px 4px 4px 0px rgba(25, 54, 67, 0.04);

    [cmdk-list] {
        min-height: 300px;
        max-height: 500px;
        overflow: auto;
        overscroll-behavior: contain;
        transition: 100ms ease;
        transition-property: height;
        padding-bottom: ${get('space.2')}px;
    }

    [cmdk-group-heading] {
        user-select: none;
        font-size: ${get('fontSizes.1')}px;
        color: ${get('colors.foreground.muted')};
        padding: ${get('space.4')}px 0;
        margin: 0 ${get('space.2')}px;
        display: flex;
        align-items: center;
    }

    [cmdk-separator] {
        height: 1px;
        background: ${get('colors.border.subtle')};
        margin: ${get('space.2')}px ${get('space.2')}px;
    }
`;
