import { useNavigate, useSearch } from '@tanstack/react-router';

import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

import { CreatePortButton } from './components/CreatePortButton';
import { PortsOverviewTable } from './components/PortsOverviewTable';

export const PortsOverviewPage = () => {
    const title = translate('ports.headline');
    useDocumentTitle(title);

    const search = useSearch({ from: '/_app/ports' });
    const navigate = useNavigate({ from: '/ports' });

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text variant="headline" as="h1">
                        {translate('ports.headline')}
                    </Text>

                    <CreatePortButton />
                </Box>
                <Box width="250px">
                    <DebouncedSearchTextInput
                        onChange={(searchQuery) => {
                            navigate({ search: { searchQuery, page: 0 } });
                        }}
                        value={search.searchQuery}
                        placeholder={translate('placeholder.port')}
                    />
                </Box>
                <PortsOverviewTable />
            </PageGrid>
        </Page>
    );
};
