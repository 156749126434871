import React, { useEffect, useState } from 'react';

import AppSettings from '../../constants';
import { useRole } from '../hooks/useRole';
import { Box } from '../ui/Box';

import { CommandDialog } from './components/CommandDialog';
import { CommandFooter } from './components/CommandFooter';
import { CommandInput } from './components/CommandInput';
import { CommandListRouter } from './components/CommandListRouter';
import { CommandListNestedRouterPath } from './components/commandRoutes';
import { ShortcutMenuContext } from './components/ShortcutMenuContext';

export const ShortcutMenu = () => {
    const role = useRole();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [pages, setPages] = useState<CommandListNestedRouterPath[]>([]);

    // Toggle the menu when ⌘K is pressed
    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                setOpen((open) => !open);
                setSearch('');
                setPages([]);
            }
        };

        document.addEventListener('keydown', down);
        return () => document.removeEventListener('keydown', down);
    }, []);

    useEffect(() => {
        setSearch('');
    }, [pages]);

    // Disable for non-admins in production
    if (AppSettings.ENV === 'production' && !role.isAdmin()) {
        return null;
    }

    return (
        <ShortcutMenuContext.Provider
            value={{
                setOpen,
                pages,
                setPages,
            }}
        >
            <CommandDialog
                key={pages.length}
                loop
                open={open}
                onOpenChange={setOpen}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    // Escape goes to previous page
                    // Backspace goes to previous page when search is empty
                    if (e.key === 'Backspace' && !search) {
                        e.preventDefault();
                        setPages((pages) => pages.slice(0, -1));
                    }

                    if (e.key === 'Escape') {
                        e.preventDefault();
                        setOpen(false);
                    }
                }}
                shouldFilter={pages.length === 0}
            >
                <Box padding={5} borderBottom="1px solid" borderColor="border.subtle">
                    <CommandInput search={search} onChange={setSearch} />
                </Box>

                <Box paddingX={4}>
                    <CommandListRouter />
                </Box>

                <CommandFooter />
            </CommandDialog>
        </ShortcutMenuContext.Provider>
    );
};
