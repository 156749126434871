import { SamplekitMessage } from '../../../../../../types/SamplekitMessage';
import { SurchargeMessage } from '../../../../../../types/SurchargeMessage';
import { StyledDefaultHeaderCell } from '../../../../../cdl/TableBuilder/components/StyledDefaultHeaderCell';
import { StyledTable } from '../../../../../cdl/TableBuilder/components/StyledTable';
import { Text } from '../../../../../cdl/Text/Text';
import { translate } from '../../../../../common/helpers/translate.helper';
import { ItemModel } from '../../../../../common/models/ItemModel';

import { ItemRow } from './components/ItemRow';
import { SampleKitRow } from './components/SampleKitRow';
import { SurchargeRow } from './components/SurchargeRow';
import { Totals, TotalsRow } from './components/TotalsRow';

interface LubesItemTableProps {
    items: ItemModel[];
    surcharges?: SurchargeMessage[];
    sampleKits?: SamplekitMessage[];
    totals?: Totals;
    showPrices?: boolean;
}

export const LubesReadOnlyProductTable = ({
    items,
    surcharges = [],
    sampleKits = [],
    totals,
    showPrices = false,
}: LubesItemTableProps) => {
    const columns = [
        { header: translate('order.pos'), numeric: true },
        { header: translate('order.product') },
        { header: translate('order.packtype') },
        { header: translate('order.unitsize'), numeric: true },
        { header: translate('order.units'), numeric: true },
        { header: translate('order.volume'), numeric: true },
        { header: translate('order.unit') },
        { header: translate('order.ppl'), numeric: true, condition: showPrices },
        { header: translate('order.total'), numeric: true, condition: showPrices },
    ].filter(({ condition = true }) => condition);

    return (
        <StyledTable variant="default">
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <StyledDefaultHeaderCell key={index} textAlign={column.numeric ? 'right' : 'left'}>
                            <Text variant="fieldLabel" color="foreground.muted">
                                {column.header}
                            </Text>
                        </StyledDefaultHeaderCell>
                    ))}
                </tr>
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <ItemRow key={index} item={item} tableRowIndex={index} showPrices={showPrices} />
                ))}

                {sampleKits.length
                    ? sampleKits.map((sampleKit, index) => (
                          <SampleKitRow
                              key={index}
                              sampleKit={sampleKit}
                              tableRowIndex={items.length + index}
                              showPrices={showPrices}
                          />
                      ))
                    : null}

                {surcharges.length
                    ? surcharges.map((surcharge, index) => (
                          <SurchargeRow
                              key={index}
                              surcharge={surcharge}
                              tableRowIndex={items.length + sampleKits.length + index}
                              showPrices={showPrices}
                          />
                      ))
                    : null}

                {totals ? <TotalsRow totals={totals} showPrices={showPrices} /> : null}
            </tbody>
        </StyledTable>
    );
};
