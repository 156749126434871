import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { fuelOrderOverviewSearchSchema } from '../../types/fuelOrderOverviewSearchSchema';
import { OrderOverviewPage } from '../order/OrderOverview/fuel/FuelOrderOverviewPage';

export const Route = createFileRoute('/_app/fuel/orders')({
    component: OrderOverviewPage,
    validateSearch: zodSearchValidator(fuelOrderOverviewSearchSchema),
});
