import 'moment-timezone';
import moment from 'moment';
import styled from 'styled-components';

import { Text } from '../../../cdl/Text/Text.tsx';
import { translate } from '../../helpers/translate.helper';
import { IconMessages } from '../../icons/cdl/Messages';
import { IconPhone } from '../../icons/cdl/Phone';
import { Box } from '../../ui/Box';
import { get } from '../../ui/get';
import { useStartUserlikeChat } from '../../hooks/useStartUserlikeChat';

const SidebarItemButton = styled.button`
    font-size: ${get('fontSizes.2')}px;
    font-weight: ${get('fontWeights.medium')};
    line-height: 24px;
    color: ${get('colors.foreground.muted')};
    padding: ${get('space.2')}px ${get('space.4')}px;
    border: none;
    border-radius: ${get('radii.2')}px;

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: ${get('colors.background.inset')};
    }
`;

const t = (key, params) => translate(`navigation.${key}`, params);

export const ContactUs = () => {
    const startUserlikeChat = useStartUserlikeChat();

    const isSummerTime = moment(new Date()).tz('Europe/Berlin').isDST();
    const timezone = isSummerTime ? 'CEST' : 'CET';

    return (
        <Box display="grid" gap={5}>
            <SidebarItemButton onClick={startUserlikeChat}>
                <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                    <Text variant="extraSmall" fontWeight="medium" color="foreground.default">
                        {t('messageUs')}
                    </Text>
                    <Box padding={2}>
                        <IconMessages height={16} width={16} />
                    </Box>
                </Box>
            </SidebarItemButton>

            <SidebarItemButton
                onClick={() => {
                    window.open(`tel:${t('phoneNumber')}`);
                }}
            >
                <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                    <Box display="grid">
                        <Text variant="extraSmall" fontWeight="medium" color="foreground.default">
                            {t('phoneNumber')}
                        </Text>
                        <Text variant="extraSmall">{t('workingHours', { timezone })}</Text>
                    </Box>
                    <Box padding={2}>
                        <IconPhone height={16} width={16} />
                    </Box>
                </Box>
            </SidebarItemButton>
        </Box>
    );
};
