import { Router } from '@tanstack/react-router';
import axios from 'axios';

import AppSettings from '../../../constants';

import { addAuthenticationInterceptors } from './interceptors/addAuthenticationInterceptors';

const authorizedGatewayAxiosInstance = axios.create({
    baseURL: AppSettings.routes.gateway,
});

export const initAuthorizedGatewayAxiosInstance = (router: Router<any, any, any>) => {
    addAuthenticationInterceptors(authorizedGatewayAxiosInstance, router);
};

export { authorizedGatewayAxiosInstance };
