import css from '@styled-system/css';
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { translate } from '../helpers/translate.helper';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { useRole } from '../hooks/useRole';
import { IconChevronDown } from '../icons/cdl/ChevronDown';
import { IconChevronUp } from '../icons/cdl/ChevronUp';
import { NavigationLink } from '../Link/NavigationLink';
import { Box } from '../ui/Box';
import { Flex } from '../ui/Flex';

const UserAvatar = styled(Flex)(
    css({
        backgroundColor: 'striking-purple.0',
        borderRadius: '100%',
        height: '32px',
        width: '32px',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        ':hover': {
            opacity: 0.75,
        },
    })
);

const UserMenuLink = styled(NavigationLink)(
    css({
        display: 'block',
        paddingX: 6,
        paddingY: 5,
        borderBottom: 1,
        minWidth: '120px',
        cursor: 'pointer',
        ':hover': {
            color: 'clear-blue.0',
            textDecoration: 'none',
            backgroundColor: 'clear-blue.9',
        },
    })
);

const UserMenuPopout = styled(Box)(
    css({
        position: 'absolute',
        backgroundColor: 'white',
        top: '70px',
        right: 3,
        border: 1,
        borderRadius: 1,
        boxShadow: 'medium',
    })
);

const Arrow = styled(Box)(
    css({
        borderRadius: '100%',
        backgroundColor: 'white',
        width: '12px',
        height: '12px',
        color: 'black',
        position: 'absolute',
        right: 0,
        transform: 'translateX(50%)',
    })
);

export const UserMenu = () => {
    const role = useRole();
    const [showMenu, setShowMenu] = useState(false);
    const userMenuRef = useRef(null);
    const avatarRef = useRef(null);

    const hideMenu = useCallback(() => {
        setShowMenu(false);
    }, [setShowMenu]);

    useOutsideClick([userMenuRef, avatarRef], hideMenu);

    return (
        <div data-test-id="UserMenu">
            <UserAvatar onClick={() => setShowMenu((prev) => !prev)} ref={avatarRef}>
                <Text color="white" weight="semi" size={10}>
                    {role.user.firstname[0]}
                    {role.user.lastname[0]}
                </Text>
                <Arrow>
                    {showMenu ? <IconChevronUp width={12} height={12} /> : <IconChevronDown width={12} height={12} />}
                </Arrow>
            </UserAvatar>

            {showMenu ? (
                <UserMenuPopout ref={userMenuRef}>
                    <UserMenuLink onClick={hideMenu} to="/user/detail/$id" params={{ id: role.user.id }}>
                        {translate('global.settings')}
                    </UserMenuLink>
                    <UserMenuLink preload={false} to="/logout">
                        {translate('navigation.logout')}
                    </UserMenuLink>
                </UserMenuPopout>
            ) : null}
        </div>
    );
};
