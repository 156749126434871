import { Command, useCommandState } from 'cmdk';

import { useCustomerGroupsPagination } from '../../../admin/customergroup/hooks/useCustomerGroupsPagination';
import { useCustomerList } from '../../../group/customer/detail/hooks/useCustomerList';
import { translate } from '../../helpers/translate.helper';
import { useDebounce } from '../../hooks/useDebounce';

import { CommandCustomerCompanyDetail } from './CommandCustomerCompanyDetail';
import { CommandCustomerGroupDetail } from './CommandCustomerGroupDetail';
import { CommandEmptyState } from './CommandEmptyState';
import { CommandErrorState } from './CommandErrorState';
import { CommandSkeletonList } from './CommandSkeletonList';

export const CustomerGroupSearch = () => {
    const search = useCommandState((state) => state.search);
    const debouncedSearch = useDebounce(search, 300);

    const customerGroupsQuery = useCustomerGroupsPagination(search, 0);
    const customersQuery = useCustomerList({
        searchQuery: debouncedSearch,
    });

    if (customerGroupsQuery.isPending || customersQuery.isPending) {
        return <CommandSkeletonList />;
    }

    if (customerGroupsQuery.isError || customersQuery.isError) {
        return <CommandErrorState />;
    }

    if (!customerGroupsQuery.data && !customersQuery.data) {
        return <CommandEmptyState />;
    }

    return (
        <Command.List>
            <Command.Group heading={translate('shortcutMenu.groups')}>
                {customerGroupsQuery.data?.content.map((customerGroup) => (
                    <CommandCustomerGroupDetail customerGroup={customerGroup} key={customerGroup.id} />
                ))}
            </Command.Group>

            <Command.Group heading={translate('shortcutMenu.companies')}>
                {customersQuery.data?.map((customer) => (
                    <CommandCustomerCompanyDetail
                        key={customer.id}
                        value={customer.id}
                        customer={customer}
                        isChildren={false}
                    />
                ))}
            </Command.Group>
        </Command.List>
    );
};
