import { useNavigate } from '@tanstack/react-router';
import { Command, useCommandState } from 'cmdk';

import { ProductContext } from '../../../../types/ProductContext';
import { OrderModel } from '../../../order/model/OrderModel';
import { useOrderPagination } from '../../../order/useOrderPagination';
import { formatPort } from '../../helpers/formatPort.helper';
import { Characters } from '../../helpers/unicode.helper';
import { useDebounce } from '../../hooks/useDebounce';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandEmptyState } from './CommandEmptyState';
import { CommandErrorState } from './CommandErrorState';
import { CommandItem } from './CommandItem';
import { CommandSkeletonList } from './CommandSkeletonList';

export const OrderSearch = () => {
    const search = useCommandState((state) => state.search);
    const debouncedSearch = useDebounce(search, 300);

    const { setOpen } = useShortcutMenu();

    const navigate = useNavigate();

    const query = useOrderPagination({
        searchQuery: debouncedSearch,
        page: 0,
        types: [ProductContext.LUBES, ProductContext.FUEL],
    });

    const onSelect = (order: OrderModel) => {
        if (order.isClosed()) {
            navigate({
                to: order.type === ProductContext.LUBES ? '/offer/$id' : '/fuel/offer/$id',
                params: {
                    id: order.chosenOfferId!,
                },
            });
        } else {
            navigate({
                to: order.type === ProductContext.LUBES ? '/order/$id' : '/fuel/order/$id',
                params: {
                    id: order.id,
                },
            });
        }

        setOpen(false);
    };

    if (query.isPending) {
        return <CommandSkeletonList />;
    }

    if (query.isError) {
        return <CommandErrorState />;
    }

    if (!query.data || query.data.content.length === 0) {
        return <CommandEmptyState />;
    }

    const formatOrder = (order: OrderModel) => {
        const parts = [order.buyerReference, order.vessel.name, formatPort({ port: order.port })];

        return parts.filter(Boolean).join(Characters.EN_SPACE + Characters.MIDDLE_DOT + Characters.EN_SPACE);
    };

    return (
        <Command.List>
            {query.data?.content.map((order: OrderModel) => (
                <CommandItem key={order.id} value={order.id} onSelect={() => onSelect(order)}>
                    {formatOrder(order)}
                </CommandItem>
            ))}
        </Command.List>
    );
};
