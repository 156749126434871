import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownMenuItemProps } from '@radix-ui/react-dropdown-menu';
import { PopperContentProps } from '@radix-ui/react-popper';
import { ReactNode } from 'react';

import { IconDots } from '../../common/icons/cdl/Dots';
import { IconButton } from '../IconButton/IconButton';
import { Text } from '../Text/Text';

import { DropdownMenuContent } from './DropdownMenuContent';
import { DropdownMenuItem } from './DropdownMenuItem';

export interface DropdownMenuOption {
    onSelect: DropdownMenuItemProps['onSelect'];
    label: string;
    destructive?: boolean;
}

interface DropdownMenuProps extends Pick<PopperContentProps, 'align'> {
    options: DropdownMenuOption[];
    trigger?: ReactNode;
}

export const DropdownMenu = ({ trigger = <IconButton Icon={IconDots} />, ...props }: DropdownMenuProps) => {
    return (
        <RadixDropdownMenu.Root modal={false}>
            <RadixDropdownMenu.Trigger asChild onClick={(event) => event.stopPropagation()}>
                {trigger}
            </RadixDropdownMenu.Trigger>

            <DropdownMenuContent sideOffset={5} align={props.align ?? 'start'}>
                {props.options.map((option, index) => (
                    <DropdownMenuItem
                        key={index}
                        onClick={(event) => event.stopPropagation()}
                        onSelect={option.onSelect}
                        $destructive={option.destructive}
                    >
                        <Text variant="fieldLabel">{option.label}</Text>
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </RadixDropdownMenu.Root>
    );
};
