import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { DropdownMenu, DropdownMenuOption } from '../../../cdl/DropdownMenu/DropdownMenu';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { queryKeys } from '../../../common/api/queryKeys';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconDots } from '../../../common/icons/cdl/Dots';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { OfferCancelPopup } from '../../../common/OfferCancelPopup/OfferCancelPopup';
import { OfferModel } from '../../model/OfferModel';
import { useMessagesExport } from '../common/hooks/useMessagesExport';
import { useOfferExport } from '../common/hooks/useOfferExport';
import { useOfferUpdate } from '../common/hooks/useOfferUpdate';

import { ShareOrderButton } from './components/ShareOrderButton';
import { LubesInitialEnquiryPopup } from './LubesInitialEnquiryPopup';

interface LubesOfferDetailActionBarProps {
    offer: OfferModel;
}

export const LubesOfferDetailActionBar = ({ offer }: LubesOfferDetailActionBarProps) => {
    const queryClient = useQueryClient();

    const [showInitialEnquiryPopup, setShowInitialEnquiryPopup] = useState(false);
    const [showCancelPopup, setShowCancelPopup] = useState(false);

    const { addLoadingToast } = useToasts();

    const updateOfferMutation = useOfferUpdate();
    const { downloadPdf } = useOfferExport();
    const { downloadMessages } = useMessagesExport(offer.id);

    const role = useRole();
    const navigate = useNavigate();

    const navigateToEditPage = () => {
        navigate({
            to: '/offer/$id/edit',
            params: {
                id: offer.id,
            },
        });

        queryClient.removeQueries({ queryKey: queryKeys.offer(offer.id) });
    };

    const prefillOrder = (order) => {
        navigate({
            to: '/orders/new',
            state: {
                order,
            },
        });
    };

    const navigateToOfferOverview = () => {
        navigate({
            to: '/order/$id',
            params: {
                id: offer.orderId,
            },
        });
    };

    const hasOfferWriteRights = (offer) => {
        return role.hasWriteRights(offer.customerId) || role.hasWriteRights(offer.supplierId);
    };

    const determineCancelState = (currentState) => {
        const enquiryStates = ['ENQUIRY', 'QUOTED'];

        if (enquiryStates.includes(currentState)) {
            if (role.isSupplier()) {
                return 'ENQUIRY_DECLINED';
            }
            if ('ENQUIRY' === currentState) {
                return 'ENQUIRY_CANCELED';
            }
            return 'QUOTE_CANCELED';
        }

        return 'CANCELED';
    };

    const onCancelPopupSubmit = async ({ cancelReason }) => {
        const newState = determineCancelState(offer.state);
        await updateOfferMutation.mutateAsync({
            offer: {
                ...offer,
                state: newState,
                cancelReason,
            },
            offerId: offer.id,
        });

        setShowCancelPopup(false);
    };

    const options: DropdownMenuOption[] = [];

    if (role.isCustomer() && role.hasWriteRights(offer.customerId)) {
        options.push({
            label: translate('offer.detail.header.actions.copy'),
            onSelect: () => {
                prefillOrder({
                    vessel: offer.vessel,
                    vesselId: offer.vesselId,
                    dateDelivery: offer.dateDelivery,
                    agent: offer.agent,
                    agentId: offer.agentId,
                    customerId: offer.customerId,
                    portId: offer.portId,
                    port: offer.port,
                    buyerReference: offer.buyerReference,
                    items: offer.items,
                    samplekits: offer.samplekits,
                    receiverSupplierIds: [offer.supplierId],
                    spot: offer.spot,
                    attributes: offer.attributes,
                });
            },
        });
    }

    options.push({
        label: translate('offer.detail.header.actions.initialOrder'),
        onSelect: () => setShowInitialEnquiryPopup(true),
    });

    if (!role.isSupplier()) {
        options.push({
            label: translate('offer.detail.header.actions.spotOverview'),
            onSelect: navigateToOfferOverview,
        });
    }

    options.push({
        label: translate('offer.detail.header.actions.export.pdf'),
        onSelect: () => {
            return addLoadingToast(
                () => downloadPdf(offer.id),
                translate('offer.detail.header.actions.export.downloadingPdf'),
                translate('offer.detail.header.actions.export.success'),
                translate('global.genericToastError')
            );
        },
    });

    options.push({
        label: translate('offer.detail.header.actions.export.messages'),
        onSelect: () => downloadMessages(offer.offerNumber),
    });

    if (role.isCustomer() && !offer.isFinished() && hasOfferWriteRights(offer)) {
        options.push({
            label: translate('offer.detail.header.actions.cancel'),
            onSelect: () => setShowCancelPopup(true),
            destructive: true,
        });
    }

    if (role.isSupplier() && !offer.isFinished() && hasOfferWriteRights(offer)) {
        const label =
            offer.state === 'QUOTED'
                ? translate('offer.detail.header.actions.cancel')
                : translate('offer.detail.header.actions.decline');

        options.push({
            label,
            onSelect: () => setShowCancelPopup(true),
            destructive: true,
        });
    }

    return (
        <>
            <ButtonGroup>
                {role.isCustomer() ? <ShareOrderButton offer={offer} /> : null}

                {!role.isAdmin() && offer.isEditable() && hasOfferWriteRights(offer) ? (
                    <Button leadingVisual={<IconPencil height={16} width={16} />} onClick={navigateToEditPage}>
                        {translate('actions.edit')}
                    </Button>
                ) : null}

                <DropdownMenu
                    options={options}
                    align="end"
                    trigger={<IconButton emphasis="medium" Icon={IconDots} />}
                />
            </ButtonGroup>

            {showInitialEnquiryPopup ? (
                <LubesInitialEnquiryPopup
                    offerId={offer.id}
                    onDismiss={() => setShowInitialEnquiryPopup(false)}
                    isOpen={showInitialEnquiryPopup}
                />
            ) : null}

            {showCancelPopup ? (
                <OfferCancelPopup
                    isOpen={showCancelPopup}
                    onSubmit={onCancelPopupSubmit}
                    onDismiss={() => setShowCancelPopup(false)}
                    isSubmitting={updateOfferMutation.isPending}
                    offerState={offer.state}
                />
            ) : null}
        </>
    );
};
