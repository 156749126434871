import { useNavigate, useSearch } from '@tanstack/react-router';

import { Text } from '../../cdl/Text/Text';
import { OverviewCustomerCompanySelect } from '../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { CustomerGroupSelect } from '../../common/form-elements/CustomerGroupSelect/CustomerGroupSelect';
import { SupplierGroupSelect } from '../../common/form-elements/SupplierGroupSelect/SupplierGroupSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { Page } from '../../common/Page/Page';
import { DebouncedSearchTextInput } from '../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../common/ui/Box';

import { InviteUserButton } from './components/InviteUserButton';
import { UserOverviewTable } from './components/UserOverviewTable';

export const UserOverviewPage = () => {
    const role = useRole();

    const search = useSearch({ from: '/_app/users' });
    const navigate = useNavigate({ from: '/users' });

    useDocumentTitle(translate('page.useroverview'));

    return (
        <Page>
            <Box display="grid" rowGap={5}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Text variant="headline" as="h1">
                        {translate('user.headline')}
                    </Text>
                    {role.hasAnyAdminRights() ? <InviteUserButton /> : null}
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Box display="flex" columnGap={4} alignItems="center">
                        <Box minWidth="250px">
                            <DebouncedSearchTextInput
                                placeholder={translate('user.searchPlaceholder')}
                                onChange={(searchQuery) => {
                                    navigate({
                                        search: (previousSearch) => ({ ...previousSearch, searchQuery, page: 0 }),
                                    });
                                }}
                                value={search.searchQuery}
                            />
                        </Box>

                        {role.isAdmin() ? (
                            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4} minWidth="600px">
                                <CustomerGroupSelect
                                    onChange={(customerGroupIds) =>
                                        navigate({
                                            search: (previousSearch) => ({
                                                ...previousSearch,
                                                customerGroupIds: customerGroupIds.map((id) => id),
                                                page: 0,
                                            }),
                                        })
                                    }
                                    value={search.customerGroupIds}
                                    placeholder={translate('customerGroupSelect.filterByCustomerGroups')}
                                    isMulti
                                    dropdownWidth="520px"
                                />

                                <SupplierGroupSelect
                                    onChange={(supplierGroupIds) => {
                                        if (Array.isArray(supplierGroupIds)) {
                                            navigate({
                                                search: (previousSearch) => ({
                                                    ...previousSearch,
                                                    supplierGroupIds: supplierGroupIds,
                                                    page: 0,
                                                }),
                                            });
                                        }
                                    }}
                                    value={search.supplierGroupIds}
                                    placeholder={translate('supplierGroupSelect.filterBySupplierGroups')}
                                    isMulti
                                    dropdownWidth="520px"
                                />
                            </Box>
                        ) : null}

                        {role.isCustomer() && !role.isOneCompanyUser() ? (
                            <OverviewCustomerCompanySelect
                                value={search.customerIds}
                                onChange={(customerIds) => {
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            customerIds,
                                            page: 0,
                                        }),
                                    });
                                }}
                            />
                        ) : null}

                        {role.isSupplier() && !role.isOneCompanyUser() ? (
                            <OverviewSupplierCompanySelect
                                value={search.supplierIds}
                                onChange={(supplierIds) => {
                                    navigate({
                                        search: (previousSearch) => ({
                                            ...previousSearch,
                                            supplierIds,
                                            page: 0,
                                        }),
                                    });
                                }}
                            />
                        ) : null}
                    </Box>
                </Box>

                <UserOverviewTable />
            </Box>
        </Page>
    );
};
