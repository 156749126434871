import { useSpring } from '@react-spring/web';
import { Children, ReactElement, ReactNode } from 'react';

import { Bar } from './components/Bar';
import { BarContainer } from './components/BarContainer';
import { InternalProgressBarMarker, InternalProgressBarMarkerProps } from './components/InternalProgressBarMarker';
import { MARKER_TARGET_WIDTH } from './util/MarkerTargetWidth';

export interface ProgressBarProps {
    value: number;
    minValue?: number;
    maxValue?: number;
    variant?: 'accent' | 'neutral' | 'attention' | 'negative';
    children?: ReactNode;
}

export const ProgressBar = ({
    value,
    minValue = 0,
    maxValue = 100,
    variant = 'accent',
    children,
}: ProgressBarProps) => {
    const fillPercentage = Math.min(((value - minValue) / (maxValue - minValue)) * 100, 100);

    const styles = useSpring({
        from: {
            width: '0%',
        },
        to: {
            width: `${fillPercentage}%`,
        },
    });

    const marker = Children.toArray(children)
        .filter((it): it is ReactElement => it !== null && it !== undefined && typeof it === 'object')
        .map((it) => it.props as InternalProgressBarMarkerProps);

    return (
        <BarContainer
            role="progressbar"
            aria-valuenow={value}
            aria-valuemin={minValue}
            aria-valuemax={maxValue}
            variant={variant}
        >
            {fillPercentage > 0 ? <Bar style={styles} variant={variant} /> : null}
            {marker.map((markerProps, index) => {
                const offsetPercentage = Math.min(((markerProps.value - minValue) / (maxValue - minValue)) * 100, 100);
                const offsetFromLeft = `calc(${offsetPercentage}% - ${MARKER_TARGET_WIDTH / 2}px`;

                return (
                    <InternalProgressBarMarker
                        key={index}
                        {...markerProps}
                        style={{ position: 'absolute', left: offsetFromLeft }}
                    />
                );
            })}
        </BarContainer>
    );
};

ProgressBar.Marker = InternalProgressBarMarker;
