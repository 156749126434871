import { Router } from '@tanstack/react-router';
import axios from 'axios';

import AppSettings from '../../../constants';

import { addAuthenticationInterceptors } from './interceptors/addAuthenticationInterceptors';

const authorizedAxiosInstance = axios.create({
    baseURL: AppSettings.routes.api,
});

export const initAuthorizedAxiosInstance = (router: Router<any, any, any>) => {
    addAuthenticationInterceptors(authorizedAxiosInstance, router);
};

export { authorizedAxiosInstance };
