import { useSearch } from '@tanstack/react-router';

import { CustomerStatisticsMessage } from '../../../types/StatisticsMessage';
import { useProductContext } from '../../common/hooks/useProductContext';

import { GeneralTabContent } from './GeneralTabContent';
import { PortsTabContent } from './PortsTabContent';
import { ProductGroupsTabContent } from './ProductGroupsTabContent';
import { ProductsTabContent } from './ProductsTabContent';
import { StatisticTab } from './StatisticTab';
import { SuppliersTabContent } from './SuppliersTabContent';
import { VesselsTabContent } from './VesselsTabContent';

interface CustomerStatisticsContentProps {
    statistics: CustomerStatisticsMessage;
}

interface CustomerStatisticsSearch {
    tab: StatisticTab;
}

export const CustomerStatisticsContent = ({ statistics }: CustomerStatisticsContentProps) => {
    const { isFuel } = useProductContext();

    const search: CustomerStatisticsSearch = useSearch({
        from: isFuel ? '/_app/fuel/statistics' : '/_app/_lubes/statistics',
    });

    switch (search.tab) {
        case StatisticTab.GENERAL:
            return <GeneralTabContent statistics={statistics} />;
        case StatisticTab.VESSELS:
            return <VesselsTabContent vesselStatistics={statistics.vessel} />;
        case StatisticTab.SUPPLIERS:
            return <SuppliersTabContent supplierStatistics={statistics.supplier} />;
        case StatisticTab.PORTS:
            return <PortsTabContent portStatistics={statistics.port} />;
        case StatisticTab.PRODUCTS:
            return <ProductsTabContent productStatistics={statistics.product} />;
        case StatisticTab.PRODUCT_GROUPS:
            return <ProductGroupsTabContent productGroupsStatistics={statistics.productGroup} />;
        case StatisticTab.CUSTOMERS:
            return null;
    }
};
