import { useCallback, useEffect, useState } from 'react';

import { AssignmentMessage } from '../../../../../types/AssignmentMessage';
import { SupplierMessage } from '../../../../../types/SupplierMessage';
import { ComboBox, ComboBoxProps, DefaultOption } from '../../../../cdl/ComboBox/ComboBox';
import { getAssignmentList } from '../../../../common/api/clients/gateway.api';
import { formatCompanyName } from '../../../../common/helpers/formatCompanyName.helper';
import { useRole } from '../../../../common/hooks/useRole';

export interface DefaultSupplierComboBoxProps extends Omit<ComboBoxProps<DefaultOption, false>, 'value'> {
    customerId: string;
    value: string;
}

export const DefaultSupplierComboBox = ({ customerId, value, ...rest }: DefaultSupplierComboBoxProps) => {
    const [selectedOption, setSelectedOption] = useState<DefaultOption | null>(null);
    const role = useRole();

    const loadAssignedSuppliers = useCallback(async (): Promise<DefaultOption[]> => {
        const response = await getAssignmentList({ customerIds: [customerId] });

        const suppliers = response
            .map((assignment: AssignmentMessage) => assignment.supplier)
            .filter((supplier?: SupplierMessage) => supplier !== undefined);

        return suppliers.map((supplier: SupplierMessage) => ({
            value: supplier.id,
            label: formatCompanyName({
                company: supplier,
                includeType: role.isAdmin(),
            })!,
        }));
    }, [customerId, role]);

    useEffect(() => {
        let isMounted = true;

        if (value) {
            loadAssignedSuppliers().then((options) => {
                const option = options.find((option) => option.value === value);

                if (isMounted && option) {
                    setSelectedOption(option);
                }
            });
        } else {
            setSelectedOption(null);
        }

        return () => {
            isMounted = false;
        };
    }, [value, loadAssignedSuppliers]);

    return (
        <ComboBox
            {...rest}
            value={selectedOption}
            loadOptions={loadAssignedSuppliers}
            isAsync
            rerenderValue={customerId}
        />
    );
};
