import { useNavigate, useSearch } from '@tanstack/react-router';

import { useProductContext } from '../../common/hooks/useProductContext';
import { TabSwitch } from '../../common/TabSwitch/TabSwitch';

import { StatisticTab } from './StatisticTab';

export const SupplierStatisticsTabSwitch = () => {
    const { isFuel } = useProductContext();

    const search = useSearch({
        from: isFuel ? '/_app/fuel/statistics' : '/_app/_lubes/statistics',
    });
    const navigate = useNavigate({ from: isFuel ? '/fuel/statistics' : '/statistics' });

    return (
        <TabSwitch
            selectedContext={search.tab}
            onSelect={(tab: StatisticTab) => {
                navigate({
                    search: (previousSearch) => ({
                        ...previousSearch,
                        tab,
                    }),
                });
            }}
            totals={{
                [StatisticTab.GENERAL]: null,
                [StatisticTab.CUSTOMERS]: null,
                [StatisticTab.PORTS]: null,
                [StatisticTab.PRODUCTS]: null,
                [StatisticTab.PRODUCT_GROUPS]: null,
            }}
        />
    );
};
