import { useQuery } from '@tanstack/react-query';

import { queries } from '../api/queryKeys/queries';

export const useVesselBudgetDetails = (vesselId) => {
    const { error, data, isPending, isError } = useQuery({
        ...queries.vessels.detail(vesselId)._ctx.budgets,
        retry: (_, error) => {
            return error.response?.status !== 404;
        },
    });

    if (isError) {
        if (error.response?.status === 404) {
            return {
                notFound: true,
                budgetData: data,
                isPending,
                isError,
            };
        }
        return { notFound: false, budgetData: data, isPending, isError };
    }

    return {
        notFound: false,
        budgetData: data,
        isPending,
        isError,
    };
};
