import { ComponentProps, ComponentPropsWithoutRef, ElementType, forwardRef, MouseEvent, ReactNode, Ref } from 'react';
import { WidthProps } from 'styled-system';

import { Spinner } from '../../common/Spinner/Spinner';

import { AbsoluteCenter } from './components/AbsoluteCenter';
import { ButtonChildrenWrapper } from './components/ButtonChildrenWrapper';
import { ButtonContentWrapper } from './components/ButtonContentWrapper';
import { StyledButton } from './components/StyledBaseButton';
import { getSpinnerSize } from './util/getSpinnerSize';

export interface ButtonProps extends ComponentPropsWithoutRef<'button'>, WidthProps {
    emphasis?: 'high' | 'medium' | 'low' | 'none';
    size?: 'small' | 'medium' | 'large';
    destructive?: boolean;
    isLoading?: boolean;
    leadingVisual?: ReactNode;
    trailingVisual?: ReactNode;
}

const BaseButton = forwardRef(
    (
        {
            emphasis = 'medium',
            size = 'medium',
            destructive = false,
            isLoading = false,
            leadingVisual,
            trailingVisual,
            onClick,
            children,
            ...rest
        }: ButtonProps,
        ref: Ref<HTMLButtonElement>
    ) => {
        const internalOnClick = (event: MouseEvent<HTMLButtonElement>) => {
            if (isLoading) {
                event.preventDefault();
                return;
            }

            onClick && onClick(event);
        };

        return (
            <StyledButton
                emphasis={emphasis}
                size={size}
                destructive={destructive}
                isLoading={isLoading}
                onClick={internalOnClick}
                ref={ref}
                {...rest}
            >
                <ButtonContentWrapper isLoading={isLoading}>
                    {leadingVisual}

                    <ButtonChildrenWrapper>{children}</ButtonChildrenWrapper>

                    {trailingVisual}
                </ButtonContentWrapper>
                {isLoading ? (
                    <AbsoluteCenter>
                        <Spinner color="currentColor" size={getSpinnerSize(size)} />
                    </AbsoluteCenter>
                ) : null}
            </StyledButton>
        );
    }
);

BaseButton.displayName = 'BaseButton';

interface ButtonComponentType {
    <C extends ElementType = 'button'>(
        props: ButtonProps &
            Omit<ComponentProps<C>, keyof ButtonProps> & {
                as?: C;
            }
    ): JSX.Element;

    displayName?: string;
}

export const Button = BaseButton as ButtonComponentType;
