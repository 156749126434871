import { Formik, FormikErrors } from 'formik';

import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { UserType } from '../../../../../types/UserType';
import { translate } from '../../../../common/helpers/translate.helper';

import { ApiKeyBaseForm, ApiKeyFormState } from './ApiKeyBaseForm';

interface ApiKeyFormProps {
    title: string;
    apiKey?: ApiKeyMessage;
    groupId: string;
    onSubmit: (values: ApiKeyFormState) => void;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export const ApiKeyForm = (props: ApiKeyFormProps) => {
    const initialFormikState: ApiKeyFormState = {
        name: props.apiKey?.name ?? '',
        companyIds: props.apiKey?.companyAccesses?.map((it) => it.companyId) ?? [],
        companyAccessLevel: props.apiKey?.companyAccesses[0]?.accessLevel,
    };

    const validate = (values: ApiKeyFormState) => {
        const errors: FormikErrors<ApiKeyFormState> = {};

        if (!values.name) {
            errors.name = translate('apikey.noNameError');
        }

        if (values.companyIds.length === 0) {
            errors.companyIds = translate('apikey.noCompanySelectedError');
        }

        if (!values.companyAccessLevel) {
            errors.companyAccessLevel = translate('apikey.noAccessLevelSelectedError');
        }

        return errors;
    };

    return (
        <Formik initialValues={initialFormikState} onSubmit={props.onSubmit} validate={validate} validateOnMount>
            <ApiKeyBaseForm groupId={props.groupId} title={props.title} userType={props.userType} />
        </Formik>
    );
};
