import { useNavigate } from '@tanstack/react-router';
import { Command } from 'cmdk';

import { SupplierGroupMessage } from '../../../../types/SupplierGroupMessage';
import { Text } from '../../../cdl/Text/Text';
import { useSupplierList } from '../../../group/supplier/detail/hooks/useSupplierList';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandItem } from './CommandItem';
import { CommandSkeleton } from './CommandSkeleton';
import { CommandSupplierCompanyDetail } from './CommandSupplierCompanyDetail';

interface GroupDetailProps {
    supplierGroup: SupplierGroupMessage;
}

export const CommandSupplierGroupDetail = (props: GroupDetailProps) => {
    const suppliersQuery = useSupplierList({ groupId: props.supplierGroup.id });
    const { setOpen } = useShortcutMenu();

    const navigate = useNavigate();

    const onGroupSelect = () => {
        navigate({ to: '/supplier-group/$id', params: { id: props.supplierGroup.id } });
        setOpen(false);
    };

    return (
        <Command.Group style={{ minWidth: 0 }}>
            <CommandItem
                key={`group-${props.supplierGroup.id}`}
                value={`group-${props.supplierGroup.id}`}
                onSelect={onGroupSelect}
            >
                <Text fontWeight="bold">{props.supplierGroup.name}</Text>
            </CommandItem>
            {suppliersQuery.isPending ? <CommandSkeleton /> : null}
            {suppliersQuery.data?.map((supplier) => (
                <CommandSupplierCompanyDetail
                    key={supplier.id}
                    value={`group-${props.supplierGroup.id}-${supplier.id}`}
                    supplier={supplier}
                    isChildren={true}
                />
            ))}
            <Command.Separator alwaysRender />
        </Command.Group>
    );
};
