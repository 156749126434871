import { Slot } from '@radix-ui/react-slot';
import { LabelHTMLAttributes, ReactNode, useContext } from 'react';
import styled from 'styled-components';

import { raise } from '../../common/helpers/raise';
import { LabelText } from '../../common/LabelText/LabelText';
import { Text } from '../Text/Text';

import { LabelContext } from './LabelContext';

const StyledLabel = styled(Text).attrs({
    as: 'label',
    variant: 'body',
})`
    cursor: pointer;
    margin: 0;
    grid-area: label;
`;

export const Legend = styled(LabelText).attrs({ as: 'legend' })`
    border: none;
    float: left; // make the legend element flow like any other block-level element
    margin: 0;
`;

const useLabelContext = () => useContext(LabelContext) || raise('no context for label found');

type LabelProps = {
    children: ReactNode;
    asChild?: boolean;
} & LabelHTMLAttributes<HTMLLabelElement>;

export const Label = ({ asChild, ...props }: LabelProps) => {
    const { id } = useLabelContext();
    const Comp = asChild ? Slot : StyledLabel;
    return <Comp htmlFor={id} {...props} />;
};
