import { useNavigate } from '@tanstack/react-router';

import { SupplierMessage } from '../../../../types/SupplierMessage';
import { IconCornerDownRight } from '../../icons/cdl/CornerDownRight';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandItem } from './CommandItem';

interface CompanyDetailProps {
    supplier: SupplierMessage;
    value: string;
    isChildren: boolean;
}

export const CommandSupplierCompanyDetail = (props: CompanyDetailProps) => {
    const { setOpen } = useShortcutMenu();
    const navigate = useNavigate();

    const onCompanySelect = (id: string) => {
        navigate({ to: '/supplier/detail/$id', params: { id } });
        setOpen(false);
    };

    return (
        <CommandItem
            value={props.value}
            onSelect={() => onCompanySelect(props.supplier.id)}
            Icon={props.isChildren ? IconCornerDownRight : undefined}
        >
            {props.supplier.name}
        </CommandItem>
    );
};
