import { useNavigate, useSearch } from '@tanstack/react-router';
import { useMemo } from 'react';
import styled from 'styled-components';

import { DateRangePicker } from '../../cdl/DateRangePicker/DateRangePicker';
import { OverviewCustomerCompanySelect } from '../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { generateStatisticsDateRangePresets } from '../../common/helpers/generateStatisticsDateRangePresets';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get';

const ActionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${get('space.4')}px;

    @media (max-width: ${get('breakpoints.2')}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const StatisticsActions = () => {
    const presets = useMemo(generateStatisticsDateRangePresets, []);
    const role = useRole();
    const { context, isFuel } = useProductContext();

    const search = useSearch({ from: isFuel ? '/_app/fuel/statistics' : '/_app/_lubes/statistics' });
    const navigate = useNavigate({ from: isFuel ? '/fuel/statistics' : '/statistics' });

    const onDateChange = (dates: Date[]) => {
        navigate({
            search: (previousSearch) => ({
                ...previousSearch,
                from: dates[0].getTime(),
                to: dates[1].getTime(),
            }),
        });
    };

    return (
        <ActionWrapper>
            {role.isAdmin() || (role.isCustomer() && !role.isOneCompanyUser(context)) ? (
                <OverviewCustomerCompanySelect
                    onChange={(customerIds) => {
                        navigate({
                            search: (previousSearch) => ({
                                ...previousSearch,
                                customerIds,
                            }),
                        });
                    }}
                    value={search.customerIds}
                    context={context}
                />
            ) : null}

            {role.isAdmin() || (role.isSupplier() && !role.isOneCompanyUser(context)) ? (
                <OverviewSupplierCompanySelect
                    onChange={(supplierIds) => {
                        navigate({
                            search: (previousSearch) => ({
                                ...previousSearch,
                                supplierIds,
                            }),
                        });
                    }}
                    value={search.supplierIds}
                    context={context}
                />
            ) : null}

            <Box minWidth="320px">
                <DateRangePicker
                    dates={[new Date(search.from), new Date(search.to)]}
                    onDatesChange={(dates) => onDateChange(dates)}
                    presets={presets}
                />
            </Box>
        </ActionWrapper>
    );
};
