import { useState } from 'react';

import { CounterOffer } from '../../../../../../types/CounterOffer';
import { CounterOfferState } from '../../../../../../types/CounterOfferState';
import { Button } from '../../../../../cdl/Button/Button';
import { Modal } from '../../../../../cdl/Modal/Modal';
import { ReadOnlyField } from '../../../../../cdl/ReadOnlyField/ReadOnlyField';
import { ReadOnlyTextarea } from '../../../../../cdl/ReadOnlyTextarea/ReadOnlyTextarea';
import { Text } from '../../../../../cdl/Text/Text';
import { DeleteConfirmPopup } from '../../../../../common/DeleteConfirmPopup/DeleteConfirmPopup';
import { formatFuelMoney } from '../../../../../common/helpers/formatFuelMoney.helper';
import { translate } from '../../../../../common/helpers/translate.helper';
import { translateSulphurContent } from '../../../../../common/helpers/translateSulphurContent';
import { LabelText } from '../../../../../common/LabelText/LabelText';
import { Table } from '../../../../../common/Table/Table';
import { Td } from '../../../../../common/Table/Td';
import { Tr } from '../../../../../common/Table/Tr';
import { Box } from '../../../../../common/ui/Box';
import { CircleValidity } from '../../../../../common/Validity/CircleValidity';
import { OfferModel } from '../../../../../offer/model/OfferModel';
import { useCounterOfferEdit } from '../hooks/useCounterOfferEdit';

interface CounterOfferReviewPopupProps {
    offer: OfferModel;
    counterOffer: CounterOffer;
    open: boolean;
    onDismiss: () => void;
}

export const CounterOfferReviewPopup = ({ offer, counterOffer, open, onDismiss }: CounterOfferReviewPopupProps) => {
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const counterOfferEdit = useCounterOfferEdit();

    const onSubmit = async () => {
        await counterOfferEdit.mutateAsync({
            counterOfferId: counterOffer.id,
            counterOffer: {
                ...counterOffer,
                state: CounterOfferState.CANCELED,
            },
        });

        setShowDeletePopup(false);
    };

    return (
        <Modal isOpen={open} onDismiss={onDismiss}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{translate('counterOffer.reviewCustomerPopup.headline')}</Text>

                {!!counterOffer.validUntil && !!counterOffer.validityTimeMinutes ? (
                    <Box>
                        <LabelText>{translate('counterOffer.reviewCustomerPopup.validityHeadline')}</LabelText>
                        <CircleValidity
                            validUntil={counterOffer.validUntil}
                            validityTime={counterOffer.validityTimeMinutes}
                        />
                    </Box>
                ) : null}

                <Table style={{ margin: 0 }}>
                    <thead>
                        <Tr inactive={false} focused={false} unread={false}>
                            <th>
                                <LabelText>{translate('counterOffer.form.productHeader')}</LabelText>
                            </th>
                            <th>
                                <LabelText>{translate('counterOffer.form.priceHeader')}</LabelText>
                            </th>
                            <th>
                                <LabelText>{translate('counterOffer.form.counterPriceHeader')}</LabelText>
                            </th>
                        </Tr>
                    </thead>
                    <tbody>
                        {counterOffer.items.map((counterOfferItem, index) => {
                            const product = offer.items[index].product!!;

                            const translatedSulphurContent = translateSulphurContent(product.sulphurContent);

                            return (
                                <Tr key={index} inactive={false} focused={false} unread={false}>
                                    <Td width="50%">
                                        <Text variant="body">{`${product.name} ${translatedSulphurContent}`}</Text>
                                    </Td>
                                    <Td width="25%">
                                        <Text variant="body">
                                            {counterOfferItem.price
                                                ? formatFuelMoney({
                                                      value: counterOfferItem.price.value,
                                                      currency: counterOfferItem.price.currency,
                                                  })
                                                : '-'}
                                        </Text>
                                    </Td>
                                    <Td width="25%">
                                        <ReadOnlyField
                                            value={
                                                counterOfferItem.counterPrice
                                                    ? formatFuelMoney({
                                                          value: counterOfferItem.counterPrice.value,
                                                          currency: counterOfferItem.counterPrice.currency,
                                                      })
                                                    : '-'
                                            }
                                        ></ReadOnlyField>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </tbody>
                </Table>

                <ReadOnlyTextarea
                    value={counterOffer.message}
                    label={translate('counterOffer.reviewCustomerPopup.messageLabel')}
                />

                <Box display="flex" justifyContent="end">
                    <Button onClick={() => setShowDeletePopup(true)}>{translate('counterOffer.cancelButton')}</Button>
                </Box>

                <DeleteConfirmPopup
                    isOpen={showDeletePopup}
                    onSubmit={onSubmit}
                    onDismiss={() => setShowDeletePopup(false)}
                    headline={translate('counterOffer.deleteConfirmPopup.headline')}
                    text={translate('counterOffer.deleteConfirmPopup.text')}
                    isSubmitting={counterOfferEdit.isPending}
                    deleteButtonText={translate('counterOffer.deleteConfirmPopup.delete')}
                />
            </Box>
        </Modal>
    );
};
