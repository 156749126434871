import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { AssignmentMessage } from '../../../../../types/AssignmentMessage';
import { Callout } from '../../../../cdl/Callout/Callout';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { IconAlertTriangle } from '../../../../common/icons/cdl/AlertTriangle';
import { Box } from '../../../../common/ui/Box';
import { useAssignmentList } from '../../../common/hooks/useAssignmentList';
import { usePortDetails } from '../../../fuel/hooks/usePortDetails';
import { sortSuppliersByName } from '../../../util/sortSuppliersByName';
import { useSpotAvailableAtPort } from '../hooks/useSpotAvailableAtPort';
import { useUpdateSupplierDefaults } from '../hooks/useUpdateSupplierDefaults';
import { FormikCreateEnquiryValues } from '../LubesCreateEnquiryFlow';

import { SuppliersSelect } from './SuppliersSelect';

interface FormikSupplierSelectProps {
    customerId?: string;
    portId?: string;
    defaultSupplierId?: string;
    directOrderAllowed?: boolean;
}

export const FormikSupplierSelect = ({
    customerId,
    portId,
    defaultSupplierId,
    directOrderAllowed,
}: FormikSupplierSelectProps) => {
    const { setFieldValue, setFieldTouched, values, errors, touched } = useFormikContext<FormikCreateEnquiryValues>();
    const portQuery = usePortDetails(portId);
    const spotAvailableAtPortQuery = useSpotAvailableAtPort(portQuery?.data?.country?.code, values.testOrder);
    const assignmentsQuery = useAssignmentList(customerId, portId, values.testOrder);
    const role = useRole();
    const isSpotAllowed = role.isSpotAllowed(customerId);

    const suppliers = assignmentsQuery.data
        ?.map((assignment: AssignmentMessage) => assignment.supplier)
        .filter((supplierMessage) => supplierMessage !== undefined);

    const sortedSuppliers = sortSuppliersByName(suppliers || [], defaultSupplierId);

    useUpdateSupplierDefaults(
        sortedSuppliers,
        defaultSupplierId,
        customerId,
        directOrderAllowed,
        values.receiverSupplierIds
    );

    useEffect(() => {
        // turn off spot if not available
        if (spotAvailableAtPortQuery.data?.available === false || (customerId && !isSpotAllowed)) {
            setFieldValue('spot', false);
        }
    }, [customerId, isSpotAllowed, setFieldValue, spotAvailableAtPortQuery.data?.available]);

    const emailSupplierNames = sortedSuppliers
        .filter((supplier) => supplier.supplierGroup.emailQuote && values.receiverSupplierIds?.includes(supplier.id))
        .map((supplier) => supplier.name)
        .join(', ');

    if (assignmentsQuery.isPending) {
        if (customerId) {
            return (
                <Box marginTop={4} display="flex" gap={6} flexWrap="wrap">
                    <Skeleton width={200} height={180} />
                    <Skeleton width={200} height={180} />
                    <Skeleton width={200} height={180} />
                    <Skeleton width={200} height={180} />
                    <Skeleton width={200} height={180} />
                </Box>
            );
        }

        return <Callout description={translate('order.missingvessel')} variant="accent" />;
    }

    if (assignmentsQuery.isError) {
        return <Callout description={translate('global.genericError')} variant="negative" />;
    }

    if (!sortedSuppliers.length && customerId && !isSpotAllowed) {
        return <Callout description={translate('order.nosuppliers')} variant="attention" />;
    }

    return (
        <>
            {emailSupplierNames ? (
                <Box marginTop={6}>
                    <Callout
                        variant="attention"
                        description={translate('order.emailQuoteSupplierWarning', {
                            supplier: emailSupplierNames,
                        })}
                    />
                </Box>
            ) : null}

            {touched?.receiverSupplierIds && errors?.receiverSupplierIds ? (
                <Callout
                    description={errors.receiverSupplierIds}
                    variant="negative"
                    leadingVisual={IconAlertTriangle}
                />
            ) : null}

            <SuppliersSelect
                availableSuppliers={sortedSuppliers}
                receiverSupplierIds={values.receiverSupplierIds || []}
                setReceiverSupplierIds={(newIds) => {
                    setFieldValue('receiverSupplierIds', newIds);
                    setFieldTouched('receiverSupplierIds', true, false);
                }}
                setSpot={(spotSelected) => {
                    if (!role.usesCloselinkLite()) {
                        setFieldValue('spot', spotSelected);
                    }
                }}
                directOrderAvailable={directOrderAllowed}
                spotAvailable={spotAvailableAtPortQuery.data?.available || true}
                spot={values.spot}
                customerId={customerId}
                defaultSupplierId={defaultSupplierId}
            />
        </>
    );
};
