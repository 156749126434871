import { useField, useFormikContext } from 'formik';

import { LubesProductGroup } from '../../../../../types/LubesProductGroup';
import { ProductContext } from '../../../../../types/ProductContext';
import { Text } from '../../../../cdl/Text/Text';
import { OfferModel } from '../../../../offer/model/OfferModel';
import { OfferState } from '../../../../offer/model/OfferState';
import { EnumOptionSelectOption } from '../../../enums/EnumOptionSelect';
import { FormikEnumOptionSelect } from '../../../form-elements/formik/FormikEnumOptionSelect';
import { FormikHumanReadableEnumValue } from '../../../form-elements/formik/FormikHumanReadableEnumValue';
import { useRole } from '../../../hooks/useRole';
import { ItemModel, Unit } from '../../../models/ItemModel';

interface UnitDisplayProps {
    name: string;
    editable: boolean;
}

export const UnitDisplay = (props: UnitDisplayProps) => {
    const { values: offer } = useFormikContext<OfferModel>();
    const role = useRole();
    const [field] = useField(props.name);
    const item: ItemModel = field.value;

    if (!props.editable || (role.isCustomer() && offer.state !== OfferState.DELIVERED)) {
        return (
            <Text variant="body">
                <FormikHumanReadableEnumValue name={`${props.name}.unit`} enumType="productUnit" />
            </Text>
        );
    }

    const filterUnitTypes = (option: EnumOptionSelectOption) => {
        if ([LubesProductGroup.SAMPLE_ANALYSIS, LubesProductGroup.TEST_KIT].includes(item.product?.group)) {
            return option.value === Unit.PIECE;
        }

        return option.value !== Unit.PIECE;
    };

    return (
        <FormikEnumOptionSelect
            name={`${props.name}.unit`}
            enumType="productUnit"
            context={ProductContext.LUBES}
            disabled={item.deleted}
            filter={filterUnitTypes}
        />
    );
};
