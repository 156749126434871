import FileDownload from 'js-file-download';
import moment from 'moment';
import { useMemo, MouseEvent, useState } from 'react';

import { Button } from '../../../../cdl/Button/Button';
import { DateRangePicker } from '../../../../cdl/DateRangePicker/DateRangePicker';
import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { extractFileName } from '../../../../common/helpers/extractFileName.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconDownload } from '../../../../common/icons/cdl/Download';
import { Box } from '../../../../common/ui/Box';
import { generateCustomerReportDatePresets } from '../helpers/generateCustomerReportDatePresets';
import { useLubesCustomerReportDownload } from '../hooks/useLubesCustomerReportDownload';

interface LubesReportButtonProps {
    customerGroupId?: string;
}

export const DownloadLubesReportButton = (props: LubesReportButtonProps) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [dates, setDates] = useState<Date[]>([]);
    const { addErrorToast } = useToasts();
    const downloadCustomerReportMutation = useLubesCustomerReportDownload();

    const handlePopupOpenClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setPopupOpen(true);
    };

    const onDownloadButtonClick = async () => {
        if (dates.length !== 2) {
            return;
        }

        const startDate = moment(dates[0]).startOf('day').utc(true).toISOString();
        const endDate = moment(dates[1]).endOf('day').utc(true).toISOString();

        downloadCustomerReportMutation.mutateAsync(
            {
                customerGroupId: props.customerGroupId,
                dateDeliveryFrom: startDate,
                dateDeliveryTo: endDate,
            },
            {
                onSuccess: (response) => {
                    FileDownload(
                        response.data,
                        extractFileName(response.headers['content-disposition'] || '', 'LubesCustomerReport'),
                        'application/vnd.ms-excel'
                    );
                    setPopupOpen(false);
                },
                onError: () => {
                    addErrorToast(translate('customergroup.lubesCustomerReport.errorToast'));
                    setPopupOpen(false);
                },
            }
        );
    };

    const presets = useMemo(generateCustomerReportDatePresets, []);

    return (
        <>
            <Button onClick={handlePopupOpenClick} leadingVisual={<IconDownload width={16} height={16} />}>
                {translate('customergroup.lubesCustomerReport.button')}
            </Button>

            <Modal isOpen={popupOpen} onDismiss={() => setPopupOpen(false)}>
                <Box padding={6} display="grid" rowGap={5}>
                    <Text variant="title">{translate('customergroup.lubesCustomerReport.title')}</Text>

                    <Text variant="small" color="foreground.muted">
                        {translate('customergroup.lubesCustomerReport.description')}
                    </Text>

                    <DateRangePicker presets={presets} dates={dates} onDatesChange={setDates} />

                    <Button
                        size="large"
                        emphasis="high"
                        width="100%"
                        onClick={onDownloadButtonClick}
                        isLoading={downloadCustomerReportMutation.isPending}
                    >
                        {translate('customergroup.lubesCustomerReport.downloadButton')}
                    </Button>
                </Box>
            </Modal>
        </>
    );
};
