import { linkOptions, useLocation, useNavigate, useSearch } from '@tanstack/react-router';

import { FuelOrderTabs } from '../../../../types/fuelOrderOverviewSearchSchema';
import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate as t, translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { IconList } from '../../../common/icons/cdl/List';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../../common/TabSwitch/TabSwitch';
import { Box } from '../../../common/ui/Box';
import { getTableValidity } from '../../../common/Validity/getTableValidity';
import { Port } from '../../../port/Port';
import { UnreadIndicator } from '../../common/UnreadIndicator';
import { OrderModel } from '../../model/OrderModel';
import { useOrderPagination } from '../../useOrderPagination';
import { OrderActions } from '../actions/OrderActions';
import { OfferCount } from '../lubes/OfferCount';
import { orderTabOfferStateFilter, orderTabStateFilter } from '../orderTabsHelper';
import { useOrderPaginationTotals } from '../useOrderPaginationTotals';

import { selectSortOption } from './utils/selectSortOption';

export const FuelOrderOverviewTable = () => {
    const role = useRole();
    const { context } = useProductContext();

    const search = useSearch({ from: '/_app/fuel/orders' });
    const navigate = useNavigate({ from: '/fuel/orders' });
    const location = useLocation();

    const handleCustomerIds = (customerIds: string[]) => {
        if (customerIds.length) {
            return customerIds;
        }

        if (role.isAdmin()) {
            return undefined;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const ordersTotalsQuery = useOrderPaginationTotals({
        searchQuery: search.searchQuery,
        customerIds: handleCustomerIds(search.customerIds),
        supplierIds: search.supplierIds,
        types: [ProductContext.FUEL],
    });
    const ordersTotals = ordersTotalsQuery.data ?? {};

    const ordersPaginationQuery = useOrderPagination({
        page: search.page,
        searchQuery: search.searchQuery,
        customerIds: handleCustomerIds(search.customerIds),
        supplierIds: search.supplierIds,
        types: [ProductContext.FUEL],
        offerStates: orderTabOfferStateFilter[search.tab as keyof typeof orderTabOfferStateFilter],
        states: orderTabStateFilter[search.tab as keyof typeof orderTabStateFilter],
        sortField: search.sortField,
        sortDirection: search.sortDirection,
    });

    const onTabChange = (tab: FuelOrderTabs) => {
        navigate({
            search: (previousSearch) => ({
                ...previousSearch,
                sortField: selectSortOption(tab).sortField,
                sortDirection: selectSortOption(tab).sortDirection,
                tab,
                page: 0,
            }),
        });
    };

    if (ordersPaginationQuery.isPending) {
        return (
            <div>
                <TabSwitch selectedContext={search.tab} onSelect={onTabChange} totals={ordersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (ordersPaginationQuery.isError) {
        return (
            <div>
                <TabSwitch selectedContext={search.tab} onSelect={onTabChange} totals={ordersTotals} />
                <Error />
            </div>
        );
    }

    if (!ordersPaginationQuery.data.content.length) {
        return (
            <div>
                <TabSwitch selectedContext={search.tab} onSelect={onTabChange} totals={ordersTotals} />
                <TableEmptyState Icon={IconList} text={t('order.emptylist')} />
            </div>
        );
    }

    const buildRowLink = (order: OrderModel) => {
        if (order.isClosed() && order.chosenOfferId) {
            return linkOptions({
                to: '/fuel/offer/$id',
                params: {
                    id: order.chosenOfferId,
                },
            });
        } else if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
            return linkOptions({
                to: '/fuel/create',
                search: {
                    orderId: order.id,
                },
                state: {
                    originLocation: location,
                },
            });
        } else {
            return linkOptions({
                to: '/fuel/order/$id',
                params: {
                    id: order.id,
                },
            });
        }
    };

    return (
        <div>
            <TabSwitch selectedContext={search.tab} onSelect={onTabChange} totals={ordersTotals} />
            <TableBuilder<OrderModel>
                data={ordersPaginationQuery.data.content}
                rowLink={(order) => buildRowLink(order)}
                isLoading={ordersPaginationQuery.isPlaceholderData && ordersPaginationQuery.isFetching}
                grow={false}
            >
                {role.isCustomer() ? (
                    <TableBuilderColumn<OrderModel> header="" width="1%" hideOnLoading>
                        {(order) => <Box width="8px">{!order.read ? <UnreadIndicator /> : null}</Box>}
                    </TableBuilderColumn>
                ) : null}

                <TableBuilderColumn<OrderModel> header={translate('order.buyerreferenceshort')} width="10%" truncate>
                    {(order) => order.buyerReference || '-'}
                </TableBuilderColumn>

                {!role.isOneCompanyUser(ProductContext.FUEL) ? (
                    <TableBuilderColumn<OrderModel>
                        header={role.isCustomer() ? translate('order.company') : translate('order.customer')}
                        width="15%"
                        truncate
                    >
                        {(order) => formatCompanyName({ company: order.customer })}
                    </TableBuilderColumn>
                ) : null}

                <TableBuilderColumn<OrderModel> header={translate('order.vessel')} width="10%" truncate>
                    {(order) => formatVessel({ vessel: order.vessel, short: true })}
                </TableBuilderColumn>

                <TableBuilderColumn<OrderModel> header={translate('order.port')} width="15%">
                    {(order) =>
                        order.port ? (
                            <Port port={order.port} vesselId={order.vesselId} locode={false} showTooltip />
                        ) : null
                    }
                </TableBuilderColumn>

                {['ordered', 'delivered', 'canceled'].includes(search.tab) ? (
                    <TableBuilderColumn<OrderModel> header={translate('order.dateDeliveryShort')} width="10%">
                        {(order) =>
                            order.dateDelivery ? formatDate({ date: order.dateDelivery, timeZone: 'UTC' }) : null
                        }
                    </TableBuilderColumn>
                ) : null}

                {['drafted', 'enquired'].includes(search.tab) ? (
                    <TableBuilderColumn<OrderModel> header={translate('order.eta')} width="10%">
                        {(order) =>
                            order.eta ? formatDateTime({ date: order.eta, timeZoneId: order.port?.timeZoneId }) : null
                        }
                    </TableBuilderColumn>
                ) : null}

                {['ordered', 'delivered', 'canceled'].includes(search.tab) ? (
                    <TableBuilderColumn<OrderModel> header={translate('order.supplier')} width="10%" truncate>
                        {(order) => formatCompanyName({ company: order.supplier }) || null}
                    </TableBuilderColumn>
                ) : null}

                {['enquired', 'ordered', 'canceled'].includes(search.tab) ? (
                    <TableBuilderColumn<OrderModel> header={translate('order.status')} width="5%">
                        {(order) => {
                            if (!order.offerState) {
                                return null;
                            }
                            return <OfferStatusTag state={order.offerState} variant="customer" />;
                        }}
                    </TableBuilderColumn>
                ) : null}

                {'enquired' === search.tab ? (
                    <TableBuilderColumn<OrderModel> header={translate('order.offercount')} width="5%">
                        {(order) => (
                            <OfferCount
                                offerCount={order.offerCount}
                                totalOffers={order.receiverSupplierIds.length}
                                includesSpot={order.spot}
                            />
                        )}
                    </TableBuilderColumn>
                ) : null}

                {'enquired' === search.tab ? (
                    <TableBuilderColumn<OrderModel> header={translate('order.enquiryValidityBy')} width="10%">
                        {(order) => getTableValidity(order.validUntil)}
                    </TableBuilderColumn>
                ) : null}

                {!(role.isAdmin() && 'drafted' !== search.tab) ? (
                    <TableBuilderColumn<OrderModel> header="" width="5%" interactive hideOnLoading>
                        {(order) => (
                            <Box display="flex" justifyContent="flex-end" width="100%">
                                <OrderActions order={order} />
                            </Box>
                        )}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>
            <CenteredPagination
                pageTotal={ordersPaginationQuery.pageTotal}
                currentPage={search.page}
                onPageChange={(page) => navigate({ search: (previousSearch) => ({ ...previousSearch, page }) })}
            />
        </div>
    );
};
