import { ApiKeyMessage } from '../../../../../types/ApiKeyMessage';
import { UserType } from '../../../../../types/UserType';
import { useDrawer } from '../../../../cdl/Drawer/useDrawer.hook';
import { IconButton } from '../../../../cdl/IconButton/IconButton';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconPencil } from '../../../../common/icons/cdl/Pencil';
import { useApiKeyUpdate } from '../hooks/useApiKeyUpdate';

import { ApiKeyFormState } from './ApiKeyBaseForm';
import { ApiKeyForm } from './ApiKeyForm';

interface ApiKeyActionsProps {
    apiKey: ApiKeyMessage;
    groupId: string;
    userType: UserType.CUSTOMER | UserType.SUPPLIER;
}

export const EditApiKeyButton = (props: ApiKeyActionsProps) => {
    const { push, pop } = useDrawer();
    const apiKeyUpdateMutation = useApiKeyUpdate();

    const onSubmit = async (values: ApiKeyFormState) => {
        const companyAccesses = values.companyIds.map((companyId) => {
            return {
                companyId: companyId,
                accessLevel: values.companyAccessLevel!,
            };
        });

        await apiKeyUpdateMutation.mutateAsync({
            id: props.apiKey.id,
            apiKeyMessage: {
                ...props.apiKey,
                name: values.name,
                companyAccesses,
            },
        });

        pop();
    };

    const onEditButtonClick = () => {
        push({
            content: (
                <ApiKeyForm
                    apiKey={props.apiKey}
                    onSubmit={onSubmit}
                    groupId={props.groupId}
                    title={translate('apikey.updatetitle')}
                    userType={props.userType}
                />
            ),
        });
    };

    return <IconButton emphasis="transparent" Icon={IconPencil} onClick={onEditButtonClick} />;
};
