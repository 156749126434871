import { ComponentPropsWithoutRef, FocusEventHandler, useState } from 'react';
import styled, { css } from 'styled-components';

import { get } from '../../common/ui/get';
import { Caption, CaptionProps } from '../Caption/Caption';
import { InputLabelText } from '../Input/components/InputLabelText';

export interface TextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
    /**
     * Define a label shown above the textarea
     */
    label?: string;
    caption?: string;
    CaptionIcon?: CaptionProps['Icon'];
    negative?: boolean;
}

const TextAreaLabel = styled.label`
    color: ${get('colors.foreground.muted')};
    display: inline-flex;
    flex-direction: column;
    width: 100%;

    // required to override global styles
    margin-bottom: 0;
    font-weight: 500;
`;

interface BaseTextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
    negative?: boolean;
}

const BaseTextArea = styled.textarea<BaseTextAreaProps>`
    resize: none;
    font-size: 16px;
    background-color: ${(props) => (props.disabled ? get('colors.background.subtle') : get('colors.background.inset'))};
    color: ${(props) => (props.disabled ? get('colors.foreground.disabled') : get('colors.foreground.default'))};
    border-radius: ${get('radii.1')}px;
    width: 100%;
    line-height: 24px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    transition:
        border 75ms,
        background 75ms;
    border: 2px solid transparent;

    &:focus {
        outline: none;
        border-color: ${get('colors.accent.emphasis')};
        background-color: ${get('colors.accent.background')};
    }

    ${(props) =>
        props.negative
            ? css`
                  background-color: ${get('colors.negative.background')};
                  border-color: ${get('colors.negative.border')};
              `
            : null}

    &::placeholder {
        color: ${get('colors.foreground.subtle')};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 60px transparent inset !important;
        background-color: transparent !important;
        background-clip: content-box !important;
    }
`;

export const TextArea = ({ label, caption, CaptionIcon, disabled, negative, ...rest }: TextAreaProps) => {
    const [isInputFocused, setIsInputFocused] = useState(false);

    const handleFocus: FocusEventHandler<HTMLTextAreaElement> = (event) => {
        setIsInputFocused(true);
        rest.onFocus && rest.onFocus(event);
    };

    const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
        setIsInputFocused(false);
        rest.onBlur && rest.onBlur(event);
    };

    return (
        <TextAreaLabel>
            {label ? (
                <InputLabelText disabled={disabled} $isFocused={isInputFocused}>
                    {label}
                </InputLabelText>
            ) : null}
            <BaseTextArea {...rest} onFocus={handleFocus} onBlur={handleBlur} disabled={disabled} negative={negative} />
            {caption ? (
                <Caption $negative={negative} Icon={CaptionIcon}>
                    {caption}
                </Caption>
            ) : null}
        </TextAreaLabel>
    );
};
