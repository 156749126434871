import { createFileRoute, redirect } from '@tanstack/react-router';

import { AblyService } from '../common/services/AblyService';

export const Route = createFileRoute('/logout')({
    loader() {
        AblyService.disconnect();

        throw redirect({
            to: '/login',
            // replacing history in order to prevent back-button to contain
            // potentially confidential data
            replace: true,
        });
    },
});
