import { useField } from 'formik';

import { LubesProductGroup } from '../../../../../types/LubesProductGroup';
import { ProductContext } from '../../../../../types/ProductContext';
import { Text } from '../../../../cdl/Text/Text';
import { EnumOptionSelectOption } from '../../../enums/EnumOptionSelect';
import { FormikEnumOptionSelect } from '../../../form-elements/formik/FormikEnumOptionSelect';
import { FormikHumanReadableEnumValue } from '../../../form-elements/formik/FormikHumanReadableEnumValue';
import { useEnums } from '../../../hooks/useEnums';
import { ItemModel, PackType } from '../../../models/ItemModel';

interface PackTypeDisplayProps {
    name: string;
    editable: boolean;
}

export const PackTypeDisplay = (props: PackTypeDisplayProps) => {
    const { getEnumField } = useEnums();

    const [field] = useField(props.name);
    const [, , unitSizeHelper] = useField(`${props.name}.unitSize`);
    const [, , unitsHelper] = useField(`${props.name}.units`);

    const item: ItemModel = field.value;

    if (!props.editable) {
        return (
            <Text variant="body">
                <FormikHumanReadableEnumValue name={`${props.name}.packType`} enumType="packType" />
            </Text>
        );
    }

    const handlePackTypeChange = (packType?: string) => {
        if (!packType) {
            return;
        }

        const defaultUnitSize = getEnumField('packType', packType, 'defaultUnitSize');
        unitSizeHelper.setValue(defaultUnitSize);

        if (!item.volume) {
            return;
        }

        unitsHelper.setValue(Math.ceil(item.volume / defaultUnitSize));
    };

    const filterPackTypes = (option: EnumOptionSelectOption) => {
        const isBottleOrPack = [PackType.BOTTLE, PackType.PACK].includes(option.value as PackType);

        if ([LubesProductGroup.SAMPLE_ANALYSIS, LubesProductGroup.TEST_KIT].includes(item.product?.group)) {
            return isBottleOrPack;
        }

        return !isBottleOrPack;
    };

    return (
        <FormikEnumOptionSelect
            name={`${props.name}.packType`}
            enumType="packType"
            context={ProductContext.LUBES}
            disabled={item.deleted}
            filter={filterPackTypes}
            onOptionChange={handlePackTypeChange}
        />
    );
};
