import { CommandItem as _CommandItem } from 'cmdk';
import { ComponentType, ReactNode } from 'react';
import styled from 'styled-components';

import { Text } from '../../../cdl/Text/Text';
import { get } from '../../ui/get';

interface StyledCommandItemProps extends CommandItemProps {
    destructive?: boolean;
}

const StyledCommandItem = styled(_CommandItem)<StyledCommandItemProps>`
    content-visibility: auto;
    cursor: pointer;

    min-width: 0;

    display: flex;
    align-items: center;
    gap: 12px;
    padding: ${get('space.4')}px 12px;
    color: ${get('colors.foreground.default')};
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;
    border-radius: ${get('radii.1')}px;

    &[data-selected='true'] {
        background: ${(props) =>
            props.destructive ? get('colors.negative.background') : get('colors.accent.background')};
        color: ${(props) => (props.destructive ? get('colors.negative.emphasis') : get('colors.accent.emphasis'))};

        svg {
            color: ${(props) => (props.destructive ? get('colors.negative.emphasis') : get('colors.accent.emphasis'))};
        }
    }

    svg {
        color: ${get('colors.foreground.default')};
    }
`;

interface CommandItemProps {
    Icon?: ComponentType<{ width?: number; height?: number }>;
    children: ReactNode;
    onSelect?: (value: string) => void;
    value?: string;
    destructive?: boolean;
}

export const CommandItem = ({ Icon, children, onSelect, ...rest }: CommandItemProps) => {
    return (
        <StyledCommandItem onSelect={onSelect} {...rest}>
            {Icon ? <Icon width={16} height={16} /> : null}
            <Text
                variant="small"
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {children}
            </Text>
        </StyledCommandItem>
    );
};
