import { AdditionalCosts } from '../../../types/GlobalPricesMessage';
import { Text } from '../../cdl/Text/Text';
import { formatFuelMoney } from '../../common/helpers/formatFuelMoney.helper';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';

import { GlobalPricesPortInformationLabel } from './GlobalPricesPortInformationLabel';

interface GlobalPricesPortAdditionalCostsProps {
    additionalCosts: AdditionalCosts;
}

export const GlobalPricesPortAdditionalCosts = ({ additionalCosts }: GlobalPricesPortAdditionalCostsProps) => {
    return (
        <Box display="grid" gridTemplateColumns="min-content 1fr" columnGap={3}>
            {additionalCosts.bargingCostPerMt ? (
                <>
                    <GlobalPricesPortInformationLabel>
                        {translate('globalPrices.marker.bargingCostLabel')}
                    </GlobalPricesPortInformationLabel>

                    <Text>
                        {translate('globalPrices.marker.bargingCost', {
                            money: formatFuelMoney({
                                value: additionalCosts.bargingCostPerMt.value,
                                currency: additionalCosts.bargingCostPerMt.currency,
                            }),
                        })}
                    </Text>
                </>
            ) : null}

            {additionalCosts.minBargeCharge ? (
                <>
                    <GlobalPricesPortInformationLabel>
                        {translate('globalPrices.marker.minBargeChargeLabel')}
                    </GlobalPricesPortInformationLabel>

                    <Text>
                        {formatFuelMoney({
                            value: additionalCosts.minBargeCharge.value,
                            currency: additionalCosts.minBargeCharge.currency,
                        })}
                    </Text>
                </>
            ) : null}

            {additionalCosts.fuelSurchargePercent ? (
                <>
                    <GlobalPricesPortInformationLabel>
                        {translate('globalPrices.marker.fuelSurchargeLabel')}
                    </GlobalPricesPortInformationLabel>

                    <Text>
                        {translate('globalPrices.marker.fuelSurcharge', {
                            percent: (additionalCosts.fuelSurchargePercent * 100).toString(),
                        })}
                    </Text>
                </>
            ) : null}
        </Box>
    );
};
