import { Fragment } from 'react';
import styled from 'styled-components';

import { translate } from '../helpers/translate.helper';
import { useEnums } from '../hooks/useEnums';
import { TooltipLarge } from '../TooltipLarge/TooltipLarge';
import { Box } from '../ui/Box';
import { theme } from '../ui/theme';

import { usePortDirectoryDetails } from './usePortDirectory';

const t = (k) => translate(`portdirectory.${k}`);

const Icon = ({ name }) => <i className={`fas fa-${name}`} />;

const Pill = styled(Box).attrs((props) => ({
    display: 'inline-block',
    margin: 1,
    ...props,
}))`
    i + i {
        margin-left: 0.5ch;
    }

    cursor: pointer;

    color: ${theme.colors['dark-blue'][4]};
    background-color: transparent;

    padding: 1px 5px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;

    border: 1px solid ${theme.colors['dark-blue'][4]};

    &:hover {
        background-color: ${theme.colors['dark-blue'][4]};
        color: #fff;
    }
`;

const Attribute = ({ tooltip, icon, children }) => {
    if (!tooltip) {
        return (
            <Pill>
                {icon ? <Icon name={icon} /> : null}
                {children}
            </Pill>
        );
    }

    return (
        <TooltipLarge title={tooltip.title} text={tooltip.text}>
            <Pill>
                {icon ? <Icon name={icon} /> : null}
                {children}
            </Pill>
        </TooltipLarge>
    );
};

const PortType = ({ portDirectory }) => {
    const { portType } = portDirectory;
    if (!portType) return null;

    const name = t(`productrange.${portType}.name`);

    return (
        <Attribute
            tooltip={{
                title: name,
                text: t(`productrange.${portType}.description`),
            }}
        >
            {name}
        </Attribute>
    );
};

const PortNoticeDays = ({ portDirectory }) => {
    const { noticeDays, bulkNotice } = portDirectory;
    if (!noticeDays) return null;

    const tooltipText = (
        <Fragment>
            {t('generalnotice')} {noticeDays} {t('workingdays')}
            {bulkNotice ? (
                <Fragment>
                    <br />
                    {[t('bulknotice'), bulkNotice, t('workingdays')].join(' ')}
                </Fragment>
            ) : null}
        </Fragment>
    );

    return (
        <Attribute
            icon="calendar-alt"
            tooltip={{
                title: t('noticerequired'),
                text: tooltipText,
            }}
        >
            {' '}
            {noticeDays}
        </Attribute>
    );
};

const PortDeliveryType = ({ portDirectory }) => {
    const {
        bulkAvailable,
        bulkMin,
        bulkMinote,
        ibcsAvailable,
        ibcsMin,
        ibcsMinNote,
        drumsPumpedAvailable,
        drumsPumpedMin,
        drumsPumpedMinNote,
        packedMin,
        packedMinNote,
    } = portDirectory;

    const text = [];
    const { getHumanReadableValue } = useEnums();

    if (bulkAvailable && (bulkMin || bulkMinote)) {
        // bulkMinote is a legacy typo and the correct naming
        text.push(
            <Fragment key="bulkmin">
                {t('bulkmin')}
                {bulkMin ? bulkMin : getHumanReadableValue('portDirectoryMinNotes', bulkMinote)}
                <br />
            </Fragment>
        );
    }

    if (ibcsAvailable && (ibcsMin || ibcsMinNote)) {
        text.push(
            <Fragment key="ibcs">
                {t('ibcsmin')}
                {ibcsMin ? ibcsMin : getHumanReadableValue('portDirectoryMinNotes', ibcsMinNote)}
                <br />
            </Fragment>
        );
    }

    if (drumsPumpedAvailable && (drumsPumpedMin || drumsPumpedMinNote)) {
        text.push(
            <Fragment key="drums">
                {t('drumspumpedmin')}
                {drumsPumpedMin ? drumsPumpedMin : getHumanReadableValue('portDirectoryMinNotes', drumsPumpedMinNote)}
                <br />
            </Fragment>
        );
    }

    if (packedMin || packedMinNote) {
        text.push(
            <Fragment key="packed">
                {t('packedmin')}
                {packedMin ? packedMin : getHumanReadableValue('portDirectoryMinNotes', packedMinNote)}
                <br />
            </Fragment>
        );
    }

    const characters = [
        bulkAvailable ? 'B' : null,
        ibcsAvailable ? 'T' : null,
        drumsPumpedAvailable ? 'DP' : null,
        'P',
    ].join(' ');

    return (
        <Attribute
            tooltip={{
                title: t('deliverytype'),
                text,
            }}
        >
            {characters}
        </Attribute>
    );
};

const PortDeliveryMode = ({ portDirectory }) => {
    const { bargeAvailable, bargeMin, bargeMinNote, truckAvailable, truckMin, truckMinNote } = portDirectory;
    const { getHumanReadableValue } = useEnums();
    const text = [];

    if (!bargeAvailable && !truckAvailable) return null;

    if (bargeAvailable && (bargeMin || bargeMinNote)) {
        text.push(
            <Fragment>
                {t('bargemin')}
                {bargeMin ? bargeMin : getHumanReadableValue('portDirectoryMinNotes', bargeMinNote)}
                <br />
            </Fragment>
        );
    }

    if (truckAvailable && (truckMin || truckMinNote)) {
        text.push(
            <Fragment>
                {t('truckmin')}
                {truckMin ? truckMin : getHumanReadableValue('portDirectoryMinNotes', truckMinNote)}
                <br />
            </Fragment>
        );
    }

    return (
        <Attribute
            tooltip={{
                title: t('deliverymode'),
                text,
            }}
        >
            {bargeAvailable ? <Icon name="ship" /> : null}
            {truckAvailable ? <Icon name="truck" /> : null}
        </Attribute>
    );
};

const PortNotesAndCharges = ({ portDirectory }) => {
    const { notes, charges } = portDirectory;
    if (!notes && !charges) return null;

    return (
        <Attribute
            tooltip={{
                title: t('notesandcharges.title'),
                text: (
                    <Fragment>
                        {notes ? (
                            <Fragment>
                                {notes}
                                <br />
                            </Fragment>
                        ) : null}
                        {charges}
                    </Fragment>
                ),
            }}
        >
            <Icon name="file-alt" />
            <Icon name="dollar-sign" />
        </Attribute>
    );
};

export const PortDirectory = ({ portId, supplierGroupId }) => {
    const { data, isPending, isError } = usePortDirectoryDetails({
        portId,
        supplierGroupId,
    });

    if (isPending || isError) {
        return null;
    }

    return (
        <Box>
            <PortType portDirectory={data} />
            <PortNoticeDays portDirectory={data} />
            <PortNotesAndCharges portDirectory={data} />
            <PortDeliveryMode portDirectory={data} />
            <PortDeliveryType portDirectory={data} />
        </Box>
    );
};
