import React, { createContext, SetStateAction } from 'react';

import { CommandListNestedRouterPath } from './commandRoutes';

interface ShortcutMenuContextType {
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    pages: CommandListNestedRouterPath[];
    setPages: React.Dispatch<SetStateAction<CommandListNestedRouterPath[]>>;
}

export const ShortcutMenuContext = createContext<ShortcutMenuContextType>({} as ShortcutMenuContextType);
