import { ComponentType } from 'react';

import { Role } from '../../../../types/Role';
import { translate } from '../../helpers/translate.helper';

import { AgentSearch } from './AgentSearch';
import { CustomerGroupSearch } from './CustomerGroupSearch';
import { Dashboard } from './Dashboard';
import { Logout } from './Logout';
import { OfferSearch } from './OfferSearch';
import { OrderSearch } from './OrderSearch';
import { PortSearch } from './PortSearch';
import { SupplierGroupSearch } from './SupplierGroupSearch';
import { UserSearch } from './UserSearch';
import { VesselSearch } from './VesselSearch';

export const CommandListNestedRouterPathEnum = {
    ORDER: 'order',
    OFFER: 'offer',
    VESSEL: 'vessel',
    CUSTOMER: 'customer',
    SUPPLIER: 'supplier',
    AGENT: 'agent',
    PORT: 'port',
    USER: 'user',
} as const;

const CommandListDirectRouterPathsEnum = {
    DASHBOARD: 'dashboard',
    LOGOUT: 'logout',
} as const;

export const CommandListRouterTypeEnum = {
    NESTED: 'nested',
    DIRECT: 'direct',
} as const;

export type CommandListNestedRouterPath =
    (typeof CommandListNestedRouterPathEnum)[keyof typeof CommandListNestedRouterPathEnum];
type CommandListDirectRouterPath =
    (typeof CommandListDirectRouterPathsEnum)[keyof typeof CommandListDirectRouterPathsEnum];

type CommandListRouterPath = CommandListNestedRouterPath | CommandListDirectRouterPath;
type CommandListRouterType = (typeof CommandListRouterTypeEnum)[keyof typeof CommandListRouterTypeEnum];

interface RouteConfig {
    type: CommandListRouterType;
    path: CommandListRouterPath;
    component: ComponentType;
    label?: string;
    enabledForRole?: (role: Role) => boolean;
}

export const commandRoutes: RouteConfig[] = [
    {
        type: CommandListRouterTypeEnum.NESTED,
        path: CommandListNestedRouterPathEnum.ORDER,
        component: OrderSearch,
        label: translate('shortcutMenu.order'),
        enabledForRole: (role: Role) => role.isAdmin() || role.isCustomer(),
    },
    {
        type: CommandListRouterTypeEnum.NESTED,
        path: CommandListNestedRouterPathEnum.OFFER,
        component: OfferSearch,
        label: translate('shortcutMenu.offer'),
        enabledForRole: (role: Role) => role.isAdmin(),
    },
    {
        type: CommandListRouterTypeEnum.NESTED,
        path: CommandListNestedRouterPathEnum.VESSEL,
        component: VesselSearch,
        label: translate('shortcutMenu.vessel'),
        enabledForRole: (role: Role) => role.isAdmin() || role.isCustomer(),
    },
    {
        type: CommandListRouterTypeEnum.NESTED,
        path: CommandListNestedRouterPathEnum.CUSTOMER,
        component: CustomerGroupSearch,
        label: translate('shortcutMenu.customer'),
        enabledForRole: (role: Role) => role.isAdmin(),
    },
    {
        type: CommandListRouterTypeEnum.NESTED,
        path: CommandListNestedRouterPathEnum.SUPPLIER,
        component: SupplierGroupSearch,
        label: translate('shortcutMenu.supplier'),
        enabledForRole: (role: Role) => role.isAdmin(),
    },
    {
        type: CommandListRouterTypeEnum.NESTED,
        path: CommandListNestedRouterPathEnum.AGENT,
        component: AgentSearch,
        label: translate('shortcutMenu.agent'),
        enabledForRole: (role: Role) => role.isAdmin(),
    },
    {
        type: CommandListRouterTypeEnum.NESTED,
        path: CommandListNestedRouterPathEnum.PORT,
        component: PortSearch,
        label: translate('shortcutMenu.port'),
        enabledForRole: (role: Role) => role.isAdmin(),
    },
    {
        type: CommandListRouterTypeEnum.NESTED,
        path: CommandListNestedRouterPathEnum.USER,
        component: UserSearch,
        label: translate('shortcutMenu.user'),
        enabledForRole: (role: Role) => role.isAdmin(),
    },
    {
        type: CommandListRouterTypeEnum.DIRECT,
        path: CommandListDirectRouterPathsEnum.DASHBOARD,
        component: Dashboard,
    },
    {
        type: CommandListRouterTypeEnum.DIRECT,
        path: CommandListDirectRouterPathsEnum.LOGOUT,
        component: Logout,
    },
];
