import { UserMessage } from '../../../../types/UserMessage';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { EditUser } from '../../components/EditUser';

interface EditUserTabelActionButtonProps {
    user: UserMessage;
}

export const EditUserTableActionButton = ({ user }: EditUserTabelActionButtonProps) => {
    const { push, pop } = useDrawer();

    return (
        <IconButton
            Icon={IconPencil}
            onClick={() => {
                push({
                    content: <EditUser user={user} onSuccess={pop} />,
                });
            }}
        />
    );
};
