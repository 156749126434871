import * as Dialog from '@radix-ui/react-dialog';
import { animated, config, useTransition } from '@react-spring/web';
import { ReactNode, FocusEvent } from 'react';

import { IconX } from '../../common/icons/cdl/X';

import { CloseIconWrapper } from './components/CloseIconWrapper';
import { ModalBackdrop } from './components/ModalBackdrop';
import { ModalContent } from './components/ModalContent';
import { getModalWidth } from './util/getModalWidth';

const AnimatedPopupBackdrop = animated(ModalBackdrop);
const AnimatedPopupContent = animated(ModalContent);

export interface ModalProps {
    isOpen: boolean;
    children: ReactNode;
    onDismiss?: () => void;
    size?: 'small' | 'medium' | 'large';
    /**
     * @deprecated Use `size` instead
     */
    width?: number;
    scrollable?: boolean;
    dismissible?: boolean;
}

export const Modal = ({
    isOpen,
    onDismiss,
    size = 'medium',
    width,
    children,
    scrollable = true,
    dismissible = true,
}: ModalProps) => {
    const transitions = useTransition(isOpen, {
        from: { opacity: 0, y: -16, scale: 1 },
        enter: { opacity: 1, y: 0, scale: 1 },
        leave: { opacity: 0, y: 0, scale: 0.98 },
        config: {
            ...config.default,
            tension: 300,
            clamp: true,
        },
    });

    const onOpenChange = (open: boolean) => {
        if (!open) {
            onDismiss?.();
        }
    };

    return (
        <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
            <Dialog.Portal forceMount>
                {transitions((styles, show) => {
                    if (!show) {
                        return null;
                    }

                    return (
                        <>
                            <AnimatedPopupBackdrop style={{ opacity: styles.opacity }}>
                                <AnimatedPopupContent
                                    style={styles}
                                    width={width ? width : getModalWidth(size)}
                                    scrollable={scrollable ? 1 : 0}
                                    onClick={(event: MouseEvent) => event.stopPropagation()}
                                    onInteractOutside={(event: FocusEvent | MouseEvent | TouchEvent) => {
                                        if (!dismissible) {
                                            event.preventDefault();
                                        }
                                    }}
                                    onEscapeKeyDown={(event: KeyboardEvent) => {
                                        if (!dismissible) {
                                            event.preventDefault();
                                        }
                                    }}
                                >
                                    {dismissible ? (
                                        <CloseIconWrapper>
                                            <IconX width={16} height={16} />
                                        </CloseIconWrapper>
                                    ) : null}
                                    {children}
                                </AnimatedPopupContent>
                            </AnimatedPopupBackdrop>
                        </>
                    );
                })}
            </Dialog.Portal>
        </Dialog.Root>
    );
};
