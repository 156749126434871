import { Command, useCommandState } from 'cmdk';

import { useSupplierGroupsPagination } from '../../../admin/suppliergroup/hooks/useSupplierGroupsPagination';
import { useSupplierList } from '../../../group/supplier/detail/hooks/useSupplierList';
import { translate } from '../../helpers/translate.helper';
import { useDebounce } from '../../hooks/useDebounce';

import { CommandEmptyState } from './CommandEmptyState';
import { CommandErrorState } from './CommandErrorState';
import { CommandSkeletonList } from './CommandSkeletonList';
import { CommandSupplierCompanyDetail } from './CommandSupplierCompanyDetail';
import { CommandSupplierGroupDetail } from './CommandSupplierGroupDetail';

export const SupplierGroupSearch = () => {
    const search = useCommandState((state) => state.search);
    const debouncedSearch = useDebounce(search, 300);

    const supplierGroupsQuery = useSupplierGroupsPagination(debouncedSearch, 0);
    const suppliersQuery = useSupplierList({
        searchQuery: debouncedSearch,
    });

    if (supplierGroupsQuery.isPending || suppliersQuery.isPending) {
        return <CommandSkeletonList />;
    }

    if (supplierGroupsQuery.isError || suppliersQuery.isError) {
        return <CommandErrorState />;
    }

    if (!supplierGroupsQuery.data && !suppliersQuery.data) {
        return <CommandEmptyState />;
    }

    return (
        <Command.List>
            <Command.Group heading={translate('shortcutMenu.groups')}>
                {supplierGroupsQuery.data?.content.map((supplierGroup) => (
                    <CommandSupplierGroupDetail supplierGroup={supplierGroup} key={supplierGroup.id} />
                ))}
            </Command.Group>

            <Command.Group heading={translate('shortcutMenu.companies')}>
                {suppliersQuery.data?.map((supplier) => (
                    <CommandSupplierCompanyDetail
                        key={supplier.id}
                        value={supplier.id}
                        supplier={supplier}
                        isChildren={false}
                    />
                ))}
            </Command.Group>
        </Command.List>
    );
};
