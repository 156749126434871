// @ts-expect-error - useId is not in @types/react
import { ReactNode, useId } from 'react';

import { LabelContext } from './LabelContext';

export const LabelProvider = ({ children }: { children: ReactNode }) => {
    const id = useId();

    return <LabelContext.Provider value={{ id }}>{children}</LabelContext.Provider>;
};
