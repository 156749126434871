import { useContextDays } from '@rehookify/datepicker';

import { formatDate } from '../../../common/helpers/formatDate.helper';
import { IconCalendar } from '../../../common/icons/cdl/Calendar';
import { Box } from '../../../common/ui/Box';
import { theme } from '../../../common/ui/theme';
import { InputLabel } from '../../Input/components/CoreInput';
import { InputContainer, InputContainerProps } from '../../Input/components/InputContainer';
import { InputLabelText } from '../../Input/components/InputLabelText';
import { LeadingVisualWrapper } from '../../Input/components/LeadingVisualWrapper';

interface CustomDateRangeInputProps extends InputContainerProps {
    label?: string;
}

export const CustomDateRangeInput = ({ label, ...rest }: CustomDateRangeInputProps) => {
    const { selectedDates } = useContextDays();

    return (
        <InputLabel>
            {label ? <InputLabelText $isFocused={rest.$isFocused}>{label}</InputLabelText> : null}
            <InputContainer {...rest}>
                <LeadingVisualWrapper>
                    <IconCalendar width={24} height={24} color={theme.colors.foreground.muted} />
                </LeadingVisualWrapper>
                <Box height="36px" padding="6px 10px" style={{ cursor: 'text' }}>
                    {selectedDates[0] ? formatDate({ date: selectedDates[0].getTime() }) + ' — ' : null}
                    {selectedDates[1] ? formatDate({ date: selectedDates[1].getTime() }) : null}
                </Box>
            </InputContainer>
        </InputLabel>
    );
};
