import { FieldArray, Form, useFormikContext } from 'formik';
import { MouseEvent } from 'react';

import { CounterOfferState } from '../../../../../../types/CounterOfferState';
import { MoneyMessage } from '../../../../../../types/MoneyMessage';
import { Button } from '../../../../../cdl/Button/Button';
import { FormikTextArea } from '../../../../../cdl/TextArea/FormikTextArea';
import { FormikDebug } from '../../../../../common/form-elements/formik/FormikDebug';
import { translate } from '../../../../../common/helpers/translate.helper';
import { LabelText } from '../../../../../common/LabelText/LabelText';
import { Table } from '../../../../../common/Table/Table';
import { Tr } from '../../../../../common/Table/Tr';
import { Box } from '../../../../../common/ui/Box';

import { CounterOfferReviewItems } from './CounterOfferReviewItems';

export interface CounterOfferReviewFormStateItem {
    productId: string;
    productName?: string;
    sulphurContent?: string;
    price: MoneyMessage | undefined;
    counterPrice: MoneyMessage | undefined;
    deleted: boolean;
}

export interface CounterOfferReviewFormState {
    responseMessage?: string;
    state: CounterOfferState;
    items: CounterOfferReviewFormStateItem[];
}

interface CounterOfferReviewFormProps {
    onUpdate: (event: MouseEvent<HTMLButtonElement>) => void;
    isCounterOfferExpired: boolean;
}

export const CounterOfferReviewForm = ({ onUpdate, isCounterOfferExpired }: CounterOfferReviewFormProps) => {
    const { isSubmitting, submitForm, setFieldValue, values } = useFormikContext<CounterOfferReviewFormState>();

    return (
        <Form>
            <Box display="grid" gridRowGap={5}>
                <Table style={{ margin: 0 }}>
                    <thead>
                        <Tr inactive={false} focused={false} unread={false}>
                            <th>
                                <LabelText>{translate('counterOffer.form.productHeader')}</LabelText>
                            </th>
                            <th>
                                <LabelText>{translate('counterOffer.form.priceHeader')}</LabelText>
                            </th>
                            <th>
                                <LabelText>{translate('counterOffer.form.counterPriceHeader')}</LabelText>
                            </th>
                        </Tr>
                    </thead>
                    <tbody>
                        <FieldArray name="items" component={CounterOfferReviewItems} />
                    </tbody>
                </Table>

                <FormikTextArea
                    label={translate('counterOffer.reviewPopup.messageLabel')}
                    name="responseMessage"
                    disabled={isCounterOfferExpired}
                />
            </Box>

            <Box display="flex" justifyContent="space-between" marginTop={6}>
                {!isCounterOfferExpired ? (
                    <Button
                        type="submit"
                        destructive={true}
                        emphasis="low"
                        isLoading={isSubmitting && values.state === CounterOfferState.DECLINED}
                        onClick={(event) => {
                            event.preventDefault();
                            setFieldValue('state', CounterOfferState.DECLINED);
                            submitForm();
                        }}
                    >
                        {translate('counterOffer.reviewPopup.declineBtn')}
                    </Button>
                ) : (
                    <Box></Box>
                )}
                <Box display="flex" justifyContent="end" style={{ gap: '8px' }}>
                    <Button onClick={onUpdate}>{translate('counterOffer.reviewPopup.updateBtn')}</Button>

                    <Button
                        type="submit"
                        emphasis="high"
                        isLoading={isSubmitting && values.state === CounterOfferState.ACCEPTED}
                        disabled={isCounterOfferExpired}
                        onClick={(event) => {
                            event.preventDefault();
                            setFieldValue('state', CounterOfferState.ACCEPTED);
                            submitForm();
                        }}
                    >
                        {translate('counterOffer.reviewPopup.acceptBtn')}
                    </Button>
                </Box>
            </Box>

            <FormikDebug />
        </Form>
    );
};
