import { SVGProps } from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}
export const IconCommand = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        fill="none"
        viewBox="0 0 24 24"
        display="block"
        aria-labelledby={titleId}
        {...props}
    >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M7.383 6.076A1 1 0 1 0 7 8h1V7a1 1 0 0 0-.617-.924ZM10 8V7a3 3 0 1 0-3 3h1v4H7a3 3 0 1 0 3 3v-1h4v1a3 3 0 1 0 3-3h-1v-4h1a3 3 0 1 0-3-3v1h-4Zm0 2v4h4v-4h-4Zm6-2h1a1 1 0 1 0-1-1v1Zm0 8v1a1 1 0 1 0 1-1h-1Zm-8 0H7a1 1 0 1 0 1 1v-1Z"
            clipRule="evenodd"
        />
    </svg>
);
