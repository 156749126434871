import { AgentGatewayMessage } from '../../../../types/AgentMessage';
import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { ContractMessage } from '../../../../types/ContractMessage';
import { ContractProductsMessage } from '../../../../types/ContractProductMessage';
import { FuelStockMessage } from '../../../../types/FuelStockMessage';
import { LubesPricesMessage } from '../../../../types/LubesPricesMessage';
import { PageMessage } from '../../../../types/PageMessage';
import { PriceCompareFuelMessage } from '../../../../types/PriceCompareFuelMessage';
import { PriceCompareLubesMessage } from '../../../../types/PriceCompareLubesMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { ProductMessage } from '../../../../types/ProductMessage';
import { ScheduleMessage } from '../../../../types/ScheduleMessage';
import { SpecificReportMessage } from '../../../../types/SpecificReportMessage';
import { SurchargeGatewayMessage } from '../../../../types/SurchargeGatewayMessage';
import { UserGatewayMessage } from '../../../../types/UserGatewayMessage';
import { Offer } from '../../../offer/model/offer.model';
import { OfferModel } from '../../../offer/model/OfferModel';
import { Order } from '../../../order/model/order.model';
import { OrderModel } from '../../../order/model/OrderModel';
import { authorizedGatewayAxiosInstance } from '../instances/AuthorizedGatewayAxiosInstance';

export interface GetLubesPricesParams {
    customerIds: string[];
    portIds: string[];
    items: unknown;
}

export const getLubesPrices = async ({
    customerIds,
    items,
    portIds,
}: GetLubesPricesParams): Promise<LubesPricesMessage> => {
    const data = {
        items,
        portIds,
        customerIds,
    };

    const response = await authorizedGatewayAxiosInstance.post('/v1/lubes-price-compare/items', data);

    return response.data;
};
export const getVessel = async (id: string): Promise<any> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/vessel/${id}`);

    return response.data;
};

export interface GetPaginatedVesselsParams {
    page: number;
    searchQuery?: string;
    customerIds?: string | string[];
    active: boolean;
}

export const getPaginatedVessels = async ({
    page,
    searchQuery,
    customerIds,
    active,
}: GetPaginatedVesselsParams): Promise<any> => {
    const params = new URLSearchParams();
    params.append('active', active.toString());
    params.append('page', page.toString());

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }
    if (customerIds) {
        if (Array.isArray(customerIds)) {
            customerIds.forEach((it) => params.append('customerIds', it));
        } else {
            params.append('customerIds', customerIds);
        }
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/vessel?${params.toString()}`);

    return response.data;
};

interface GetOrdersTotalParams {
    vesselId?: string;
    searchQuery?: string;
    read?: boolean;
    customerIds?: string[] | string;
    supplierIds?: string[] | string;
    types?: string[] | string;
    offerStates: string[] | string;
    states: string[] | string;
}

export const getOrdersTotal = async ({
    vesselId,
    searchQuery,
    read,
    customerIds,
    supplierIds,
    offerStates,
    types,
    states,
}: GetOrdersTotalParams): Promise<any> => {
    const params = new URLSearchParams();

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (vesselId) {
        params.append('vesselIds', vesselId);
    }

    if (read !== undefined) {
        params.append('read', read.toString());
    }

    if (customerIds) {
        if (Array.isArray(customerIds)) {
            customerIds.forEach((it) => params.append('customerIds', it));
        } else {
            params.append('customerIds', customerIds);
        }
    }

    if (supplierIds) {
        if (Array.isArray(supplierIds)) {
            supplierIds.forEach((it) => params.append('supplierIds', it));
        } else {
            params.append('supplierIds', supplierIds);
        }
    }

    if (offerStates) {
        if (Array.isArray(offerStates)) {
            offerStates.forEach((it) => params.append('offerStates', it));
        } else {
            params.append('offerStates', offerStates);
        }
    }

    if (types) {
        if (Array.isArray(types)) {
            types.forEach((it) => params.append('types', it));
        } else {
            params.append('types', types);
        }
    }

    if (states) {
        if (Array.isArray(states)) {
            states.forEach((it) => params.append('states', it));
        } else {
            params.append('states', states);
        }
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v2/orders/count?${params.toString()}`);

    return response.data;
};

export interface GetOrdersParams {
    page?: number;
    vesselId?: string;
    searchQuery?: string;
    read?: boolean;
    customerIds?: string[] | string;
    supplierIds?: string[] | string;
    offerStates?: string[] | string;
    types?: ProductContext[];
    states?: string[] | string;
    sortField?: string;
    sortDirection?: string;
}

export const getOrders = async ({
    page,
    vesselId,
    searchQuery,
    read,
    customerIds,
    supplierIds,
    offerStates,
    types,
    states,
    sortField,
    sortDirection,
}: GetOrdersParams): Promise<PageMessage<OrderModel>> => {
    const params = new URLSearchParams();

    if (page !== undefined) {
        params.append('page', page.toString());
    }

    if (sortField) {
        params.append('sortField', sortField);
    }

    if (sortDirection) {
        params.append('sortDirection', sortDirection);
    }

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (vesselId) {
        params.append('vesselIds', vesselId);
    }

    if (read !== undefined) {
        params.append('read', read.toString());
    }

    if (customerIds) {
        if (Array.isArray(customerIds)) {
            customerIds.forEach((it) => params.append('customerIds', it));
        } else {
            params.append('customerIds', customerIds);
        }
    }

    if (supplierIds) {
        if (Array.isArray(supplierIds)) {
            supplierIds.forEach((it) => params.append('supplierIds', it));
        } else {
            params.append('supplierIds', supplierIds);
        }
    }

    if (offerStates) {
        if (Array.isArray(offerStates)) {
            offerStates.forEach((it) => params.append('offerStates', it));
        } else {
            params.append('offerStates', offerStates);
        }
    }

    if (types) {
        if (Array.isArray(types)) {
            types.forEach((it) => params.append('types', it));
        } else {
            params.append('types', types);
        }
    }

    if (states) {
        if (Array.isArray(states)) {
            states.forEach((it) => params.append('states', it));
        } else {
            params.append('states', states);
        }
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v2/orders?${params.toString()}`);

    return {
        ...response.data,
        content: response.data.content.map((order: object) => new Order(order)),
    };
};
export const getOrder = async (orderId: string): Promise<any> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v2/orders/${orderId}`);

    return new Order(response.data);
};

interface GetOffersTotalParams {
    vesselId?: string;
    searchQuery?: string;
    supplierRead?: boolean;
    customerIds?: string[] | string;
    supplierIds?: string[] | string;
    types?: string[] | string;
    states: string[] | string;
}

export const getOffersTotal = async ({
    vesselId,
    searchQuery,
    supplierRead,
    supplierIds,
    customerIds,
    types,
    states,
}: GetOffersTotalParams): Promise<any> => {
    const params = new URLSearchParams();

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (vesselId) {
        params.append('vesselIds', vesselId);
    }

    if (supplierIds) {
        if (Array.isArray(supplierIds)) {
            supplierIds.forEach((it) => params.append('supplierIds', it));
        } else {
            params.append('supplierIds', supplierIds);
        }
    }

    if (customerIds) {
        if (Array.isArray(customerIds)) {
            customerIds.forEach((it) => params.append('customerIds', it));
        } else {
            params.append('customerIds', customerIds);
        }
    }

    if (types) {
        if (Array.isArray(types)) {
            types.forEach((it) => params.append('types', it));
        } else {
            params.append('types', types);
        }
    }

    if (states) {
        if (Array.isArray(states)) {
            states.forEach((it) => params.append('states', it));
        } else {
            params.append('states', states);
        }
    }

    if (supplierRead !== undefined) {
        params.append('supplierRead', supplierRead.toString());
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/offer/count?${params.toString()}`);

    return response.data;
};

export interface GetOffersParams {
    page?: number;
    vesselId?: string;
    searchQuery?: string;
    supplierRead?: boolean;
    supplierIds?: string[] | string;
    customerIds?: string[] | string;
    types?: string[] | string;
    states?: string[] | string;
    sortField?: string;
    sortDirection?: string;
}

export const getOffers = async ({
    page,
    vesselId,
    searchQuery,
    supplierRead,
    supplierIds,
    customerIds,
    types,
    states,
    sortField,
    sortDirection,
}: GetOffersParams): Promise<PageMessage<OfferModel>> => {
    const params = new URLSearchParams();
    if (page !== null && page !== undefined) {
        params.append('page', page.toString());
    }

    if (sortField) {
        params.append('sortField', sortField);
    }

    if (sortDirection) {
        params.append('sortDirection', sortDirection);
    }

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (vesselId) {
        params.append('vesselIds', vesselId);
    }

    if (supplierIds) {
        if (Array.isArray(supplierIds)) {
            supplierIds.forEach((it) => params.append('supplierIds', it));
        } else {
            params.append('supplierIds', supplierIds);
        }
    }

    if (customerIds) {
        if (Array.isArray(customerIds)) {
            customerIds.forEach((it) => params.append('customerIds', it));
        } else {
            params.append('customerIds', customerIds);
        }
    }

    if (types) {
        if (Array.isArray(types)) {
            types.forEach((it) => params.append('types', it));
        } else {
            params.append('types', types);
        }
    }

    if (states) {
        if (Array.isArray(states)) {
            states.forEach((it) => params.append('states', it));
        } else {
            params.append('states', states);
        }
    }

    if (supplierRead !== undefined) {
        params.append('supplierRead', supplierRead.toString());
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/offer?${params.toString()}`);

    return {
        ...response.data,
        content: response.data.content.map((offer: unknown) => new Offer(offer)),
    };
};

export interface GetOfferListParams {
    orderIds?: string[] | string;
    types?: string[] | string;
}

export const getOfferList = async ({ orderIds, types }: GetOfferListParams): Promise<OfferModel[]> => {
    const params = new URLSearchParams();

    if (orderIds) {
        if (Array.isArray(orderIds)) {
            orderIds.forEach((id) => params.append('orderIds', id));
        } else {
            params.append('orderIds', orderIds);
        }
    }

    if (types) {
        if (Array.isArray(types)) {
            types.forEach((it) => params.append('types', it));
        } else {
            params.append('types', types);
        }
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/offer/list?${params.toString()}`);

    return response.data.map((offer: unknown) => new Offer(offer));
};
export const getOfferById = async (offerId: string): Promise<OfferModel> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/offer/${offerId}`);

    // @ts-ignore
    return new Offer(response.data);
};
export const getMessagesForOfferId = async (offerId: string): Promise<any> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/message/offer/${offerId}`);

    return response.data;
};

interface GetAssignmentListParams {
    customerIds?: string[];
}

export const getAssignmentList = async ({
    customerIds,
}: GetAssignmentListParams): Promise<AssignmentGatewayMessage[]> => {
    const params = new URLSearchParams();

    if (customerIds) {
        customerIds.forEach((id) => params.append('customerIds', id));
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/assignment/list?${params.toString()}`);

    return response.data;
};

export interface GetAssignmentsParams {
    page: number;
    customerIds?: string[];
    supplierIds?: string[];
}

export const getAssignments = async ({
    page,
    customerIds,
    supplierIds,
}: GetAssignmentsParams): Promise<AssignmentGatewayMessage> => {
    const params = new URLSearchParams();
    params.append('page', page.toString());

    if (customerIds?.length) {
        customerIds.forEach((id) => params.append('customerIds', id));
    }

    if (supplierIds?.length) {
        supplierIds.forEach((id) => params.append('supplierIds', id));
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/assignment?${params.toString()}`);

    return response.data;
};

export const getVesselTanksByVesselId = async (vesselId: string): Promise<any> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/vessel-tanks?vesselIds=${vesselId}`);

    return response.data;
};

export interface GetAgentsParams {
    page: number;
    active: boolean;
    portIds?: string[];
    customerIds?: string[];
    searchQuery?: string;
}

export const getAgents = async ({
    portIds = [],
    customerIds = [],
    searchQuery,
    page = 0,
    active,
}: GetAgentsParams): Promise<PageMessage<AgentGatewayMessage>> => {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('active', active.toString());

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }
    if (portIds.length) {
        portIds.forEach((id) => params.append('portIds', id));
    }
    if (customerIds.length) {
        customerIds.forEach((id) => params.append('customerIds', id));
    }
    const response = await authorizedGatewayAxiosInstance.get(`/v1/agents/page?${params.toString()}`);

    return response.data;
};

export const getAgent = async (agentId: string): Promise<AgentGatewayMessage> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/agents/${agentId}`);

    return response.data;
};

export const getLatestPorts = async (): Promise<any> => {
    const response = await authorizedGatewayAxiosInstance.get('/v1/port/latest');

    return response.data;
};

interface GetContractsParams {
    page: number;
    searchQuery?: string;
    customerGroupIds?: string[];
    tender?: boolean;
    active?: boolean;
}

export const getContracts = async ({
    page = 0,
    searchQuery,
    customerGroupIds = [],
    tender,
    active,
}: GetContractsParams): Promise<any> => {
    const params = new URLSearchParams();
    params.append('page', page.toString());

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (customerGroupIds?.length) {
        customerGroupIds.forEach((it) => params.append('customerGroupIds', it));
    }

    if (tender !== undefined) {
        params.append('tender', tender.toString());
    }

    if (active !== undefined) {
        params.append('active', active.toString());
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/fuel-contracts?${params.toString()}`);

    return response.data;
};
export const getContract = async (contractId: string): Promise<ContractMessage> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/fuel-contracts/${contractId}`);

    return response.data;
};

export interface GetKeyPortsParams {
    page: number;
    searchQuery?: string;
    context?: ProductContext;
    customerGroupIds?: string[];
}

export const getKeyPorts = async ({
    page = 0,
    searchQuery,
    context,
    customerGroupIds = [],
}: GetKeyPortsParams): Promise<any> => {
    const params = new URLSearchParams();
    params.append('page', page.toString());

    if (searchQuery) {
        params.append('searchQuery', searchQuery.trim());
    }

    if (context) {
        params.append('context', context);
    }

    if (customerGroupIds.length) {
        customerGroupIds.forEach((id) => params.append('customerGroupIds', id));
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/key-ports?${params.toString()}`);

    return response.data;
};
export const getKeyPort = async (keyPortId: string): Promise<any> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/key-ports/${keyPortId}`);

    return response.data;
};
export const getFuelStocks = async (vesselId: string): Promise<{ fuelStocks: FuelStockMessage[] }> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/fuel-stocks/latest?vesselIds=${vesselId}`);

    return response.data;
};

interface GetScheduleListParams {
    imo: string;
    etdFrom?: string;
    etdTo?: string;
    customerGroupId?: string;
}

export const getScheduleList = async ({
    imo,
    etdFrom,
    etdTo,
    customerGroupId,
}: GetScheduleListParams): Promise<ScheduleMessage[]> => {
    const params = new URLSearchParams();
    params.append('imo', imo);

    if (etdFrom) {
        params.append('etdFrom', etdFrom);
    }

    if (etdTo) {
        params.append('etdTo', etdTo);
    }

    if (customerGroupId) {
        params.append('customerGroupId', customerGroupId);
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/schedule/list?${params.toString()}`);

    return response.data;
};
export const getLubesPriceCompare = async (
    vesselId: string,
    portIds: string[]
): Promise<{ prices: PriceCompareLubesMessage[] }> => {
    const params = new URLSearchParams();
    params.append('vesselId', vesselId);
    portIds.forEach((portId) => params.append('portIds', portId));

    const response = await authorizedGatewayAxiosInstance.get(`/v1/lubes-price-compare/vessels?${params.toString()}`);

    return response.data;
};
export const getFuelPriceCompare = async (
    vesselId: string,
    portIds: string[]
): Promise<{ prices: PriceCompareFuelMessage[] }> => {
    const params = new URLSearchParams();
    params.append('vesselId', vesselId);
    portIds.forEach((portId) => params.append('portIds', portId));

    const response = await authorizedGatewayAxiosInstance.get(`/v1/fuel-price-compare/vessels?${params.toString()}`);

    return response.data;
};

interface GetFuelContractProductsConfig {
    portId: string;
    validFrom: string;
    validTo: string;
    categories?: string[];
    sulphurContents?: string[];
    groupId?: string;
}

export const getFuelContractProducts = async ({
    portId,
    validFrom,
    validTo,
    categories,
    sulphurContents,
    groupId,
}: GetFuelContractProductsConfig): Promise<ContractProductsMessage> => {
    const params = new URLSearchParams();
    params.append('portId', portId);
    params.append('validFrom', validFrom);
    params.append('validTo', validTo);

    if (categories) {
        params.append('categories', categories.join(','));
    }

    if (sulphurContents) {
        params.append('sulphurContents', sulphurContents.join(','));
    }

    if (groupId) {
        params.append('groupId', groupId);
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/fuel-price-compare/contracts?${params.toString()}`);

    return response.data;
};
export const getMessages = async (offerId: string): Promise<any> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/message/offer/${offerId}`);

    return response.data;
};
export const getInitialEnquiry = async (offerId: string): Promise<OfferModel> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/offer/history/${offerId}/initial`);

    // @ts-ignore
    return new Offer(response.data);
};
export const getManagementUserById = async (userId: string): Promise<UserGatewayMessage> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/user/management/${userId}`);

    return response.data;
};

interface ManagementUsersParams {
    page: number;
    searchQuery?: string;
    companyIds?: string[];
    groupIds?: string[];
}

export const getManagementUsers = async (params: ManagementUsersParams): Promise<PageMessage<UserGatewayMessage>> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('page', params.page.toString());
    if (params.searchQuery) {
        urlSearchParams.append('searchQuery', params.searchQuery);
    }
    if (params.companyIds) {
        params.companyIds.forEach((id) => {
            urlSearchParams.append('companyIds', id);
        });
    }
    if (params.groupIds) {
        params.groupIds.forEach((id) => {
            urlSearchParams.append('groupIds', id);
        });
    }
    const response = await authorizedGatewayAxiosInstance.get<PageMessage<UserGatewayMessage>>(
        `/v1/user/management?${urlSearchParams.toString()}`
    );

    return response.data;
};

export interface GetReportsConfig {
    type: ProductContext;
    from: number;
    to: number;
    customerIds?: string[];
    supplierIds?: string[];
}

export const getVesselReports = async (params: GetReportsConfig): Promise<SpecificReportMessage> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('from', params.from.toString());
    urlSearchParams.append('to', params.to.toString());

    if (params.type) {
        urlSearchParams.append('types', params.type);
    }

    if (params.customerIds) {
        params.customerIds.forEach((id) => {
            urlSearchParams.append('customerIds', id);
        });
    }

    if (params.supplierIds) {
        params.supplierIds.forEach((id) => {
            urlSearchParams.append('supplierIds', id);
        });
    }

    const response = await authorizedGatewayAxiosInstance.get<SpecificReportMessage>(
        `/v1/report/vessel?${urlSearchParams}`
    );

    return response.data;
};

export const getSupplierReports = async (params: GetReportsConfig): Promise<SpecificReportMessage> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('from', params.from.toString());
    urlSearchParams.append('to', params.to.toString());

    if (params.type) {
        urlSearchParams.append('types', params.type);
    }

    if (params.customerIds) {
        params.customerIds.forEach((id) => {
            urlSearchParams.append('customerIds', id);
        });
    }

    if (params.supplierIds) {
        params.supplierIds.forEach((id) => {
            urlSearchParams.append('supplierIds', id);
        });
    }

    const response = await authorizedGatewayAxiosInstance.get<SpecificReportMessage>(
        `/v1/report/supplier?${urlSearchParams.toString()}`
    );

    return response.data;
};

export const getPortReports = async (params: GetReportsConfig): Promise<SpecificReportMessage> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('from', params.from.toString());
    urlSearchParams.append('to', params.to.toString());

    if (params.type) {
        urlSearchParams.append('types', params.type);
    }

    if (params.customerIds) {
        params.customerIds.forEach((id) => {
            urlSearchParams.append('customerIds', id);
        });
    }

    if (params.supplierIds) {
        params.supplierIds.forEach((id) => {
            urlSearchParams.append('supplierIds', id);
        });
    }

    const response = await authorizedGatewayAxiosInstance.get<SpecificReportMessage>(
        `/v1/report/port?${urlSearchParams.toString()}`
    );

    return response.data;
};

export const getProductReports = async (params: GetReportsConfig): Promise<SpecificReportMessage> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('from', params.from.toString());
    urlSearchParams.append('to', params.to.toString());

    if (params.type) {
        urlSearchParams.append('types', params.type);
    }

    if (params.customerIds) {
        params.customerIds.forEach((id) => {
            urlSearchParams.append('customerIds', id);
        });
    }

    if (params.supplierIds) {
        params.supplierIds.forEach((id) => {
            urlSearchParams.append('supplierIds', id);
        });
    }

    const response = await authorizedGatewayAxiosInstance.get<SpecificReportMessage>(
        `/v1/report/product?${urlSearchParams}`
    );

    return response.data;
};

export const getCustomerReports = async (params: GetReportsConfig): Promise<SpecificReportMessage> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('from', params.from.toString());
    urlSearchParams.append('to', params.to.toString());

    if (params.type) {
        urlSearchParams.append('types', params.type);
    }

    if (params.customerIds) {
        params.customerIds.forEach((id) => {
            urlSearchParams.append('customerIds', id);
        });
    }

    if (params.supplierIds) {
        params.supplierIds.forEach((id) => {
            urlSearchParams.append('supplierIds', id);
        });
    }

    const response = await authorizedGatewayAxiosInstance.get<SpecificReportMessage>(
        `/v1/report/customer?${urlSearchParams}`
    );

    return response.data;
};

export const getSurcharges = async (supplierGroupId: string): Promise<SurchargeGatewayMessage[]> => {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('supplierGroupId', supplierGroupId);

    const response = await authorizedGatewayAxiosInstance.get(`/v1/surcharge?${urlSearchParams.toString()}`);

    return response.data;
};

interface getProductsParams {
    page?: number;
    supplierProductGroups?: string[];
    searchQuery: string;
    includeArchived?: boolean;
}

export const getLubesProducts = async ({
    searchQuery,
    includeArchived = false,
    page = 0,
    supplierProductGroups = [],
}: getProductsParams): Promise<PageMessage<ProductMessage>> => {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('searchQuery', searchQuery.trim());
    params.append('productTypes', 'LUBES');

    if (!includeArchived) {
        params.append('archived', 'false');
    }

    if (supplierProductGroups.length > 0) {
        supplierProductGroups.forEach((supplierGroup) => {
            params.append('supplierProductGroups', supplierGroup);
        });
    }

    const response = await authorizedGatewayAxiosInstance.get(`/v1/product?${params.toString()}`);

    return response.data;
};

export const getLatestProducts = async (): Promise<ProductMessage[]> => {
    const response = await authorizedGatewayAxiosInstance.get(`/v1/product/latest`);

    return response.data;
};
