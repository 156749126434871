import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { PriceMessage } from '../../../../types/LubesPricesMessage';
import { Button } from '../../../cdl/Button/Button';
import { Callout } from '../../../cdl/Callout/Callout';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconDiscount2 } from '../../../common/icons/cdl/Discount2';
import { Box } from '../../../common/ui/Box';

interface SingleBestPriceCalloutProps {
    bestPriceOption: PriceMessage;
    onCompareClick: () => void;
    onApply: (portId: string, receiverSupplierIds: string[]) => void;
    trackingId: string;
}

export const SingleBestPriceCallout = ({
    bestPriceOption,
    onCompareClick,
    onApply,
    trackingId,
}: SingleBestPriceCalloutProps) => {
    const role = useRole();

    useEffect(() => {
        if (!role.isAdmin()) {
            ReactGA.event('apply_best_price_button_rendered', {
                category: 'Better port hint',
                action: 'Apply best price button is rendered',
                id: trackingId,
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const bestPriceSupplier = bestPriceOption.supplier.name;
    const bestPricePort = bestPriceOption.port.name;

    return (
        <Callout
            variant="accent"
            heading={translate('priceCompare.singleBestPrice.heading', {
                supplier: bestPriceSupplier,
                port: bestPricePort,
            })}
            description={translate('priceCompare.singleBestPrice.description', {
                supplier: bestPriceSupplier,
                port: bestPricePort,
            })}
            leadingVisual={(props) => <IconDiscount2 {...props} />}
        >
            <Box display="flex" gap={4}>
                {!role.isAdmin() ? (
                    <Button type="button" onClick={() => onApply(bestPriceOption.portId, [bestPriceOption.supplierId])}>
                        {translate('priceCompare.singleBestPrice.applyBtn')}
                    </Button>
                ) : null}
                <Button type="button" emphasis="high" onClick={onCompareClick}>
                    {translate('priceCompare.compareBtn')}
                </Button>
            </Box>
        </Callout>
    );
};
