import { useNavigate, useSearch } from '@tanstack/react-router';

import { ProductContext } from '../../../types/ProductContext';
import { CenteredPagination } from '../../cdl/Pagination/CenteredPagination';
import { Text } from '../../cdl/Text/Text';
import { CenteredPageError } from '../../common/Error/CenteredPageError';
import { translate } from '../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { NoSearchResults } from '../../common/Search/NoSearchResults/NoSearchResults';
import { useOfferPagination } from '../../offer/useOfferPagination';

import { SearchResultOfferTable } from './SearchResultOfferTable';

export const SupplierSearchResult = () => {
    const { query, offersPage } = useSearch({ from: '/_app/search' });
    const navigate = useNavigate({ from: '/search' });

    const paginatedOffersQuery = useOfferPagination({
        page: offersPage,
        searchQuery: query,
        types: [ProductContext.FUEL, ProductContext.LUBES],
    });

    if (paginatedOffersQuery.isPending) {
        return <LoadingIndicator variant="page" />;
    }

    if (paginatedOffersQuery.isError) {
        return <CenteredPageError />;
    }

    if (paginatedOffersQuery.data.content.length === 0) {
        return (
            <div>
                <Text as="h1" variant="headline" marginBottom={6}>
                    {translate('searchResult.title')}
                </Text>

                <NoSearchResults variant="page" />
            </div>
        );
    }

    return (
        <div>
            <Text as="h1" variant="title" marginBottom={6}>
                {translate('searchResult.title')}
            </Text>

            <Text as="h2" variant="subtitle1" marginBottom={5}>
                {translate('searchResult.enquiries')}
            </Text>

            <SearchResultOfferTable offers={paginatedOffersQuery.data.content} />

            <CenteredPagination
                pageTotal={paginatedOffersQuery.pageTotal}
                currentPage={offersPage}
                onPageChange={(page) => navigate({ search: { query, offersPage: page } })}
            />
        </div>
    );
};
