import { useNavigate, useSearch } from '@tanstack/react-router';

import { ProductContext } from '../../../types/ProductContext';
import { CenteredPagination } from '../../cdl/Pagination/CenteredPagination';
import { Text } from '../../cdl/Text/Text';
import { Error } from '../../common/Error/Error';
import { translate } from '../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { NoSearchResults } from '../../common/Search/NoSearchResults/NoSearchResults';
import { useOrderPagination } from '../../order/useOrderPagination';

import { SearchResultOrderTable } from './SearchResultOrderTable';

export const AdminSearchResultOrderTable = () => {
    const { query, ordersPage, offersPage } = useSearch({ from: '/_app/search' });
    const navigate = useNavigate({ from: '/search' });

    const paginatedOrdersQuery = useOrderPagination({
        page: ordersPage,
        searchQuery: query,
        types: [ProductContext.FUEL, ProductContext.LUBES],
    });

    if (paginatedOrdersQuery.isPending) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.orders')}
                </Text>

                <LoadingIndicator />
            </div>
        );
    }

    if (paginatedOrdersQuery.isError) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.orders')}
                </Text>

                <Error />
            </div>
        );
    }

    if (paginatedOrdersQuery.data.content.length === 0) {
        return (
            <div>
                <Text as="h2" variant="subtitle1">
                    {translate('searchResult.orders')}
                </Text>

                <NoSearchResults />
            </div>
        );
    }

    return (
        <div>
            <Text as="h2" variant="subtitle1">
                {translate('searchResult.orders')}
            </Text>

            <div>
                <SearchResultOrderTable orders={paginatedOrdersQuery.data.content} />
                <CenteredPagination
                    pageTotal={paginatedOrdersQuery.pageTotal}
                    currentPage={ordersPage}
                    onPageChange={(page) => navigate({ search: { query, ordersPage: page, offersPage } })}
                />
            </div>
        </div>
    );
};
