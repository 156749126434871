import { useEffect, useState } from 'react';
import { MultiValue, OnChangeValue, PropsValue, SingleValue } from 'react-select';

import { ComboBox, ComboBoxProps, DefaultOption } from '../../../cdl/ComboBox/ComboBox';
import { getSupplierGroups, GetSupplierGroupsParams } from '../../api/clients/company.api';

export interface SupplierGroupSelectProps<IsMulti extends boolean>
    extends Omit<
        ComboBoxProps<DefaultOption, IsMulti>,
        'onChange' | 'value' | 'loadOptions' | 'options' | 'isLoading'
    > {
    onChange: (option: OnChangeValue<string, IsMulti>) => void;
    value?: IsMulti extends true ? MultiValue<string> : SingleValue<string>;
}

const loadSupplierGroups = async (params: GetSupplierGroupsParams): Promise<DefaultOption[]> => {
    let response = await getSupplierGroups(params);
    return response.map((supplierGroup) => ({
        value: supplierGroup.id,
        label: supplierGroup.name,
    }));
};

const loadSupplierGroupsBySearchQuery = (searchQuery: string) => loadSupplierGroups({ searchQuery });

function loadSupplierGroupsByIds<IsMulti>(ids: IsMulti extends true ? MultiValue<string> : SingleValue<string>) {
    return loadSupplierGroups({ ids: ids === null ? undefined : (ids as string[] | string) });
}

export function SupplierGroupSelect<IsMulti extends boolean = false>(props: SupplierGroupSelectProps<IsMulti>) {
    const [selectedValue, setSelectedValue] = useState<PropsValue<DefaultOption>>(props.isMulti ? [] : null);

    useEffect(() => {
        if (props.value?.length) {
            loadSupplierGroupsByIds<IsMulti>(props.value).then((result) => {
                setSelectedValue(props.isMulti ? result : result[0]);
            });
        } else {
            setSelectedValue(props.isMulti ? [] : null);
        }
    }, [props.value, props.isMulti]);

    const onChange: ComboBoxProps<DefaultOption, IsMulti>['onChange'] = (newValue) => {
        setSelectedValue(newValue);

        props.onChange(
            (props.isMulti
                ? (newValue as MultiValue<DefaultOption>).map((it) => it.value)
                : (newValue as SingleValue<DefaultOption>)?.value) as OnChangeValue<string, IsMulti>
        );
    };

    return (
        <ComboBox {...props} value={selectedValue} onChange={onChange} loadOptions={loadSupplierGroupsBySearchQuery} />
    );
}
