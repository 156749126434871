import { useParams } from '@tanstack/react-router';
import { AxiosError } from 'axios';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { UserOnboardRequestErrorResponse } from '../../common/api/clients/userOnboard.api';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useStartUserlikeChat } from '../../common/hooks/useStartUserlikeChat';
import { Spinner } from '../../common/Spinner/Spinner';
import { Box } from '../../common/ui/Box';
import { theme } from '../../common/ui/theme';
import { authenticate } from '../../lib/auth';
import { FormikOnboardForm } from '../components/FormikOnboardForm';
import { LoginPageContentBox } from '../components/LoginPageContentBox';
import { useOnboardingSessionReset } from '../hooks/useOnboardingSessionReset';
import { useRedirectAfterLogin } from '../hooks/useRedirectAfterLogin';
import { useUserOnboarding } from '../hooks/useUserOnboarding';
import { useUserOnboardingUpdate } from '../hooks/useUserOnboardingUpdate';

export interface FormikRegistrationState {
    firstName: string;
    lastName: string;
    phone: string;
    emailAddress: string;
    password: string;
    newsletterEnabled: boolean;
}

const validate = (values: FormikRegistrationState) => {
    const errors: FormikErrors<FormikRegistrationState> = {};

    if (!values.firstName) {
        errors.firstName = translate('onboard.error.requiredFirstName');
    }

    if (!values.lastName) {
        errors.lastName = translate('onboard.error.requiredLastName');
    }

    if (!values.password) {
        errors.password = translate('onboard.error.requiredPassword');
    } else if (values.password.length < 6) {
        errors.password = translate('onboard.error.passwordTooShort');
    }

    return errors;
};

export const OnboardPage = () => {
    const { userId, token } = useParams({ from: '/onboard/$userId/$token' });
    const userQuery = useUserOnboarding(userId, token);
    const userOnboardingMutation = useUserOnboardingUpdate(userId, token);
    const { addErrorToast } = useToasts();
    const redirectAfterLogin = useRedirectAfterLogin(true);
    const startUserlikeChat = useStartUserlikeChat();
    useOnboardingSessionReset();

    useDocumentTitle(translate('onboard.pageTitle'));

    if (userQuery.isPending) {
        return (
            <LoginPageContentBox>
                <Box style={{ textAlign: 'center' }}>
                    <Spinner color={theme.colors.foreground.default} />
                </Box>
            </LoginPageContentBox>
        );
    }

    if (!userQuery.isSuccess) {
        return (
            <LoginPageContentBox>
                <Text variant="title" as="h1">
                    {translate('onboard.error.invitationLinkNoLongerValid')}
                </Text>
                <Text as="p" variant="small" color="foreground.muted">
                    {translate('onboard.error.furtherSupportInformation')}
                </Text>
                <Box marginTop={6}>
                    <Button emphasis="high" size="large" type="submit" onClick={() => startUserlikeChat()}>
                        {translate('onboard.contactUs')}
                    </Button>
                </Box>
            </LoginPageContentBox>
        );
    }

    const initialFormValues: FormikRegistrationState = {
        firstName: userQuery.data.firstname || '',
        lastName: userQuery.data.lastname || '',
        phone: userQuery.data.phone || '',
        emailAddress: userQuery.data.email,
        password: '',
        newsletterEnabled: false,
    };

    const handleSubmit = async (values: FormikRegistrationState, meta: FormikHelpers<FormikRegistrationState>) => {
        try {
            await userOnboardingMutation.mutateAsync({
                ...userQuery.data,
                password: values.password,
                phone: values.phone,
                firstname: values.firstName,
                lastname: values.lastName,
                mailSettings: {
                    ...userQuery.data.mailSettings,
                    newsletter: values.newsletterEnabled,
                },
            });

            await authenticate({ emailAddress: userQuery.data.email, password: values.password })
                .then(() => redirectAfterLogin())
                .catch(() => {
                    addErrorToast(translate('login.error.weCouldNotConnectToCloselink'));
                });

            await redirectAfterLogin();
        } catch (error) {
            const axiosError = error as AxiosError<UserOnboardRequestErrorResponse>;

            if (axiosError.response?.status !== 400) {
                addErrorToast(translate('onboard.error.weCouldNotConnectToCloselink'));
            } else {
                switch (axiosError.response.data.errorCode) {
                    case 'MAIL_NOT_UNIQUE':
                        meta.setErrors({
                            emailAddress: translate('onboard.error.emailNotUnique'),
                        });
                        break;
                    case 'PASSWORD_SAFETY':
                        meta.setErrors({
                            password: translate('onboard.error.insecurePassword'),
                        });
                        break;
                }
            }
        }
    };

    return (
        <LoginPageContentBox>
            <Text variant="title" as="h1">
                {translate('onboard.completeAccount')}
            </Text>
            <Text variant="small" color="foreground.muted">
                {translate('onboard.enterInformationBelow', {
                    emailAddress: userQuery.data.email,
                })}
            </Text>
            <Formik
                initialValues={initialFormValues}
                validate={validate}
                onSubmit={handleSubmit}
                component={FormikOnboardForm}
            />
        </LoginPageContentBox>
    );
};
