import { useNavigate } from '@tanstack/react-router';
import { Command } from 'cmdk';

import { CustomerGroupMessage } from '../../../../types/CustomerGroupMessage';
import { Text } from '../../../cdl/Text/Text';
import { useCustomerList } from '../../../group/customer/detail/hooks/useCustomerList';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandCustomerCompanyDetail } from './CommandCustomerCompanyDetail';
import { CommandItem } from './CommandItem';
import { CommandSkeleton } from './CommandSkeleton';

interface GroupDetailProps {
    customerGroup: CustomerGroupMessage;
}

export const CommandCustomerGroupDetail = (props: GroupDetailProps) => {
    const customersQuery = useCustomerList({ groupId: props.customerGroup.id });
    const { setOpen } = useShortcutMenu();

    const navigate = useNavigate();

    const onGroupSelect = () => {
        navigate({ to: '/customer-group/$id', params: { id: props.customerGroup.id } });
        setOpen(false);
    };

    return (
        <Command.Group style={{ minWidth: 0 }}>
            <CommandItem value={`group-${props.customerGroup.id}`} onSelect={onGroupSelect}>
                <Text fontWeight="bold">{props.customerGroup.name}</Text>
            </CommandItem>
            {customersQuery.isPending ? <CommandSkeleton /> : null}
            {customersQuery.data?.map((customer) => (
                <CommandCustomerCompanyDetail
                    key={customer.id}
                    value={`group-${props.customerGroup.id}-${customer.id}`}
                    customer={customer}
                    isChildren={true}
                />
            ))}
            <Command.Separator alwaysRender />
        </Command.Group>
    );
};
