import { Command } from 'cmdk';

import { translate } from '../../helpers/translate.helper';
import { Characters } from '../../helpers/unicode.helper';
import { useRole } from '../../hooks/useRole';
import { useShortcutMenu } from '../hooks/useShortcutMenu';

import { CommandEmptyState } from './CommandEmptyState';
import { CommandItem } from './CommandItem';
import { CommandListNestedRouterPath, CommandListRouterTypeEnum, commandRoutes } from './commandRoutes';

export const CommandListRouter = () => {
    const role = useRole();
    const { setPages, pages } = useShortcutMenu();

    const page = pages[pages.length - 1];

    if (!page) {
        return (
            <Command.List>
                <Command.Empty>
                    <CommandEmptyState />
                </Command.Empty>

                <Command.Group heading={translate('shortcutMenu.open')}>
                    {commandRoutes
                        .filter((route) => route.type === CommandListRouterTypeEnum.NESTED)
                        .map((route) => {
                            const isEnabled = route.enabledForRole?.(role) ?? true;

                            if (isEnabled) {
                                return (
                                    <CommandItem
                                        key={route.label}
                                        onSelect={() =>
                                            setPages((pages) => [...pages, route.path as CommandListNestedRouterPath])
                                        }
                                    >
                                        {route.label}
                                        {Characters.HORIZONTAL_ELLIPSIS}
                                    </CommandItem>
                                );
                            }
                        })}
                </Command.Group>

                <Command.Group heading={translate('shortcutMenu.navigation')}>
                    {commandRoutes
                        .filter((route) => route.type === CommandListRouterTypeEnum.DIRECT)
                        .map((route) => {
                            const isEnabled = route.enabledForRole?.(role) ?? true;

                            if (isEnabled) {
                                const Component = route.component;
                                return <Component key={route.label} />;
                            }
                        })}
                </Command.Group>
            </Command.List>
        );
    }

    const matchingRoute = commandRoutes.find((route) => route.path === page);
    if (matchingRoute) {
        const Component = matchingRoute.component;
        return <Component />;
    }

    return null;
};
